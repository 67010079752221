import React, {forwardRef, HTMLAttributes} from 'react';
import './index.scss';
import {IconNames} from "../../../models/icon";


interface Props extends HTMLAttributes<HTMLOrSVGElement> {
	iconName: IconNames
	key?: string
}

const Icon: React.FC<Props> = forwardRef<HTMLSpanElement, Props>((props, ref) => {
	const {iconName, className, ...additionalProps} = props;
	const classes = "icon icon-" + iconName + (
		className ? ' ' + className : ''
	)

	return <span ref={ref} className={classes} {...additionalProps}></span>
})

export default Icon
