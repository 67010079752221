/**
 * This function will take in a set of params, a set of OrderBy values, and a set of filter values, and add them to the Params
 * and then add an ellipsis sign if one is passed through.
 * @param params
 * @param filter
 * @param order
 *
 * @return {[key: string]: string|number}
 */
export function addOrderAndFilterToParams(params: {[key: string]: string|number }, filter?: {[key: string]: string|number }, order?: {[key: string]: string }): {[key: string]: string|number} {

	if (order) {
		for (let orderKey in order) {
			params["order[" + orderKey + "]"] = order[orderKey];
		}
	}

	if (filter) {
		for (let filterKey in filter) {
			if (filter[filterKey] !== undefined) {
				params["filter[" + filterKey + "]"] = filter[filterKey];
			}
		}
	}

	return params;
}
