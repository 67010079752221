import React, {PropsWithChildren, useEffect, useState} from 'react';
import Post, {postPlaceholder} from '../models/post/post';
import UserPostRequests from '../services/requests/UserPostRequests';
import LoadingScreen from '../components/Loaders/LoadingScreen';
import ErrorPage from "../pages/ErrorPage";
import Brand from "../models/brand";
import {connect} from "../data/connect";
import {mergeBrandOptions} from "../models/brand-option";
import Banner from "../models/banner";

let cachedPosts: {[key: string]: Post | undefined} = {}

/**
 * The structure of the consumer
 */
export interface PostContextConsumerState {
    hasLoaded: boolean,
    notFound: boolean,
    post: Post | undefined,
    setPost: (post: Post) => void,
}

let defaultContext: PostContextConsumerState = {
    hasLoaded: false,
    notFound: false,
    post: postPlaceholder(['news']),
    setPost: (post: Post) => {}
};

export const PostContext = React.createContext<PostContextConsumerState>(defaultContext);

interface OwnProps {
    postSlug: string,
    skipCache?: boolean,
}

interface StateProps {
    brand: Brand
}

export interface PostContextProviderProps extends OwnProps, StateProps{
}

const PostContextProvider: React.FC<PropsWithChildren<PostContextProviderProps>> = ({postSlug, skipCache, brand, children}) => {
    const [postState, setPostState] = useState(defaultContext);

    const setAndBrandPost = (postContext: PostContextConsumerState) => {
        if(postContext.post) {
            // Confirm the post is valid for this brand before output
            let brandedPost = postContext.post.brands?.find(postBrand => postBrand.id === brand.id) ? mergeBrandOptions(postContext.post, brand) : undefined

            // Brand Post children & banners if applicable
            if(brandedPost){
                let brandedChildren = [] as Post[]
                brandedPost.children?.forEach(child => {
                    if(child.brands?.find(childBrand => childBrand.id === brand.id)){
                        brandedChildren.push(mergeBrandOptions(child, brand))
                    }
                })

                brandedPost.children = brandedChildren


                let brandedBanners = [] as Banner[]
                brandedPost.banners?.forEach(banner => {
                    brandedBanners.push(mergeBrandOptions(banner, brand))
                })

                brandedPost.banners = brandedBanners
            }

            setPostState({
                ...postContext,
                post: brandedPost
            })
        }
    }

    const setPost = (post: Post): void => {
        cachedPosts[post.slug!] = {...post};
        setAndBrandPost({
            ...postState,
            post: post,
        })
    }

    useEffect(() => {
        if (!skipCache && cachedPosts[postSlug]) {
            setAndBrandPost({
                hasLoaded: true,
                notFound: false,
                post: cachedPosts[postSlug],
                setPost: setPost,
            });
        } else {
            setAndBrandPost({
                ...postState,
                hasLoaded: false,
            });
            UserPostRequests.getPost(postSlug).then(post => {
                cachedPosts[postSlug] = post;
                setAndBrandPost({
                    hasLoaded: true,
                    notFound: false,
                    post: post,
                    setPost,
                });
            }).catch(() => {
                setAndBrandPost({
                    ...postState,
                    hasLoaded: true,
                    notFound: true,
                });
            })
        }
    }, [postSlug, window.location.pathname]);

    return (
        <PostContext.Provider value={{...postState, setPost}}>
            <PostContext.Consumer>
                {context => (context.hasLoaded ?
                    (!context.notFound ? children :
                            <ErrorPage errorNumber={404}/>
                    ) : <LoadingScreen/>
                )}
            </PostContext.Consumer>
        </PostContext.Provider>
    )
}

export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: PostContextProvider
});
