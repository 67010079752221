import React, {PropsWithChildren, useEffect} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {TokenState} from '../../../data/persistent/persistent.state';
import {connect} from '../../../data/connect';
import {brands, PAGES} from "../../../config";
import Brand, {BrandRootParams} from "../../../models/brand";
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";
import MyOrganizationContextProvider, {MyOrganizationContext} from "../../../contexts/organization/MyOrganizationContext";
import {setBrand} from "../../../data/persistent/persistent.actions";


interface UserOrganizationValidationProps {
    organizationBrands: Brand[],
    tokenValidation: boolean | TokenState,
    redirectUrl?: string,
    setBrand: (brand: Brand) => void
}

const UserOrganizationValidation: React.FC<PropsWithChildren<UserOrganizationValidationProps>> = ({organizationBrands, tokenValidation, redirectUrl, setBrand, children}) => {
    const {brand}  = useParams<BrandRootParams>();
    const isValidBrand = brand ? brands.includes(brand) : false
    const brandState = organizationBrands?.find((orgBrand) => orgBrand.abbreviation === brand)

    useEffect(() => {
        if(brandState){
            setBrand(brandState)
        }
    }, [organizationBrands]);

    return brandState && tokenValidation && isValidBrand ? (
        <React.Fragment>
            {children}
        </React.Fragment>
    ) : (
        <Navigate to={redirectUrl ? redirectUrl : PAGES.DEFAULT_LANDING}/>
    )
}

interface OwnProps {
}

interface DispatchProps {
    setBrand: typeof setBrand
}

interface StateProps {
    tokenData?: TokenState;
}

interface AuthenticatedRouteProps extends StateProps, DispatchProps {
}

const AuthenticatedRoute: React.FC<PropsWithChildren<AuthenticatedRouteProps>> = ({tokenData, setBrand, children}) => {
    const validation = tokenData || process.env.REACT_APP_ENV === 'staging'

    return (
        <MeContextProvider>
            <MeContext.Consumer>
                {contextMe => contextMe.me.organization_id ? (
                    <MyOrganizationContextProvider organizationId={contextMe.me.organization_id}>
                        <MyOrganizationContext.Consumer>
                            {context => (
                                <UserOrganizationValidation organizationBrands={context.organization.brands!} tokenValidation={validation} setBrand={setBrand}>
                                    {children}
                                </UserOrganizationValidation>
                            )}
                        </MyOrganizationContext.Consumer>
                    </MyOrganizationContextProvider>
                ) : null }
            </MeContext.Consumer>
        </MeContextProvider>
    )
}


export default connect<OwnProps, StateProps, { }>({
    mapStateToProps: (state) => ({
        tokenData: state.persistent.tokenData
    }),
    mapDispatchToProps: ({
        setBrand
    }),
    component: AuthenticatedRoute
});
