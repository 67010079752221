import {phoneRegExp} from "../../../util/regex";
import React, {useEffect} from "react";
import RequiredInput from "../../FormComponents/RequiredInput";
import InputWrapper from "../../FormComponents/InputWrapper";
import PhoneNumberInput from "../../FormComponents/PhoneNumberInput";
import * as Yup from 'yup';
import {getFieldError, StringKeyObject, validate} from "../../../util/form";
import {buildContactPrefixes} from "../../../models/form";


const ContactContent = ((prefix: string, features: StringKeyObject, form: any) => {
    const prefixes = buildContactPrefixes(prefix)

    // Yup validation object specific to contact component
    const yupObject = {
        [prefixes.first_name]: Yup.string().trim().required(getFieldError(features['first_name'])),
        [prefixes.last_name]: Yup.string().trim().required(getFieldError(features['last_name'])),
        [prefixes.email]: Yup.string().email(getFieldError(features['email'], true)).required(getFieldError(features['email'])),
        [prefixes.phone]: Yup.string().matches(phoneRegExp, getFieldError(features['phone'], true)),
    }

    // Function to combine the first and last name values into a single name
    const first_name_value = form.values[prefixes.first_name]
    const last_name_value = form.values[prefixes.last_name]
    useEffect(() => {
        form.setFieldValue(prefixes.name, first_name_value + ' ' + last_name_value)
    }, [first_name_value, last_name_value]);

    // Function that returns the HTML for address component
    const htmlNode = (onChangeEvent: (name: string, value: string) => void, onKeyDown: (event: any) => void) => (
        <React.Fragment>
            <RequiredInput
                name={prefixes.first_name}
                value={form.values[prefixes.first_name]}
                error={form.errors[prefixes.first_name] && form.touched[prefixes.first_name] ? form.errors[prefixes.first_name] : undefined}
                placeholder={features['first_name']}
                onRequiredInputChange={onChangeEvent}
                autoCapitalize={'word'}
                onKeyDown={onKeyDown}
            />
            <RequiredInput
                name={prefixes.last_name}
                value={form.values[prefixes.last_name]}
                error={form.errors[prefixes.last_name] && form.touched[prefixes.last_name] ? form.errors[prefixes.last_name] : undefined}
                placeholder={features['last_name']}
                onRequiredInputChange={onChangeEvent}
                autoCapitalize={'word'}
                onKeyDown={onKeyDown}
            />
            <RequiredInput
                name={prefixes.email}
                value={form.values[prefixes.email]}
                error={form.errors[prefixes.email] && form.touched[prefixes.email] ? form.errors[prefixes.email] : undefined}
                placeholder={features['email']}
                onRequiredInputChange={onChangeEvent}
                onKeyDown={onKeyDown}
            />
            <InputWrapper label={''}>
                <PhoneNumberInput
                    name={prefixes.phone}
                    value={form.values[prefixes.phone]}
                    placeholder={features['phone']}
                    onPhoneNumberChange={(phoneNumber: string) => onChangeEvent(prefixes.phone, phoneNumber)}
                    onKeyDown={onKeyDown}
                />
            </InputWrapper>
        </React.Fragment>
    )

    return {
        node: (onChangeEvent: (name: string, value: string) => void, onKeyDown: (event: any) => void) => htmlNode(onChangeEvent, onKeyDown),
        validate: (submission: any) => validate(submission, prefixes),
        yup: yupObject
    }
})

export default ContactContent
