import React, {useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import Product from "../../../models/product";
import Col from "../../Basic/Col";
import Section from "../../Basic/Section";
import Price from "../../Price";
import ResourceImage from "../../ResourceImage";
import {useViewportWidth} from "../../../util/WindowInfo";
import AccordionGroup from "../../AccordionGroup";
import ProductAsset from "../../../models/productAsset";
import {PossibleBrands} from "../../../config";
import Img from "../../Basic/Img";
import {imageProps} from "../../../util/image";
import HTMLComponent from "../../Basic/HTMLComponent";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";
import ProductActions from "../../ProductActions";
import {Link} from "react-router-dom";
import ModalOverlay from "../../Basic/ModalOverlay";

/****** Gallery Image Section *****/
interface GalleryImageSectionProps {
    featureImage: ProductAsset,
    galleryImages: ProductAsset[],
    displayImage: ProductAsset,
    setDisplayImage: (displayImage: ProductAsset) => void
}

const GalleryImageSection: React.FC<GalleryImageSectionProps> = ({featureImage, galleryImages, displayImage, setDisplayImage}) => {
    const buildAsset = (asset: ProductAsset) => {
        return asset ? (
            <ResourceImage
                className={asset === displayImage ? 'active' : ''}
                image={asset.url ? asset.url : ''}
                alt={asset.name}
                width={asset.width ? asset.width : ''}
                height={asset.height ? asset.height: ''}
                onClick={() => setDisplayImage(asset)}
            />
        ) : null
    }

    return (
        <div className={'gallery-images'}>
            {buildAsset(featureImage)}
            {galleryImages.map((galleryAsset) => {return buildAsset(galleryAsset)})}
        </div>
    )
}

/***** Product Details Section *****/
interface ProductDetailsSectionProps {
    brand: PossibleBrands,
    product: Product
}

const ProductDetailsSection: React.FC<ProductDetailsSectionProps> = ({product, brand}) => {
    let productFeatureImage = product.assets?.find((asset) => asset.type === 'feature')
    let galleryProductImages = product.assets?.filter((asset) => asset.type === 'gallery')
    let specificationFiles = product.assets?.filter((asset) => asset.type === 'specs')

    const [productQuantity, setProductQuantity] = useState<number>(1)
    const [displayImage, setDisplayImage] = useState<ProductAsset|undefined>(productFeatureImage!)
    const [showImgModal, setShowImgModal] = useState(false);
    const [mobile, setMobile] = useState(false)
    const windowWidth = useViewportWidth()

    const displayImageProps = imageProps(brand, displayImage, product.title)
    const features = ['sku-identifier', 'return-disclaimer']
    let specificationLinks = null

    useEffect(() => {
        setMobile(windowWidth < 768)
    }, [windowWidth]);

    const productHeading = <h2 className={'name'}>{product.title}</h2>

    const featuresAndSpecificationDetails = []
    if(product.feature){
        featuresAndSpecificationDetails.push({label: 'Features', children: <HTMLComponent className={'features'} content={product.feature} />})
    }

    if (product.specification) {

        featuresAndSpecificationDetails.push({
            label: 'Specifications',
            children: (
                <React.Fragment>
                    {product.specification ? <HTMLComponent className={'specifications'} content={product.specification}/> : null}
                    {specificationLinks}
                </React.Fragment>
            )
        })
    }

    if (specificationFiles?.length) {
        specificationLinks = specificationFiles?.map(productAsset => {

            // Migrated assets have auto generated URLs, so we have to look at the source to get the actual file name
            const linkNameUrlParts = productAsset.source ? (
                productAsset.source.split('/')
            ) : productAsset.url.split('/')

            const fileNameParts = linkNameUrlParts[linkNameUrlParts.length-1].split('.')
            const fileName = fileNameParts.shift()?.replace(/_/g, ' ')
            const fileExtension = '(' + fileNameParts.join('.').toUpperCase() + ')'

            return <Link key={productAsset.id} to={productAsset.url} className={'spec-link'} target={"_blank"}>{fileName} {fileExtension}</Link>
        })
    }


    return (
        <Section id={'product-id-' + product.id}>
            <Col colWidth={6} className={'display-image'}>
                {mobile ? productHeading : null}
                <Img {...displayImageProps} onClick={() => setShowImgModal(true)}/>
            </Col>
            <Col colWidth={6}>
                {!mobile ? productHeading : null}
                <StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()}>
                    <StaticFeatureContext.Consumer>
                        {context => (
                            <React.Fragment>
                                <div className={'product-info'}>
                                    {context.features['sku-identifier'] ? (
                                        <div className={'sku'}>
                                            <p className={'sku-label'}>{context.features['sku-identifier']}</p>
                                            <p>{product.sku}</p>
                                        </div>
                                    ) : ''}
                                    <Price price={product.cost_dealer} sale={product.cost_sale} free={product.free} />
                                </div>
                                {product.body ? <HTMLComponent className={'product-note'} content={product.body}/> : null}
                                {product.return && context.features['return-disclaimer'] ? <p className={'disclaimer'}>{context.features['return-disclaimer']}</p> : null}
                            </React.Fragment>
                        )}
                    </StaticFeatureContext.Consumer>
                </StaticFeatureContextProvider>
                <div className={'add-to-cart-section'}>
                    <ProductActions product={product} quantity={productQuantity} onQuantityAdjust={(amount) => setProductQuantity(amount)}/>
                </div>
                {specificationLinks ? <p className={'specification-links'}>{specificationLinks}</p> : null}
                {galleryProductImages?.length ? (
                    <GalleryImageSection
                        featureImage={productFeatureImage!}
                        galleryImages={galleryProductImages}
                        displayImage={displayImage!}
                        setDisplayImage={setDisplayImage}/>
                ) : null}
                <AccordionGroup
                    className={'features-specs'}
                    closedIcon={'arrow-down'} openIcon={'arrow-up'}
                    content={featuresAndSpecificationDetails}/>
            </Col>
            <ModalOverlay align={"center"} onClose={() => setShowImgModal(false)} open={showImgModal} className={'enlarge-img-modal'} title={product.title} verticalMargin>
                <div className={'image-zoom'}>
                    <Img {...displayImageProps} />
                </div>
            </ModalOverlay>
        </Section>
    )
}

export default ProductDetailsSection
