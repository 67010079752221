import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import Product from "../models/product";
import {connect} from "../data/connect";
import Brand from "../models/brand";
import {mergeBrandOptions} from "../models/brand-option";

/**
 * The state interface for our state
 */
export interface CategoryProductsContextState extends BasePaginatedContextState<Product> {}

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContexts: CategoryProductsContextState[] = [];
let categoryProductsGroups: {[key: string]: (newState: CategoryProductsContextState) => void} = {};

function createDefaultState(): CategoryProductsContextState {
    return {
        ...defaultBaseContext(),
        loadAll: false,
        limit: 18,
        expands: [
            'assets',
            'brandOptions',
            'brands',
        ],
        filter: {
            'published_at': 'notnull'
        },
        order: {
            'weight': 'desc',
            'title': 'asc',
        }
    }
}

/**
 * The actual context component
 */
export const CategoryProductsContext = React.createContext<CategoryProductsContextState>(createDefaultState());

interface OwnProps extends BasePaginatedContextProviderProps {
    categoryId: number,
}

interface StateProps {
    brand: Brand
}

export interface CategoryProductsContextProviderProps extends OwnProps, StateProps{
}

const CategoryProductsContextProvider: React.FC<PropsWithChildren<CategoryProductsContextProviderProps>> = (({categoryId, brand, ...rest}) => {
    const [contextState, setContextState] = useState({
       ...( persistentContexts[categoryId] ? persistentContexts[categoryId] : createDefaultState()),
        initialLoadComplete: false,
    });
    const [categoryProducts, setCategoryProducts] = useState<Product[]>([])
    const [instanceKey, _] = useState(Math.random() + "-" + Date.now());

    const setPersistedState = (newState: CategoryProductsContextState) => {
        persistentContexts[categoryId] = newState;
        Object.keys(categoryProductsGroups).forEach(key => {
            if (key != instanceKey) {
                categoryProductsGroups[key](persistentContexts[categoryId])
            }
        });
    }
    const updateContext = (newState: CategoryProductsContextState) => {
        const loadedProductsForBrand = [...newState.loadedData].filter( (product: Product) => product.brands?.find(categoryBrand => categoryBrand.id === brand.id))
        const brandedProducts = loadedProductsForBrand.map( product => mergeBrandOptions(product, brand))

        setCategoryProducts(brandedProducts)
        setContextState(newState)

        if (brandedProducts.length < 6 && newState.hasAnotherPage) {
            newState.loadNext()
        }
    }

    const fullContext = {
        ...contextState,
        ...createCallbacks(setContextState, contextState,'/categories/' + categoryId + '/products')
    }

    useEffect(() => {
        if (contextState.initialLoadComplete && contextState != persistentContexts[categoryId]) {
            setPersistedState(contextState)
            updateContext(contextState)
        }
    }, [contextState])

    useEffect(() => {
        categoryProductsGroups[instanceKey] = updateContext;

        return () => {
            delete categoryProductsGroups[instanceKey];
        }
    }, []);

    useEffect(() => {
        if (persistentContexts[categoryId] && persistentContexts[categoryId].initialLoadComplete) {
            updateContext(persistentContexts[categoryId]);
        } else {
            contextState.initiated = true;
            const newContext = prepareContextState(setContextState, contextState, '/categories/' + categoryId + '/products');

            newContext.initialLoadComplete = false;
            updateContext({...newContext});
            newContext.refreshData(false)
        }
    }, [categoryId]);

    return (
        <CategoryProductsContext.Provider value={{...fullContext, loadedData: categoryProducts}}>
            <CategoryProductsContext.Consumer>
                { context => context.initialLoadComplete && context.loadedData ? (
                    rest.children
                ) : null}
            </CategoryProductsContext.Consumer>
        </CategoryProductsContext.Provider>
    )
});

export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: CategoryProductsContextProvider
});
