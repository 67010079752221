import React, {Dispatch, PropsWithChildren, SetStateAction, useEffect, useState} from 'react';
import Order, {generateEmptyOrder} from "../models/order/order";
import OrderRequests from "../services/requests/OrderRequests";
import OrderProduct, {formatOrderProducts, formatSingleOrderProduct} from "../models/order/order-product";

interface CurrentOrderContextState {
    order: Order,
    initiated: boolean,
    loadComplete: boolean,
    networkError: boolean,
}

export interface CurrentOrderContextStateConsumer extends CurrentOrderContextState {
    addToCurrentOrder: (order: Order, orderProduct: OrderProduct, org_id: number) => void,
    updateCurrentOrder: (order: Order, orderProductId: number, totalPrice: number, orderProduct?: OrderProduct) => void,
    clearCurrentOrder: (order_id: number) => void
}

let persistedState = {
    order: generateEmptyOrder(),
    initiated: false,
    loadComplete: false,
    networkError: false,
} as CurrentOrderContextState;

let orderSubscriptions: {[key: string]: Dispatch<SetStateAction<CurrentOrderContextState>>} = {};

function createDefaultState(): CurrentOrderContextStateConsumer {
    return {
        ...persistedState,
        addToCurrentOrder: (order: Order, orderProduct: OrderProduct, org_id: number) => {},
        updateCurrentOrder: (order: Order, orderProductId: number, totalPrice: number, orderProduct?: OrderProduct) => {},
        clearCurrentOrder: (order_id: number) => {},
    }
}

export const CurrentOrderContext = React.createContext<CurrentOrderContextStateConsumer>(createDefaultState());

const setPersistedState = (order: Order) => {
    persistedState = {
        order: {...order},
        initiated: true,
        loadComplete: true,
        networkError: false,
    };
    Object.values(orderSubscriptions).forEach(callback => callback(persistedState));
}

const setNetworkError = () => {
    persistedState = {
        ...persistedState,
        networkError : true,
    }
}

const setInitiated = () => {
    persistedState = {
        ...persistedState,
        initiated : true,
    }
    Object.values(orderSubscriptions).forEach(callback => callback(persistedState));
}

interface CurrentOrderContextProviderProps {
    reset?: boolean,
    organization_id?: number
}

/**
 * Allows child components the ability to easily use the information of the currently logged in user
 */
const CurrentOrderContextProvider: React.FC<PropsWithChildren<CurrentOrderContextProviderProps>> = ({organization_id, reset, ...props}) => {
    const [currentOrderContext, setCurrentOrderContext] = useState(persistedState);
    const [instanceKey, _] = useState(Math.random() + "-" + Date.now());

    useEffect(() => {
        orderSubscriptions[instanceKey] = setCurrentOrderContext;

        return () => {
            delete orderSubscriptions[instanceKey];
        }
    }, []);

    useEffect(() => {
        if (organization_id) {
            setInitiated()
            loadInfo()
        }
    }, [organization_id]);

    const loadInfo = async () => {
        // If we want to request an unsubmitted order from the server, we do that here
        if(organization_id && !currentOrderContext.loadComplete) {
            // Get the unsubmitted order for the organization. If no orders exist, create an empty order
            try {
                const order = await OrderRequests.getOpenOrganizationOrder(organization_id)

                if (order) {
                    setPersistedState(order)
                }

            } catch (error: any) {
                if (error && error.status) {
                    const ignoredStatuses = [
                        429, 499,
                        500, 503,
                    ]
                    if (!ignoredStatuses.includes(error.status)) {
                        // there is an error, and it is not a hang up
                        console.warn('unable to retrieve order from the server')
                    }
                }
                else {
                    setNetworkError();
                }
            }
        }
    }

    useEffect(() => {
        if(reset){
            setPersistedState(generateEmptyOrder())
        }
        loadInfo()
    }, [reset]);

    const addToOrder = async (order: Order, orderProduct: OrderProduct, org_id: number) => {
        const totalPrice = order.total_price.toFixed(2)

        // Make a request to the API to update the existing order, or create a new one if one does not exist
        if(!order.id) {
            const newOrder = await createOrder(org_id)
            order = {...newOrder, order_products: order.order_products, total_price: order.total_price}
        }
        const newProduct = formatSingleOrderProduct(orderProduct)
        const newOrderProduct = await OrderRequests.addOrderItem(order.id!, newProduct)
        .catch((error) => {
            console.log('Unable to add "' + orderProduct.product.title + '" to the order.')
            console.log(error)
        })
        await OrderRequests.updateOrder(order.id!, {total_price: totalPrice})

        // Update the order product id so that we can adjust it in the future
        for (let i = 0; i < order.order_products.length; i++) {
            if (order.order_products[i].product.id === orderProduct.product.id) {
                order.order_products[i].id = newOrderProduct?.id
                break;
            }
        }
        // Set the persistent order
        setPersistedState(order)
    }

    const updateOrder = async (order: Order, orderProductId: number, totalPrice: number, orderProduct?: OrderProduct) => {
        setPersistedState(order)

        let requestedOrder: Promise<OrderProduct>|null = null

        // If an orderProduct is set, update an exising product. Otherwise, delete the product.
        if (orderProduct) {
            const updatedProduct = {quantity: orderProduct.quantity}
            requestedOrder = OrderRequests.updateOrderItem(order.id!, orderProductId, updatedProduct)
        }
        else {
            requestedOrder = OrderRequests.deleteOrderItem(order.id!, orderProductId)
        }

        // Make request to API to update or delete order item, then update the total price of the order
        await requestedOrder.catch((error) => {
            console.log('Unable to update product number' + orderProductId + 'in order.')
            console.log(error)
        })

        await OrderRequests.updateOrder(order.id!, {total_price: totalPrice.toFixed(2)})

    }

    const clearOrder = async (order_id: number) => {

        const newOrder = await createOrder(organization_id!)

        setPersistedState(newOrder)

        // Make a request to the API to delete the order
        await OrderRequests.deleteOrder(order_id!).catch((error) => {
            console.log('Unable to delete order number' + order_id + '.')
            console.log(error)
        })
    }

    const createOrder = async (org_id: number) => {
        const order = generateEmptyOrder()
        const totalFree = Number(0).toFixed(2)
        const totalCall = Number(0).toFixed(2)

        // Add the new product to the order
        const serverOrderData = {
            ...order,
            order_products: formatOrderProducts(order.order_products),
            total_price: Number(0).toFixed(2),
            total_free: totalFree,
            total_call: totalCall
        }
        delete serverOrderData['id'];

        const newOrder = await OrderRequests.postOrder(org_id, serverOrderData).catch((error) => {
            console.log('Unable to create order.')
            console.log(error)
        })
        order.id = newOrder?.id

        return order
    }

    const fullContext = {
        ...currentOrderContext,
        addToCurrentOrder: addToOrder,
        updateCurrentOrder: updateOrder,
        clearCurrentOrder: clearOrder
    } as CurrentOrderContextStateConsumer;

    return (
        <CurrentOrderContext.Provider value={{...fullContext}}>
            <CurrentOrderContext.Consumer>
                { context => context.loadComplete && context.order ? (
                    props.children
                ) : null}
            </CurrentOrderContext.Consumer>
        </CurrentOrderContext.Provider>
    )
};

export default CurrentOrderContextProvider;
