import React, {PropsWithChildren, useEffect, useState} from 'react';
import Product from "../models/product";
import Brand from "../models/brand";
import {mergeBrandOptions} from "../models/brand-option";
import ProductRequests from "../services/requests/ProductRequests";
import LoadingScreen from "../components/Loaders/LoadingScreen";
import {connect} from "../data/connect";
import CategoryRequests from "../services/requests/CategoryRequests";


type bestSellingCache = {
    [key: number]: Product[]|undefined
}
let cachedBestSellers: bestSellingCache = {};

/**
 * The structure of the consumer
 */
export interface BestSellingProductsContextConsumerState {
    hasLoaded: boolean,
    notFound: boolean,
    products?: Product[],
    setProducts: (products: Product[]) => void,
}

let defaultContext: BestSellingProductsContextConsumerState = {
    hasLoaded: false,
    notFound: false,
    products: [],
    setProducts: (products: Product[]) => {}
};

export const BestSellingProductsContext = React.createContext<BestSellingProductsContextConsumerState>(defaultContext);

interface OwnProps {
    categoryId?: number,
    skipCache?: boolean,
}

interface StateProps {
    brand: Brand
}

export interface BestSellingProductsContextProviderProps extends OwnProps, StateProps {
}

const BestSellingProductsContextProvider: React.FC<PropsWithChildren<BestSellingProductsContextProviderProps>> = ({categoryId = 0, skipCache, brand, children}) => {
    const [bestSellersState, setBestSellersState] = useState(defaultContext)

    const setAndBrandProduct = (productContext: BestSellingProductsContextConsumerState) => {
        if(productContext.products) {
            const brandedProducts = productContext.products.filter(product => product.brands?.find(productBrand => productBrand.id === brand.id)).map(product => (
                mergeBrandOptions(product, brand)
            ))

            setBestSellersState({
                ...productContext,
                products: brandedProducts
            })
        }
    }

    const setProducts = (products: Product[]): void => {
        cachedBestSellers[categoryId] = {...products};
        setAndBrandProduct({
            ...bestSellersState,
            products: products
        })
    }

    useEffect(() => {
        if (!skipCache && cachedBestSellers[categoryId]) {
            setAndBrandProduct({
                hasLoaded: true,
                notFound: false,
                products: cachedBestSellers[categoryId],
                setProducts: setProducts,
            });
        } else {
            setAndBrandProduct({
                ...bestSellersState,
                hasLoaded: false,
            });
            // TODO reinstall the ORDER options for getting Best Sellers, once configured on the serverside
            const request = categoryId !== 0 ? (
                CategoryRequests.getCategoryProducts(categoryId, {
                    filter: {'published_at': 'notnull'},
                    order: {
                        // 'items_sold': 'DESC',
                        // 'price': 'DESC',
                    }
                })
            ) : (
                ProductRequests.getProducts({
                    filter: {'featured': '1', 'published_at': 'notnull'},
                    order: {
                        // 'items_sold': 'DESC',
                        // 'price': 'DESC',
                    }
                })
            )

            request.then(products => {
                cachedBestSellers[categoryId] = products;
                setAndBrandProduct({
                    hasLoaded: true,
                    notFound: false,
                    products: products,
                    setProducts,
                });
            }).catch(() => {
                setAndBrandProduct({
                    ...bestSellersState,
                    hasLoaded: true,
                    notFound: true,
                });
            })
        }
    }, [categoryId]);

    return (
        <BestSellingProductsContext.Provider value={{...bestSellersState, setProducts}}>
            <BestSellingProductsContext.Consumer>
                {context => (context.hasLoaded ? (
                        <React.Fragment>
                            {children}
                        </React.Fragment>
                    ) : <LoadingScreen/>
                )}
            </BestSellingProductsContext.Consumer>
        </BestSellingProductsContext.Provider>
    )
}

export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: BestSellingProductsContextProvider
});
