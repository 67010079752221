import {ActionType} from '../../util/types';


export const incrementLoadingCount = () => ({
    type: 'increment-loading-count',
} as const);

export const decrementLoadingCount = () => ({
    type: 'decrement-loading-count',
} as const);

export const incrementOpenModalCount = () => ({
    type: 'increment-open-modal-count',
} as const);

export const decrementOpenModalCount = () => ({
    type: 'decrement-open-modal-count',
} as const);

export const clearLoadingCount = () => ({
    type: 'clear-loading-count',
} as const);

export const setSearchString = (searchString: string) => ({
    type: 'set-search-string',
    searchString
} as const);

export type SessionActions =
    | ActionType<typeof incrementLoadingCount>
    | ActionType<typeof decrementLoadingCount>
    | ActionType<typeof incrementOpenModalCount>
    | ActionType<typeof decrementOpenModalCount>
    | ActionType<typeof clearLoadingCount>
    | ActionType<typeof setSearchString>
