import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {connect} from "../../data/connect";
import {setBrand} from "../../data/persistent/persistent.actions";
import Brand from "../../models/brand";

interface OwnProps {
    to: string,
    isSSO?: boolean
}

interface DispatchProps {
    setBrand: typeof setBrand
}

interface StateProps {
}

interface RedirectExternalProps extends OwnProps, StateProps, DispatchProps {
}

const RedirectExternal: React.FC<RedirectExternalProps> = ({ to, isSSO, setBrand }) => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const brand = searchParams.get('brand')
        if (brand) {
            setBrand({abbreviation: brand} as Brand)
        }

        if (!isSSO || searchParams.get("dealer")) {
            let redirect = to
            if (isSSO) {
                redirect += "?dealer=" + searchParams.get("dealer");
            }
            console.log("Redirecting to " + redirect)
            window.location.href = redirect
        }
        else {
            console.warn("Waiting for Search Params to do redirect...")
        }
    }, [to, isSSO, searchParams]);

    return null;
};



export default connect<OwnProps, StateProps, { }>({
    mapStateToProps: (state) => ({
        tokenData: state.persistent.tokenData
    }),
    mapDispatchToProps: ({
        setBrand
    }),
    component: RedirectExternal
});
