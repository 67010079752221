import React, {useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import CurrentCart from "../../CartComponents/CurrentCart";
import Button from "../../Basic/Button";
import BorderContent from "../BorderContent";
import Order from "../../../models/order/order";
import CurrentOrderContextProvider, {CurrentOrderContext} from "../../../contexts/CurrentOrderContext";
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";
import {useLocation, useParams} from "react-router-dom";
import {BrandRootParams} from "../../../models/brand";
import ConfirmationPopup from "../../ConfirmationPopup";
import {useViewportWidth} from "../../../util/WindowInfo";
import Content from "../../Basic/Content";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";
import LoadingSection from "../../Loaders/LoadingSection";

interface MyCartContentProps {
    id: string,
    order: Order,
    clearCurrentOrder: (order_id: number) => void
}
const MyCartContent: React.FC<MyCartContentProps> = ({id, order, clearCurrentOrder}) => {
    const {state} = useLocation()
    const {brand} = useParams<BrandRootParams>()
    const [willEmpty, setWillEmpty] = useState(false)
    const isCartEmpty = order.order_products.length <= 0
    const [isMobile, setIsMobile] = useState(false)

    const windowWidth = useViewportWidth()
    const classes = 'my-cart-section' + (isCartEmpty ? ' empty' : '')
    const features = ['continue-shopping', 'checkout-headline', 'empty-cart', 'my-cart-headline', 'total-price-label', 'remove-from-cart-warning']

    useEffect(() => {
        setIsMobile(windowWidth < 768)
    }, [windowWidth]);

    const emptyCart = () => {
        if(order){
            // Clear the contents of the cart
            clearCurrentOrder(order.id!)
        }
        setWillEmpty(false)
    }

    const emptyCancelled = () => setWillEmpty(false)

    const getCartButtons = (cartContinueShopping: string, emptyCart: string, checkoutHeadline: string) => {
        return (
            <div className={'cart-btns'}>
                <Button to={`/${brand}/products`} buttonColor={'dk-gray'}>{cartContinueShopping}</Button>
                <Button buttonColor={'dk-gray'} onClick={() => setWillEmpty(!willEmpty)}>{emptyCart}</Button>
                <Button to={`/${brand}/checkout`} buttonColor={'main'}
                        data={{matches: state.matches}}>{checkoutHeadline}</Button>
            </div>
        )
    }

    return (
        <StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()} hideLoader={true}>
            <StaticFeatureContext.Consumer>
                {context => context.hasLoaded ? (
                    <section className={classes} id={id}>
                        {context.features['my-cart-headline'] ? <h2 className={'my-cart-title'}>{context.features['my-cart-headline']}</h2> : ''}
                        <BorderContent className={'my-cart'} id={id}>
                            <CurrentCart totalPosition={'bottom'} totalLabel={context.features['total-price-label']}/>
                            {isMobile ? null : getCartButtons(context.features['continue-shopping'], context.features['empty-cart'], context.features['checkout-headline'])}
                        </BorderContent>
                        {isMobile ? <Content className={'btn-content'}>{getCartButtons(context.features['continue-shopping'], context.features['empty-cart'], context.features['checkout-headline'])}</Content> : null}
                        <ConfirmationPopup show={willEmpty} onConfirm={emptyCart} onCancel={emptyCancelled} returnToTopOnClose={true}>
                            <p className={'bold'}>{context.features['remove-from-cart-warning'].replace('__PRODUCT_NAME__', 'ALL items')}</p>
                        </ConfirmationPopup>
                    </section>
                ) : <LoadingSection id={'loading-cart-content-section'}/> }
            </StaticFeatureContext.Consumer>
        </StaticFeatureContextProvider>

    )
}


interface MyCartSectionProps {
    id: string
}

const MyCartSection: React.FC<MyCartSectionProps> = ({id}) => (
    <MeContextProvider>
        <MeContext.Consumer>
            {contextMe => (
                <CurrentOrderContextProvider organization_id={contextMe.me.organization_id}>
                    <CurrentOrderContext.Consumer>
                        {context => (
                            <MyCartContent id={id} order={context.order}
                                           clearCurrentOrder={context.clearCurrentOrder}/>
                        )}
                    </CurrentOrderContext.Consumer>
                </CurrentOrderContextProvider>
            )}
        </MeContext.Consumer>
    </MeContextProvider>
)

export default MyCartSection
