import React from 'react'

import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import Table from "../../components/Table";
import Section from "../../components/Basic/Section";
import {
	OrganizationOrdersContext,
	OrganizationOrdersContextProvider
} from "../../contexts/organization/OrganizationOrdersContext";
import MeContextProvider, {MeContext} from "../../contexts/MeContext";
import {Link} from "react-router-dom";
import Order, {getOrderStatus} from "../../models/order/order";
import Button from "../../components/Basic/Button";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import LoadingScreen from "../../components/Loaders/LoadingScreen";

/***** ORDER TABLE *****/
interface OrderTableProps {
	data: Order[],
	colTitles: string[],
	className?: string
}

const OrderTable: React.FC<OrderTableProps> = ({data, colTitles, className}) => {
	return (
		<Table id='orders-table' colNames={colTitles} className={className}>
			{data.map((order: Order, index) => {
				return (
					<tr key={index}>
						<td><p>{order.id}</p></td>
						<td><p>{order.submitted_at}</p></td>
						<td><p>{getOrderStatus(order)}</p></td>
						<td><Link to={`${order.id}`}>View</Link></td>
					</tr>
				)
			})}
		</Table>
	)
}

interface NoOrdersViewProps {

}

const NoOrdersView: React.FC<NoOrdersViewProps> = ({}) => {
	const features = ['no-orders-to-show', 'continue-shopping']
	return (
		<StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()} hideLoader={true}>
			<StaticFeatureContext.Consumer>
				{context => context.hasLoaded ? (
					<div className={'no-orders'}>
						<p>{context.features['no-orders-to-show']}</p>
						<Button buttonColor={'main'} to={'../'}>{context.features['continue-shopping']}</Button>
					</div>
				) : <LoadingScreen/>}
			</StaticFeatureContext.Consumer>
		</StaticFeatureContextProvider>
	)
}

/***** ORDER SECTION *****/

interface OrderSectionProps {
	organization_id: number
}

const OrderSection: React.FC<OrderSectionProps> = ({organization_id}) => {
	return (
		<OrganizationOrdersContextProvider customerId={organization_id}>
			<OrganizationOrdersContext.Consumer>
				{context => {
					const hasContent = context.loadedData.length > 0
					return (
						<React.Fragment>
							<Section id={'order-page-headline'}>
								{hasContent ? (
									<React.Fragment>
										<h3 className={'order-page-title'}>Orders</h3>
										<hr/>
									</React.Fragment>
								) : (
									<NoOrdersView/>
								)}
							</Section>
							{hasContent ? (
								<React.Fragment>
									<OrderTable data={context.loadedData} colTitles={['ID', 'Date', 'Status', 'Details']} />
									<StaticFeatureContextProvider featureNames={['load-more-label']} featuresRequestKey={'load-more-label'}>
										<StaticFeatureContext.Consumer>
											{staticContext => context.hasAnotherPage ? (
												<Button className={'expand-more-btn'} buttonColor={"main"} onClick={() => context.loadNext()}>
													{staticContext.features['load-more-label']}
												</Button>
											) : null }
										</StaticFeatureContext.Consumer>
									</StaticFeatureContextProvider>
								</React.Fragment>
							) : null }
						</React.Fragment>
					)}}
			</OrganizationOrdersContext.Consumer>
		</OrganizationOrdersContextProvider>
	)
}

/***** ORDER PAGE *****/
interface OrderProps {
}

const Orders: React.FC<OrderProps> = () => {
	return (
		<MeContextProvider>
			<MeContext.Consumer>
				{context => (
					<PageWrapper id={'orders-page'} title={"Your Orders"}>
						{context.me.organization_managers?.length ? <OrderSection organization_id={context.me.organization_managers[0].organization_id} /> : null}
					</PageWrapper>
				)}
			</MeContext.Consumer>
		</MeContextProvider>
	)
}

export default Orders
