import React, {useEffect, useState} from 'react';
import './index.scss';
import ModalOverlay from "../../Basic/ModalOverlay";
import CurrentCart from "../CurrentCart";
import Button from "../../Basic/Button";
import {useMatches, useParams} from "react-router-dom";
import {BrandRootParams} from "../../../models/brand";
import {PAGES} from "../../../config";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";
import LoadingIndicator from "../../Loaders/LoadingIndicator";

interface CurrentCartPopupProps {
    open: boolean
    onClose: () => void
}

const CurrentCartPopup: React.FC<CurrentCartPopupProps> = ({open, onClose}) => {
    const [currentlyOpen, setCurrentlyOpen] = useState(open)
    const {brand}  = useParams<BrandRootParams>();
    const matches = useMatches()
    const features = ['add-to-cart-success', 'subtotal-price-label', 'continue-shopping']

    useEffect(() => {
        if (open && !currentlyOpen) {
            setCurrentlyOpen(open)
        }
    }, [open]);

    const closePopup = () => {
        onClose()
        setCurrentlyOpen(false)
    }

    const closeCart = () => {
        if (currentlyOpen) {
            closePopup()
        }
    }

    return (
        <StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()} hideLoader={true}>
            <StaticFeatureContext.Consumer>
                {context => (
                    <ModalOverlay className={'current-order-popup'} title={context.hasLoaded ? context.features['add-to-cart-success'] : ''} align={"right"} open={currentlyOpen} onClose={closePopup}>
                        {context.hasLoaded ? (
                            <CurrentCart abbreviated totalPosition={'top'} totalLabel={context.features['subtotal-price-label']} closeCart={closePopup}>
                                <Button to={'/' + brand + PAGES.CART} data={{matches: matches}} buttonColor={"main"} onClick={() => closePopup()}>view cart</Button>
                                <Button onClick={() => closePopup()} buttonColor={"dk-gray"}>{context.features['continue-shopping']}</Button>
                            </CurrentCart>
                        ) : <LoadingIndicator/>}
                    </ModalOverlay>
                )}
            </StaticFeatureContext.Consumer>
        </StaticFeatureContextProvider>
    )
}
export default CurrentCartPopup
