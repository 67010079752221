import React, {useEffect, useState} from 'react'

import {TokenState} from "../../data/persistent/persistent.state";
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "../../data/connect";
import Brand from "../../models/brand";
import axios from "axios";
import {storeReceivedToken} from "../../services/AuthManager";
import LoadingScreen from "../../components/Loaders/LoadingScreen";
import AuthRequests from "../../services/requests/AuthRequests";
import OrganizationRequests from "../../services/requests/OrganizationRequests";
import User from "../../models/user/user";

type ValidationRootParams = { initialToken?: string }

let SSOmeRequest: Promise<User>|null = null;

interface OwnProps {
}

interface StateProps {
	tokenData?: TokenState;
	brand?: Brand
}

interface SSOValidationProps extends StateProps, OwnProps {
}

const baseURL = process.env.REACT_APP_API_URL + 'v1';
const validationRefreshApi = axios.create({ baseURL });
let validationRefreshPromise: Promise<TokenState>|null = null;

const SSOValidation: React.FC<SSOValidationProps> = ({tokenData, brand}) => {
	const {initialToken}  = useParams<ValidationRootParams>();
	const [refreshStatus, setRefreshStatus] = useState(false)
	const [userRetrieved, setUserRetrieved] = useState(false)
	const navigate = useNavigate()

	// Refresh the token, so that the token passed via URL is no longer valid
	const refreshToken = async () => {
		if (!validationRefreshPromise) {
			validationRefreshPromise = validationRefreshApi.post('/auth/refresh', null, {
				headers: {Authorization: `Bearer ${initialToken}`}
			}).then(({data}) => {
				setTimeout(() => {
					validationRefreshPromise = null;
				}, 50)
				return Promise.resolve(storeReceivedToken(data.token));
			});
		}
		tokenData = await validationRefreshPromise
	}

	const enterApplication = (url: string, error: any) => {
		if (error) {
			console.log(error)
		}
		navigate(url)
	}

	const requestOrganization = (user: User)=> {
		if (user.organization_managers?.length) {
			try {
				OrganizationRequests.getMyOrganization(user.organization_managers[0].organization_id).then(organization => {
					if (organization && organization.brands?.length) {
						enterApplication(`/${organization.brands[0]?.abbreviation}/`, "SUCCESS")
					} else {
						enterApplication('/splash/', "user has no related brands")
					}
				})
			}
			catch(error) {
				enterApplication('/splash/', error)
			}
		}
		else {
			enterApplication('/splash/', "user has no related organization")
		}
	}
	const requestLoggedInUser = async () => {
		try {
			SSOmeRequest = AuthRequests.getMe()

			const me = await SSOmeRequest
			if (me) {
				setUserRetrieved(true)
				requestOrganization(me)
			}
			else {
				await requestLoggedInUser()
			}
		}
		catch(error) {
			if (userRetrieved) {
				enterApplication('/splash/', error)
			}
			else {
				await requestLoggedInUser()
			}
		}
		SSOmeRequest = null
	}

	// Refresh is complete, refer the user back to their requested brand landing page
	const refreshComplete = () => {
		let successURL = `/${brand?.abbreviation}/`
		setRefreshStatus(true)
		if (!brand) {
			requestLoggedInUser()
		}
		else {
			navigate(successURL)
		}
	}

	useEffect(() => {
		if (initialToken && !refreshStatus) {
			refreshToken().then(refreshComplete)
		}
	})

	return <LoadingScreen/>
}

export default connect<OwnProps, StateProps, { }>({
	mapStateToProps: (state) => ({
		tokenData: state.persistent.tokenData,
		brand: state.persistent.brand
	}),
	component: SSOValidation
});
