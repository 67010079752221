import React from 'react';
import './index.scss';
import './responsive.scss';
import Section from "../Basic/Section";
import Asset from "../../models/asset";
import Button from "../Basic/Button";
import {BrandColorNames} from "../../models/brand";
import Img from "../Basic/Img";

interface ArticleComponentProps {
    id: string,
    content: string,
    btnText?: string,
    btnLink?: string,
    btnColor?: BrandColorNames,
    image?: Asset,
    sectionClass?: string,
}

const ArticleComponent: React.FC<ArticleComponentProps> = ({id, content, btnLink, btnText, btnColor, image, sectionClass}) => {
    const buttonColor = btnColor ? btnColor : 'white'
    let imageComponent = null as any

    if (image) {
        if (image.height && image.width && image.height > image.width) {

            imageComponent = (
                <div key={image.id} className={'article-banner'}>
                    <Img
                        src={image.url}
                        alt={image.alt ? image.alt : ''}
                        height={image.height}
                        width={image.width}
                    />
                </div>
            )
        }
        else {
            imageComponent = (
                <div
                    key={image.id}
                    className={'article-banner'}
                    style={{backgroundImage: `url(${image.url})`}}
                    title={image.caption ? image.caption : id + '-article-section'}
                />
            )
        }
    }

    return (
        <Section id={id} className={'article-section bg-main' + (sectionClass ? ' ' + sectionClass : '')}>
            <div className={'article-body'}>
                <div className={'article-content'} dangerouslySetInnerHTML={{__html: content}} />
                <Button to={btnLink} buttonColor={buttonColor}>{btnText}</Button>
            </div>
            {imageComponent}
        </Section>
    )
}

export default ArticleComponent
