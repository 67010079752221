import React, {ReactNode} from 'react';
import './index.scss';
import './responsive.scss';
import Price from "../Price";

interface Props {
    headline : string|ReactNode,
    subhead? : string|ReactNode,
    price ?: number
    sale ?: number
    free?: boolean
}

const ResourceCaption: React.FC<Props> = ({headline, subhead, price, sale, free}) => {

    return (
        <div className={'resource-caption'}>
            <div className={'caption'}>
                <p className={'headline'}>{headline}</p>
                {subhead ? <p className={'subhead'}>{subhead}</p> : ''}
            </div>
            {price ? <Price price={price} sale={sale} free={free}/> : null}
        </div>
    )
}

export default ResourceCaption
