import React, {useState} from 'react'

import './index.scss';
import Col from "../../components/Basic/Col";
import PageWrapper from "../../components/Basic/PageWrapper";
import {brands, IEnvironments, loginPage, PossibleBrands} from "../../config";
import {Link, useNavigate} from "react-router-dom";
import Img from "../../components/Basic/Img";
import Section from "../../components/Basic/Section";
import {connect} from "../../data/connect";
import {TokenState} from "../../data/persistent/persistent.state";
import Button from "../../components/Basic/Button";
import {logOut, setBrand} from "../../data/persistent/persistent.actions";
import {clearLoadingCount} from "../../data/session/session.actions";
import Brand from "../../models/brand";
import MeContextProvider, {MeContext} from "../../contexts/MeContext";
import ModalOverlay from "../../components/Basic/ModalOverlay";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import {brandDefaultLogo} from "../../config/image_constants";

interface OwnProps {
}

interface StateProps {
	tokenData?: TokenState;

}

interface DispatchProps {
	logOut: typeof logOut,
	clearLoadingCount: typeof clearLoadingCount,
	setBrand: typeof setBrand
}

interface SplashProps extends StateProps, DispatchProps, OwnProps {
}

const Splash: React.FC<SplashProps> = ({clearLoadingCount, logOut, setBrand, tokenData}) => {
	const loginLink = process.env.REACT_APP_ENV ? loginPage[process.env.REACT_APP_ENV as IEnvironments] : '/sign-in';

	const [isDealerModalOpen, setIsDealerModalOpen] = useState(false);
	const [loginPageLink, setLoginPageLink] = useState(loginLink)
	const navigate = useNavigate();

	const handleLogOut = () => {
		logOut();
		clearLoadingCount();
	}

	const handleBrandSelection = ( brand: string ) => {
		let link = loginLink;

		// if the link is brand specific, add the brand to the front of the link
		if (loginPageLink[0] !== '/' && !loginPageLink.includes('https://')) {
			// If the user is logged in, go to the home page instead of the login link
			link = `/${brand}/` + (tokenData ? '' : loginLink)
			navigate(link);
		}
		else if (tokenData) {
			link = `/${brand}`
			navigate(link);
		}

		setLoginPageLink(link);
		setBrand({abbreviation: brand} as Brand)
		setIsDealerModalOpen(true);
	}

	const handleDealer = ( response: "yes"|"no" ) => {
		setIsDealerModalOpen(false);
		navigate(loginPageLink + '?dealer=' + response);
	}

	return (
		<React.Fragment>
			<PageWrapper id={'splash-page'} title={"Splash Page"}>
				<Section id={'brand-login-links'}>
					{(brands.map((brand: string, index: number) => {
						return (
							<Col colWidth={6} key={index}>
								<a onClick={() => handleBrandSelection(brand)}>
									<Img src={process.env.PUBLIC_URL + brandDefaultLogo[brand as PossibleBrands].url} alt={`${brand} logo`}/>
								</a>
							</Col>
						)
					}))}
					<p>
						In order to access this website, please log in through the Dealer Portal.
						{tokenData ? <Button className={'logout'} buttonColor={'base'} onClick={handleLogOut}>Log Out</Button> : null}
					</p>
					<ConfirmationPopup
						show={isDealerModalOpen}
						onCancel={() => handleDealer('no')}
						onConfirm={() => handleDealer('yes')}
						onDismiss={() => setIsDealerModalOpen(false)}
						confirmText={'Yes'}
						cancelText={'No'}
						confirmColor={'base'}
						cancelColor={'base'}>
							<p className={'bold'}>Are you a Dealer?</p>
					</ConfirmationPopup>
				</Section>
			</PageWrapper>
		</React.Fragment>
	)
}

export default connect<OwnProps, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		tokenData: state.persistent.tokenData
	}),
	mapDispatchToProps: ({
		logOut,
		clearLoadingCount,
		setBrand,
	}),
	component: Splash
});
