import Brand from "../../models/brand";

export interface TokenState {
    token: string;
    receivedAt: number;
}


export default interface PersistentState {
    tokenData?: TokenState;
    brand?: Brand
}


export const initialPersistentState: PersistentState = {
}
