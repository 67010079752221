import {ActionType} from '../../util/types';
import {TokenState} from './persistent.state';
import Brand from "../../models/brand";


export const setBrand = (brand: Brand) => ({
    type: 'set-brand',
    brand
} as const);

export const setTokenData = (tokenData: TokenState) => ({
    type: 'set-token-data',
    tokenData
} as const);


export const logOut = () => ({
    type: 'log-out'
} as const);

export type PersistentActions =
    | ActionType<typeof setTokenData>
    | ActionType<typeof setBrand>
    | ActionType<typeof logOut>
