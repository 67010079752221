import React, {forwardRef} from 'react';
import './index.scss';
import './responsive.scss';
import {Link, useParams} from "react-router-dom";
import ResourceImage, {ResourceImageProps} from "../ResourceImage";
import {BrandRootParams} from "../../models/brand";
import Category from "../../models/category";
import {PAGES} from "../../config";
import {productDefaultAsset} from "../../config/image_constants";

interface Props extends Omit<ResourceImageProps, 'image'|'alt'|'link'|'caption'> {
    category: Category
    ancestors?: Category[]
}

const ResourceCategory = forwardRef<HTMLDivElement, Props>((props, ref) => {

    const {category, ancestors, backgroundColor, className,...additionalProps} = props
    const {brand}  = useParams<BrandRootParams>();

    const image = category.thumbnail ? category.thumbnail : category.image

    const CategoryImage = (
        <ResourceImage
            backgroundColor={backgroundColor}
            image={image?.url ? image.url :  productDefaultAsset[brand!].url}
            alt={image ? (image.alt ? image.alt : category.name ) : brand + ' logo'}
            caption={category.name}
            {...additionalProps} />
    )

    const categoryURLRoot = '/' + brand + PAGES.CATEGORY_ROOT + (ancestors ? ancestors.map(ancestor => '/' + ancestor.slug).join('') : '') + '/'

    return (
        <div className={'resource-category'} ref={ref}>
            <Link to={categoryURLRoot + category.slug}>
                {CategoryImage}
            </Link>
        </div>
    )
})

export default ResourceCategory
