import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import Order from "../../models/order/order";

/**
 * The state interface for our state
 */
export interface OrganizationOrdersContextState extends BasePaginatedContextState<Order> {}

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = createDefaultState();

function createDefaultState(): OrganizationOrdersContextState {
    return {
        ...defaultBaseContext(),
        loadAll: true,
        order: {
            'created_at': 'desc',
        },
        filter: {
            'submitted_at': 'notnull',
        },
        limit: 50,
    }
}

/**
 * The actual context component
 */
export const OrganizationOrdersContext = React.createContext<OrganizationOrdersContextState>(createDefaultState());

export interface OrganizationOrdersContextProviderProps extends BasePaginatedContextProviderProps{
    customerId: number,
}

export const OrganizationOrdersContextProvider: React.FC<PropsWithChildren<OrganizationOrdersContextProviderProps>> = (props => {
    const [organizationOrdersState, setOrganizationOrdersState] = useState(persistentContext);
    useEffect(() => {
        prepareContextState(setOrganizationOrdersState, organizationOrdersState, '/organizations/' + props.customerId + '/orders')
    }, [props.customerId]);

    const fullContext = {
        ...persistentContext,
        ...createCallbacks(setOrganizationOrdersState, persistentContext, '/organizations/' + props.customerId + '/orders')
    }

    return (
        <OrganizationOrdersContext.Provider value={fullContext}>
            {props.children}
        </OrganizationOrdersContext.Provider>
    )
});
