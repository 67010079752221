export interface StringKeyObject { [key: string]: string }

// Function to loop through a key string object and validate each of the form fields based on the submission
export const validate = ((submission: any, fieldNames: {[key: string]: string} ): string|undefined => {
    for(const field in fieldNames) {
        if(fieldNames.hasOwnProperty(field)){
            if(submission.errors[fieldNames[field]] && submission.touched[fieldNames[field]]){
                return submission.errors[fieldNames[field]]
            }
        }
    }
    return undefined
})

// Prints form field error (required or invalid)
export const getFieldError = (name: string, invalid?: boolean) => {
    return invalid ? 'Invalid ' + name : name + ' is required'
}