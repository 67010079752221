import React, {useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import AccordionGroup from "../AccordionGroup";
import {IconNames} from "../../models/icon";
import {CollapsibleContainerGroupProps} from "../Basic/CollapsibleContainerGroup";
import MenuGroup, {buildMenuDropdowns} from "../../models/menu-item";

interface MenuExpanderProps extends Omit<CollapsibleContainerGroupProps, 'content'>{
    expanders: MenuGroup[]
    mobileLinkIcon?: IconNames
}

const MenuExpander: React.FC<MenuExpanderProps> = ({expanders, mobileLinkIcon, closeAll, ...rest}) => {
    const [closeMenus, setCloseMenus] = useState(false)

    // If the parent tells us to close all menus
    useEffect(() => {
        if (closeAll) {
            setCloseMenus(closeAll)
        }
    }, [closeAll]);

    // If a child tells us to close all menus
    useEffect(() => {
        if (closeMenus) {
            setCloseMenus(false)
        }
    }, [closeMenus]);

    return (
        <AccordionGroup className={'menu-expander'} content={buildMenuDropdowns(expanders, mobileLinkIcon)} closeAll={closeMenus} {...rest} />
    )
}

export default MenuExpander
