import React, {useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import ModalOverlay from "../Basic/ModalOverlay";
import MediaQuery from "react-responsive";
import {menu_constants} from "../../config/string_constants";
import Icon from "../Basic/Icon";
import Img from "../Basic/Img";
import {Link, useParams} from "react-router-dom";
import {BrandRootParams} from "../../models/brand";
import MenuExpander from "../MenuExpander";
import {PAGES, PossibleBrands} from "../../config";
import SearchBar from "../SearchBar";
import CurrentOrderContextProvider, {CurrentOrderContext} from "../../contexts/CurrentOrderContext";
import {calculateNumOfItemsInOrder} from "../../models/order/order";
import MenuItem, {buildMenu} from "../../models/menu-item";
import MeContextProvider, {MeContext} from "../../contexts/MeContext";
import {brandDefaultLogo, brandReversedLogo} from "../../config/image_constants";
import ParentCategoriesContextProvider, {ParentCategoriesContext} from "../../contexts/ParentCategoriesContext";
import Category from "../../models/category";
interface CartCounterProps {
    cartTotal: number
}

const CartCounter: React.FC<CartCounterProps> = ({cartTotal}) => {
    return (
        <span className={'circle-outline'}>
            <span className={'product-count'}>{cartTotal}</span>
        </span>
    )
}

interface MainMenuContentProps{
    menuItems: Category[]
    brand: PossibleBrands
    logoClicked: () => void
    shouldCloseMenus: boolean
    reverseLogo: boolean
}

const MainMenuContent: React.FC<MainMenuContentProps> = ({menuItems, brand, logoClicked, shouldCloseMenus, reverseLogo}) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [menuExpanders, setMenuExpanders] = useState<MenuItem[]>([])
    const logo = reverseLogo ? brandReversedLogo[brand!] : brandDefaultLogo[brand!]

    useEffect(() => {
        const menu = buildMenu('main-menu', menuItems, brand)
        setMenuExpanders(menu)
    }, [menuItems.length, brand]);

    const menuExpander = menuExpanders ? (
        <MenuExpander key={'main-menu'}
                      expanders={menuExpanders}
                      closeAll={shouldCloseMenus}
                      closedIcon={'arrow-down'}
                      mobileLinkIcon={'arrow-right'}
        />) : ''

    return (
        <React.Fragment>
            <MediaQuery maxWidth={769}>
                <p className={'hamburger'}>
                    <Icon iconName={"hamburger"} onClick={() => setMobileMenuOpen(true)}/>
                </p>
                <ModalOverlay title={menu_constants.site_name} className={'main-menu-modal'} align={'left'} onClose={() => setMobileMenuOpen(false)} open={mobileMenuOpen}>
                    {menuExpander}
                </ModalOverlay>
            </MediaQuery>
            <Link to={'/' + brand + '/'} onClick={logoClicked}>
                <Img src={process.env.PUBLIC_URL + logo.url} alt={logo.alt!} className={'brand-logo'}/>
            </Link>
            <MediaQuery minWidth={770}>
                <div className={'desktop-menu-information'}>
                    <p className={'site-name'}>{menu_constants.site_name}</p>
                    {menuExpander}
                </div>
            </MediaQuery>
        </React.Fragment>
    )
}

interface MainMenuProps {
    onCartClicked: () => void
    reverseLogo: boolean
    reset?: boolean
    slot?: string
}

const MainMenu: React.FC<MainMenuProps> = ({onCartClicked, reverseLogo, reset, slot}) => {
    const [showSearchBar, setShowSearchBar] = useState(false)
    const [shouldCloseMenus, setShouldCloseMenus] = useState(false)
    const {brand} = useParams<BrandRootParams>()
    const brandRoot = '/' + brand

    // reset the menu to its default state
    useEffect(() => {
        if (reset) {
            setShouldCloseMenus(true)
        }

        // reset complete, prime for next navigation
        if (shouldCloseMenus) {
            setShouldCloseMenus(false)
        }
    }, [shouldCloseMenus, reset]);

    // When one of the navigation icons or the logo is clicked, generic statements
    const menuElementClicked = (hideSearch = true) => {
        setShouldCloseMenus(true)

        if (hideSearch) {
            setShowSearchBar(false)
        }
    }

    // Specific menu item click events
    const logoClicked = () => {
        menuElementClicked()
    }
    const searchClicked = () => {
        menuElementClicked(false)
        setShowSearchBar(!showSearchBar)
    }
    const profileClicked = () => {
        menuElementClicked()
        return true
    }
    const cartClicked = () => {
        onCartClicked()
        menuElementClicked()
    }

    return (
        <MeContextProvider hideLoader={true}>
            <MeContext.Consumer>
                {contextMe => (
                    <ParentCategoriesContextProvider>
                        <ParentCategoriesContext.Consumer>
                            {context => context.loadedData ? (
                                <div className={'menu-buffer'} slot={slot}>
                                    <SearchBar onSearch={() => setShowSearchBar(false)} className={showSearchBar ? 'active' : ''}/>
                                    <div className={'main-menu'}>
                                        <div className={'menu-content'}>
                                            <MainMenuContent menuItems={context.loadedData} shouldCloseMenus={shouldCloseMenus} brand={brand!} reverseLogo={reverseLogo} logoClicked={logoClicked}/>
                                            <p className={'menu-icons'}>
                                                <Icon iconName={"search"} onClick={searchClicked}/>
                                                <Link to={brandRoot + PAGES.ORDERS_ROOT} onClick={profileClicked}>
                                                    <Icon iconName={"profile"}/>
                                                </Link>
                                                <span className={'cart-link'}>
                                                    <CurrentOrderContextProvider organization_id={contextMe.me.organization_id}>
                                                        <CurrentOrderContext.Consumer>
                                                            {context => {
                                                                const cartTotal = calculateNumOfItemsInOrder(context.order.order_products)
                                                                return (
                                                                    <React.Fragment>
                                                                        <Icon iconName={"cart"} onClick={cartClicked} className={(cartTotal > 99 ? 'three-digit' : (cartTotal > 9 ? 'two-digit' : ''))}/>
                                                                        <CartCounter cartTotal={cartTotal}/>
                                                                    </React.Fragment>
                                                                )
                                                            }}
                                                        </CurrentOrderContext.Consumer>
                                                    </CurrentOrderContextProvider>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : null }
                        </ParentCategoriesContext.Consumer>
                    </ParentCategoriesContextProvider>
                )}
            </MeContext.Consumer>
        </MeContextProvider>
    )
}

export default MainMenu
