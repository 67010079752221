import React, {HTMLAttributes} from 'react';
import './index.scss';
import Button from "../../Basic/Button";
import Icon from "../../Basic/Icon";
import {useNavigate} from "react-router-dom";


interface Props extends HTMLAttributes<HTMLOrSVGElement> {
}

const AuthenticationNavigation: React.FC<Props> = ({className, ...props}) => {
	const navigate = useNavigate();

	return (
		<nav className={'authentication-nav' + (className ? ' ' + className : '')} {...props}>
			<Button onClick={() => navigate(-1)}><Icon iconName={'arrow-left'}/> <span>Cancel</span></Button>
		</nav>
	)
}

export default AuthenticationNavigation
