import CategoryProductsContextProvider, {
    CategoryProductsContext,
    CategoryProductsContextState
} from "../../contexts/CategoryProductsContext";
import React, {useEffect} from "react";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import Section from "../Basic/Section";
import Button from "../Basic/Button";
import Category from "../../models/category";
import HTMLComponent from "../Basic/HTMLComponent";
import {useMatches} from "react-router-dom";
import CategoryList from "../PageSections/CategoryList";
import ProductList from "../PageSections/ProductList";
import LoadingScreen from "../Loaders/LoadingScreen";

interface CategoryOptionsListLoadMoreProps {
    categoryContext: CategoryProductsContextState
}

const CategoryOptionsListLoadMore: React.FC<CategoryOptionsListLoadMoreProps> = ({categoryContext}) => (
    categoryContext.hasAnotherPage ? (
        <StaticFeatureContextProvider featureNames={['load-more-label']} featuresRequestKey={'load-more-label'}>
            <StaticFeatureContext.Consumer>
                {staticContext => (
                    <Section id={'load-more'} content_props={{'className': 'pad-bot'}}>
                        <Button className={'load-more'} buttonColor={'main'} onClick={() => categoryContext.loadNext()}>{staticContext.features['load-more-label']}</Button>
                    </Section>
                )}
            </StaticFeatureContext.Consumer>
        </StaticFeatureContextProvider>
    ) : null )

/**
 * The category list output
 * - Outputs a list of subcategories, if the current category has subcategories
 * - Handles the category product context, if a category only has 1 product
 *
 * @component
 * @param products the set of products to display
 */
interface CategoryOptionsListProps {
    category: Category
    linkRoot: string
    setPageTitle: (title: string) => void
    isRootCategory?: boolean
    includeBody?: boolean
}

export const CategoryOptionsList: React.FC<CategoryOptionsListProps> = ({category, linkRoot, setPageTitle, isRootCategory, includeBody = true}) => {
    const categoryBody = category.body && !isRootCategory && includeBody ? <HTMLComponent className={"category-body"} content={category.body}/> : null
    const matches = useMatches()

    useEffect(() => {
        setPageTitle(category.name)
    }, [category.name])

    return category.children && category.children?.length > 0 ? (
        <CategoryList id={category.slug} category={category} ancestry={linkRoot}>
            {categoryBody}
        </CategoryList>
    ) : (
        <CategoryProductsContextProvider categoryId={category.id!}>
            <CategoryProductsContext.Consumer>
                {context => context.initialLoadComplete ? (
                    context.loadedData.length ? (
                        <React.Fragment>
                            <ProductList sectionId={category.slug + '-products'} products={context.loadedData} breadcrumbs={matches}>
                                {categoryBody}
                            </ProductList>
                            <CategoryOptionsListLoadMore categoryContext={context}/>
                        </React.Fragment>
                    ) : (
                        <Section id={'no-products-available'}>
                            <StaticFeatureContextProvider featureNames={['no-products-available']} featuresRequestKey={'no-products-available'}>
                                <StaticFeatureContext.Consumer>
                                    {staticContext => (
                                        <h3>{staticContext.features['no-products-available']}</h3>
                                    )}
                                </StaticFeatureContext.Consumer>
                            </StaticFeatureContextProvider>
                        </Section>
                    )
                ) : <LoadingScreen/>}
            </CategoryProductsContext.Consumer>
        </CategoryProductsContextProvider>
    )
}

export default CategoryOptionsList
