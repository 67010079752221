import React from 'react'
import './index.scss';
import Section from "../../Basic/Section";
import Button from "../../Basic/Button";
import PageLink from "../../../models/link";

interface ProductContentProps {
	headline: string
	subhead?: string,
	includeReturnBtn?: boolean,
	returnBtn?: PageLink
}

const NotFoundError: React.FC<ProductContentProps> = ({headline, subhead, includeReturnBtn = false, returnBtn}) => {
	return (
		<Section id={'not-found-error'}>
			<h2>{headline}</h2>
			<p>{subhead}</p>
			{includeReturnBtn ?
				<Button
					to={returnBtn ? returnBtn.link : '..'}
					className={'return-btn'}
					buttonColor={'main'}
					type={'button'}>
					{returnBtn ? returnBtn.title : 'Go Back'}
				</Button>
			: null }
		</Section>
	)
}
export default NotFoundError
