import {PAGES, PossibleBrands} from "./index";
import Category from "../models/category";
import {StringKeyObject} from "../util/form";

export const featuredCategoriesSlugs = ['fixtures', 'signage', 'accessories']
export const resourceCategoriesSlugs = ['planograms', 'printable-signage-templates'] //, 'showroom-floor-planning-services'

export const defaultSiteTitle = 'CNH Dealership Merchandising Services'

export const staticStringFallbacks = {
    'site-title': defaultSiteTitle,
    'add-to-cart-button': 'Add to Cart',
    'add-to-cart-button-abbreviation': 'Add',
    'download-button': 'Download PDF',
    'download-button-abbreviation': 'Download',
    'add-to-cart-success': 'Added to Cart',
    'add-to-cart-success-abbreviation': 'Added',
    'sku-identifier': 'SKU:',
    'total-price-label': 'Total:',
    'subtotal-price-label': 'Subtotal',
    'continue-shopping': 'Continue Shopping',
    'start-shopping': 'Start Shopping',
    'cart-empty': 'Your cart is empty!',
    'no-products-available': 'Sorry! There are currently no products available in this category.',
    'sub-category-does-not-exist': 'Sorry! This sub category does not exist.',
    'no-search-results-available': 'Sorry! We couldn\'t find what you were looking for.',
    'category-does-not-exist': 'Invalid Category',
    'all-text': 'All',
    'return-disclaimer': 'Orders are limited to a 48 hour grace period to make changes or to cancel. After 48 hours, orders cannot be cancelled and the customer is responsible for the full amount of the order in addition to any freight costs.',
    'best-sellers-headline': 'Best Sellers',
    'shop-by-category-headline': 'Shop By Category',
    'contact-us-headline': 'Still Have Questions?',
    'faqs-headline': 'Frequently Asked Questions',
    'recommendation-headline': 'You May Also Like',
    'my-cart-headline': 'My Cart',
    'checkout-headline': 'Checkout',
    'checkout-thank-you': 'Thank You For Your Order!',
    'order-summary-headline': 'Order Summary',
    'no-orders-to-show': 'Orders You Place Will Be Reviewable Here',
    'first_name': 'First Name',
    'last_name': 'Last Name',
    'email': 'Email',
    'phone': 'Phone',
    'fax': 'Fax',
    'company': 'Company',
    'address': 'Address',
    'address_1': 'Address 1',
    'address_2': 'Address 2',
    'city': 'City',
    'country': 'Country',
    'state': 'State/Province',
    'zip': 'Zip',
    'subject': 'Subject',
    'individual-label': 'Individual Placing the Order:',
    'shipping-contact': 'Shipping Contact:',
    'shipping-address': 'Shipping Address:',
    'billing-contact': 'Billing Contact:',
    'billing-address': 'Billing Address:',
    'additional-info': 'Additional Information:',
    'image-label': 'Image',
    'details-label': 'Details',
    'quantity-abbreviation-label': 'Qty',
    'price-label': 'Price',
    'load-more-label': 'Load More',
    'name': 'Name',
    'view-cart': 'View Cart',
    'view-now': 'View Now',
    'remove-from-cart-warning': 'Are you sure you want to remove __PRODUCT_NAME__ from your cart?',
    'unavailable-product-button-text': 'Unavailable',
    'unavailable-product-link-text': 'Temporarily unavailable. View details.',
    'unavailable-product-message': 'This manufacturer is currently out of production and not taking orders at this time. Please check back for availability updates or <a href="contact-us">contact us</a> with any questions.',
    'contact-for-pricing': 'Please reach out to your CNH Field Aftersales Manager (FAM) for more information.',
} as StringKeyObject

export const breadCrumbLabels = {
    'your-orders': 'Orders',
    'my-cart': 'Cart',
    'resources': 'Resources',
}

export const countries = [
    'United States',
    'Canada'
]

export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
]

export const provinces = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
]

export const menuNameOptions = [
    {
        slug: 'printable-signage-templates',
        name: 'Templates',
    },
    {
        slug: 'showroom-planning-services',
        name: 'Planning Services',
        omitMenu: 'footer'
    }
]

export const menu_constants = {
    site_name: "Dealership Merchandising Services",
    item_names: {
        'categories': 'Categories',
        'resources': 'Resources',
        'support': 'Support',
    },
    resource_menu_items: [
        {
            'name': 'Showroom Planning Services',
            'slug': 'showroom-planning-services',
        },
    ] as Category[],
    support_menu_items: [
        {
            'name': 'Orders',
            'slug': PAGES.ORDERS_ROOT.slice(1)
        },
        {
            'name': 'Log Out',
            'slug':  '../' + PAGES.DEFAULT_LANDING.slice(1)
        },
        {
            'name': 'Contact Us',
            'slug': 'contact-us'
        },
        {
            'name': 'Privacy Notice',
            'slug': 'https://portal.cnh.com/resources/pdf/privacy_en.pdf'
        },
        {
            'name' : 'Terms & Conditions',
            'slug': 'terms-and-conditions'
        }
    ] as Category[]
}

export const noMenuPaths = ['sign-up', 'sign-in']

export const getBrandedNoMenuPaths = (brand: PossibleBrands) => {
    return noMenuPaths.map((path) => {
        return `/${brand}/${path}`
    })
}
