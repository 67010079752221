import React, {PropsWithChildren} from 'react';
import './index.scss';


interface Props {
}

const Disclaimer: React.FC<PropsWithChildren<Props>> = ({children}) => {
	return (
		<p className={'disclaimer'}>
			{children}
		</p>
	)
}

export default Disclaimer
