import React, {PropsWithChildren, useEffect, useState} from "react";
import ErrorPage from "../pages/ErrorPage";
import FeatureRequests from "../services/requests/FeatureRequests";
import LoadingIndicator from "../components/Loaders/LoadingIndicator";
import {StringKeyObject} from "../util/form";

const cachedFeatures: StringKeyObject = {}

/**
 * The structure of the consumer
 */
export interface StaticFeatureContextConsumerState {
    hasLoaded: boolean,
    notFound: boolean,
    features: StringKeyObject,
    setFeatures: (features: StringKeyObject) => void,
}

let defaultContext: StaticFeatureContextConsumerState = {
    hasLoaded: false,
    notFound: false,
    features: {} as StringKeyObject,
    setFeatures: (features: StringKeyObject) => {}
};

export const StaticFeatureContext = React.createContext<StaticFeatureContextConsumerState>(defaultContext);

interface OwnProps {
    featureNames: string[],
    featuresRequestKey: string,
    skipCache?: boolean,
    hideLoader?:boolean,
}

export interface StaticFeatureContextProviderProps extends OwnProps {
}

const StaticFeatureContextProvider: React.FC<PropsWithChildren<StaticFeatureContextProviderProps>> = ({featureNames, featuresRequestKey, skipCache, hideLoader, children}) => {
    const [featureState, setFeatureState] = useState(defaultContext);

    const setFeatures = (features: StringKeyObject): void => {
        setFeatureState({
            ...featureState,
            features: features
        })
    }

    async function getFeatures(): Promise<(StringKeyObject)> {
        let features = {} as StringKeyObject
        setFeatureState({
            ...featureState,
            hasLoaded: false,
        });

        for(let i = 0; i < featureNames.length; i++) {

            if (cachedFeatures.hasOwnProperty(featureNames[i])) {
                features[featureNames[i]] = cachedFeatures[featureNames[i]]
            }
            else {
                // Add each feature to the local list of features to eventually set the state
                features[featureNames[i]] =  await FeatureRequests.getFeature(featureNames[i])
            }
        }

        return features
    }

    useEffect(() => {
        getFeatures().then(features => {
            setFeatureState({
                hasLoaded: true,
                notFound: false,
                features: features,
                setFeatures: setFeatures
            })

            if(Object.keys(features).length === 0) {
                setFeatureState({...featureState, notFound: true})
            }
        }).catch(error => [
            console.warn(error)
        ])
    }, [featuresRequestKey]);

    return (
        <StaticFeatureContext.Provider value={{...featureState, setFeatures}}>
            <StaticFeatureContext.Consumer>
                {context => (context.hasLoaded ?
                    (!context.notFound ? (
                        children
                    ) : (
                        <ErrorPage errorNumber={404}/>
                    )) : (
                            !hideLoader ? <LoadingIndicator/> : children
                    )
                )}
            </StaticFeatureContext.Consumer>
        </StaticFeatureContext.Provider>
    )
}

export default StaticFeatureContextProvider
