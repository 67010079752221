import React, {forwardRef, HTMLAttributes, ReactElement} from 'react';
import './index.scss';
import {Helmet} from "react-helmet";
import { defaultSiteTitle } from '../../../config/string_constants';

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
	id: string,
	title?: string,
}

const PageWrapper: React.FC<Props> = forwardRef<HTMLDivElement, Props>(({id, title, children, ...props}, ref) => {
	let nav = null
	let header = null
	let footer = null
	let content = [] as ReactElement[]

	React.Children.forEach(children,(child) => {
		if (React.isValidElement(child)) {
			switch (child.props.slot) {
				case 'nav':
					nav = child
					break
				case 'header':
					header = child
					break
				case 'footer':
					footer = child
					break
				default:
					content.push(child)
			}
		}
	})

	return (
		<div ref={ref} id={id}>
			<div id={'wrapper'} {...props}>
				<Helmet>
					<title>{ `${title ? title + ' |' : ''} ${defaultSiteTitle}`}</title>
				</Helmet>
				{nav}
				{header}
				<div id={"main"} className={"section"}>
					{content}
				</div>
			</div>
			{footer}
		</div>

	)
})

export default PageWrapper
