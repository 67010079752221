import React, {forwardRef, HTMLAttributes} from 'react';
import './index.scss';
import Content, {ContentProps} from "../Content";


interface Props extends HTMLAttributes<HTMLOrSVGElement> {
	id: string
	tag?: keyof HTMLElementTagNameMap
	no_content_wrapper?: boolean
	content_props?: ContentProps
}

const Section: React.FC<Props> = forwardRef<HTMLOrSVGElement, Props>(({tag: Wrapper = "section", no_content_wrapper, content_props, className, children, ...props}, ref) => {

	const content = no_content_wrapper ? children : (
		<Content {...content_props}>
			{children}
		</Content>
	)

	// @ts-ignore – The ref here gets a little hairy because our tag type is variable
	return (<Wrapper ref={ref} className={'section' + (className ? ' ' + className : '')} children={content} {...props} />)
})

export default Section
