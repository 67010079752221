import React, {PropsWithChildren, useEffect, useState} from 'react';
import Product, {generateEmptyProduct} from '../models/product';
import ProductRequests from '../services/requests/ProductRequests';
import LoadingScreen from '../components/Loaders/LoadingScreen';
import Brand from "../models/brand";
import {connect} from "../data/connect";
import {mergeBrandOptions} from "../models/brand-option";

type productCache = {
    [key: string]: Product|undefined
}
let cachedProducts: productCache = {};

/**
 * The structure of the consumer
 */
export interface ProductContextConsumerState {
    hasLoaded: boolean,
    notFound: boolean,
    product?: Product,
    setProduct: (product: Product) => void,
}

let defaultContext: ProductContextConsumerState = {
    hasLoaded: false,
    notFound: false,
    product: generateEmptyProduct(),
    setProduct: (product: Product) => {}
};

export const ProductContext = React.createContext<ProductContextConsumerState>(defaultContext);

interface OwnProps {
    productSlug: string,
    skipCache?: boolean,
}

interface StateProps {
    brand: Brand
}

export interface ProductContextProviderProps extends OwnProps, StateProps {
}

const ProductContextProvider: React.FC<PropsWithChildren<ProductContextProviderProps>> = ({productSlug, skipCache, brand, children}) => {
    const [productState, setProductState] = useState(defaultContext)

    const setAndBrandProduct = (productContext: ProductContextConsumerState) => {
        if(productContext.product) {
            // If there are related products, filter them by the current brand
            if(productContext.product.related_products) {
                const filteredRelatedProducts = productContext.product.related_products?.filter( (product: Product) => product.brands?.find(productBrand => productBrand.id === brand.id));
                productContext.product.related_products = filteredRelatedProducts.map(product => mergeBrandOptions(product, brand))
            }
            const brandedProduct = mergeBrandOptions(productContext.product, brand)
            setProductState({
                ...productContext,
                product: brandedProduct
            })
        }
    }

    const setProduct = (product: Product): void => {
        cachedProducts[product.slug!] = {...product};
        setAndBrandProduct({
            ...productState,
            product: product
        })
    }

    useEffect(() => {
        if (!skipCache && cachedProducts[productSlug]) {
            setAndBrandProduct({
                hasLoaded: true,
                notFound: false,
                product: cachedProducts[productSlug],
                setProduct: setProduct,
            });
        } else {
            setAndBrandProduct({
                ...productState,
                hasLoaded: false,
            });
            ProductRequests.getProduct(productSlug).then(product => {
                cachedProducts[productSlug] = product;
                setAndBrandProduct({
                    hasLoaded: true,
                    notFound: false,
                    product: product,
                    setProduct,
                });
            }).catch(() => {
                setAndBrandProduct({
                    ...productState,
                    hasLoaded: true,
                    notFound: true,
                });
            })
        }
    }, [productSlug]);

    return (
        <ProductContext.Provider value={{...productState, setProduct}}>
            <ProductContext.Consumer>
                {context => (context.hasLoaded ? (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                ) : <LoadingScreen/>
                )}
            </ProductContext.Consumer>
        </ProductContext.Provider>
    )
}

export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: ProductContextProvider
});
