import React, {ForwardedRef, forwardRef, HTMLAttributes} from 'react';
import './index.scss';
import './responsive.scss';
import {Link} from "react-router-dom";

export interface ResourceImageProps extends HTMLAttributes<HTMLOrSVGElement> {
    image : string,
    caption? : string,
    alt? : string,
    width?: string,
    height?: string,
    link?: string,
    backgroundColor? : 'white' | 'gray'
}

const ResourceImage = forwardRef<HTMLDivElement, ResourceImageProps>((props, ref) => {

    const {image, caption, alt, width, height, link, backgroundColor, className,...additionalProps} = props

    let wrapperProps = {
        className : 'resource-image' + (backgroundColor ? ' bg-' + backgroundColor : ' bg-gray') + (caption ? '' : ' no-caption ') + (className ? ' ' + className : ''),
        ...additionalProps
    } as any

    const Wrapper = link ? Link : 'div'

    if(link){
        wrapperProps.to = link;
    }

    return (
        <Wrapper {...wrapperProps} ref={ref as ForwardedRef<any>}>
            <div className={'image-wrap'}>
                <img src={image} alt={alt ? alt : ''} width={width} height={height}/>
            </div>
            {caption ? <p>{caption}</p> : ''}
        </Wrapper>
    )
})

export default ResourceImage
