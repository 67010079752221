import React, {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import './index.scss'
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import SignUpContextProvider, {
    SignUpContext,
    SignUpContextStateConsumer,
    SignUpData
} from "../../contexts/signin/SignUpContext";
import ServerAlert from "../../components/ServerAlert";
import {RequestError} from "../../models/request-error";
import AuthRequests from "../../services/requests/AuthRequests";
import {useFormik} from "formik";
import Button from "../../components/Basic/Button";
import AuthenticationPage from "../../components/Authentication/AuthenticationPage";
import InputWrapper from "../../components/FormComponents/InputWrapper";
import Col from "../../components/Basic/Col";
import {BrandRootParams} from "../../models/brand";
import {PAGES, ENVIRONMENTS} from "../../config";


interface SignUpContentProps {
    setValidationError: (error: string) => void,
    signUpContext: SignUpContextStateConsumer,
    onSubmit: (formData: SignUpData) => void,
}

const SignUpContent: React.FC<SignUpContentProps> = ({signUpContext, onSubmit, setValidationError}) => {
    const emailInputRef = useRef<HTMLInputElement>(null)
    const firstNameInputRef = useRef<HTMLInputElement>(null)
    const lastNameInputRef = useRef<HTMLInputElement>(null)
    const passwordInputRef = useRef<HTMLInputElement>(null)
    const SignUpSchema = Yup.object().shape({
        first_name: Yup.string().trim().required('First Name is required'),
        last_name: Yup.string().trim().required('Last Name is required'),
        email: Yup.string().email('Invalid Email').required('Email is required'),
        password: Yup.string().trim().required('Password is required'),
    })

    const inputs = [
        firstNameInputRef?.current,
        lastNameInputRef?.current,
        emailInputRef?.current,
        passwordInputRef?.current,
    ].filter(i => i)as HTMLInputElement[]

    const validateForm = (submission: any) : string|undefined =>  {
        if (submission.errors.email && submission.touched.email) {
            return submission.errors.email
        }
        else if (submission.errors.first_name && submission.touched.first_name) {
            return submission.errors.first_name;
        }
        else if (submission.errors.last_name && submission.touched.last_name) {
            return submission.errors.last_name;
        }
        else if (submission.errors.password && submission.touched.password) {
            return submission.errors.password;
        }
        return undefined
    }

    const form: any = useFormik({
        initialValues: signUpContext.data,
        initialErrors: {first_name: signUpContext.data.first_name},
        validationSchema: SignUpSchema,
        onSubmit: onSubmit,
    });

    useEffect(() => {
        if (!form.isValid) {

            const currentError = validateForm(form);

            setValidationError(currentError ? currentError : '');
        }
    }, [form, form.isSubmitting, form.isValid, setValidationError])


    useEffect(() => {
        if (inputs) {

            const enterPressed = (event: any) => {
                if (event.keyCode === 13 && !form.isSubmitting) {
                    form.handleSubmit();
                }
            }

            inputs.forEach((i: any) => {
                i.removeEventListener('keydown', enterPressed);
                i.addEventListener('keydown', enterPressed)
            })
        }
    },  [form, inputs])

    return (
        <form className={"flex"}>
            <Col flex colWidth={6}>
                <InputWrapper label={''}>
                    <input
                        autoCapitalize={'word'}
                        name='first_name'
                        placeholder={'First Name'}
                        type='text'
                        onChange={form.handleChange}
                        ref={firstNameInputRef}
                    />
                </InputWrapper>
            </Col>
            <Col flex colWidth={6}>
                <InputWrapper label={''}>
                    <input
                        autoCapitalize={'word'}
                        name='last_name'
                        placeholder={'Last Name'}
                        type='text'
                        onChange={form.handleChange}
                        ref={lastNameInputRef}
                    />
                </InputWrapper>
            </Col>
            <Col flex colWidth={12}>
                <InputWrapper label={''} className={'email-box'}>
                    <input
                        name='email'
                        type='email'
                        placeholder={'email@example.com'}
                        autoComplete={'username'}
                        onChange={form.handleChange}
                        ref={emailInputRef}
                    />
                </InputWrapper>
            </Col>
            <Col flex colWidth={12}>
                <InputWrapper label={''} className={'password-box'}>
                    <input
                        name='password'
                        type='password'
                        placeholder={'Password'}
                        autoComplete={'current-password'}
                        onChange={form.handleChange}
                        ref={passwordInputRef}
                    />
                </InputWrapper>
            </Col>
            <Col flex colWidth={12}>
                <Button
                    buttonColor={'main'}
                    className={'submit' + (form && form.isValid ? ' valid' : '')}
                    onClick={() => form.handleSubmit()}>
                    Submit
                </Button>
            </Col>
        </form>
    )
}

const SignUp: React.FC = () => {
    const [submissionState, setSubmissionState] = useState(false)
    const [validationError, setValidationError] = useState('');
    const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);
    const navigate = useNavigate();
    const {brand}  = useParams<BrandRootParams>();

    const onSubmit = (formData: SignUpData, setData: (data: SignUpData) => void) => {
        setData(formData);
        setSubmissionState(true);
        processSignUp(formData).then(() => {
            setSubmissionState(false);
        });
    }

    const processSignUp = async (signUpData: SignUpData) => {
        try {
            await AuthRequests.signUp(signUpData)
            navigate(`/${brand}` + PAGES.AUTHORIZED_LANDING)
        } catch (error) {
            setRequestError(error as RequestError)
            console.log(error)
        }
    }

    return process.env.REACT_APP_ENV === ENVIRONMENTS.development ? (
        <AuthenticationPage slug={'sign-up'} title={"Sign Up"} validationError={validationError} isSubmitting={submissionState}>
            <SignUpContextProvider>
                <SignUpContext.Consumer>
                    {signUpContext => (
                        <React.Fragment>
                            <SignUpContent
                                setValidationError={setValidationError}
                                signUpContext={signUpContext}
                                onSubmit={(data: any) => onSubmit(data, signUpContext.setData)}
                            />
                            {requestError &&
                                <ServerAlert
                                    requestError={requestError}
                                    onCloseAlert={() => setRequestError(undefined)}
                                />
                            }
                        </React.Fragment>
                    )}
                </SignUpContext.Consumer>
            </SignUpContextProvider>
        </AuthenticationPage>
    ) : <Navigate to={PAGES.DEFAULT_LANDING}/>
}

export default SignUp;
