import Feature from "../../models/feature";
import {api} from "../api";
import {staticStringFallbacks} from "../../config/string_constants";

export default class FeatureRequests {
    /**
     * All cached results from any feature requests
     */
    private static cachedResults: {[key: string]: string} = {};

    private static loadComplete = false

    private static page = 1

    /**
     * Gets a feature based on the passed in name
     * @param name
     * @return Feature|undefined
     */
    static async getFeature(name: string): Promise<string> {

        if (FeatureRequests.cachedResults.hasOwnProperty(name))  {
            return FeatureRequests.cachedResults[name];
        }

        // If there are still features left to load, continue loading
        if (!FeatureRequests.loadComplete) {
            const { data } = await api.get('/features', {
                params: {
                    'limit': '100',
                    'page': FeatureRequests.page,
                }
            });

            const featuredResults = data.data as Feature[];

            // Add all features returned to the cached list and backup list
            featuredResults.forEach((feature) => {
                if(feature.value) {
                    FeatureRequests.cachedResults[feature.name] = feature.value
                }
            })

            if (data.current_page === data.last_page) {
                FeatureRequests.loadComplete = true
            }
            else {
                FeatureRequests.page++;
            }
        }

        return FeatureRequests.cachedResults.hasOwnProperty(name) ? FeatureRequests.cachedResults[name] : staticStringFallbacks[name]
    }
}
