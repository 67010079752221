import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {connect} from "../data/connect";
import Brand from "../models/brand";
import {mergeBrandOptions} from "../models/brand-option";
import Post from "../models/post/post";

/**
 * The state interface for our state
 */
export interface FAQsContextState extends BasePaginatedContextState<Post> {}


function createDefaultState(): FAQsContextState {
    return {
        ...defaultBaseContext(),
        loadAll: false,
        limit: 7,
        expands: [
            'brandOptions',
            'brands',
        ],
        filter: {
            'published_at': 'notnull',
            'type': 'faq',
        },
    }
}

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext: FAQsContextState = createDefaultState();

/**
 * The actual context component
 */
export const FAQsContext = React.createContext<FAQsContextState>(createDefaultState());

interface OwnProps extends BasePaginatedContextProviderProps {
}

interface StateProps {
    brand: Brand
}

export interface FAQsContextProviderProps extends OwnProps, StateProps{
}

const FAQsContextProvider: React.FC<PropsWithChildren<FAQsContextProviderProps>> = (({brand, ...rest}) => {
    const [contextState, setContextState] = useState({...persistentContext});
    const [brandedFAQs, setBrandedFAQs] = useState([] as Post[])

    const updateContext = (newState: FAQsContextState) => {
        persistentContext = {...newState}
        setContextState(newState)

        const newBrandedFAQs = [...newState.loadedData].filter(
            (post: Post) => post.brands && post.brands.find(postBrand => postBrand.id === brand.id)
        ).map( product => mergeBrandOptions(product, brand))

        setBrandedFAQs(newBrandedFAQs)
    }

    const context = createCallbacks(setContextState, contextState,'/articles/');

    useEffect(() => {
        if (contextState != persistentContext) {
            updateContext(contextState)
        }
    }, [contextState, brand])


    useEffect(() => {
        if (persistentContext && persistentContext.initialLoadComplete) {
            updateContext(persistentContext);
        } else {
            contextState.initiated = true;
            const newContext = prepareContextState(setContextState, contextState, '/articles/');

            newContext.initialLoadComplete = false;
            updateContext({...newContext});
            newContext.refreshData(false)
        }
    }, []);


    return (
        <FAQsContext.Provider value={{...context, loadedData: brandedFAQs}}>
            <FAQsContext.Consumer>
                {(context) => rest.children}
            </FAQsContext.Consumer>
        </FAQsContext.Provider>
    )
});

export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: FAQsContextProvider
});
