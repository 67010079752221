import React, {useEffect, useState} from 'react';
import './index.scss';
import Product from "../../../models/product";
import Order, {findOrderProductInOrder, generateEmptyOrder, updateProductQuantity} from "../../../models/order/order";
import Icon from "../../Basic/Icon";
import ConfirmationPopup from "../../ConfirmationPopup";
import CurrentOrderContextProvider, {CurrentOrderContext} from "../../../contexts/CurrentOrderContext";
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";
import OrderProduct from "../../../models/order/order-product";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";

interface QuantitySelectorContentProps extends QuantitySelectorProps {
    order: Order
    updateCurrentOrder: (order: Order, orderProductId: number, totalPrice: number, orderProduct?: OrderProduct) => void
}

const QuantitySelectorContent: React.FC<QuantitySelectorContentProps> = (props) => {
    const {order, updateCurrentOrder, product, initialQuantity, updateOrder, displayOnly, onQuantityUpdate} = props
    const [quantity, setQuantity] = useState(initialQuantity)
    const [willRemove, setWillRemove] = useState(false)
    const [lastUpdatedQuantity, setLastUpdatedQuantity] = useState(initialQuantity)

    useEffect(() => {
        if (initialQuantity !== quantity) {
            setQuantity(initialQuantity)
            setLastUpdatedQuantity(initialQuantity)
        }
    }, [initialQuantity]);

    // Update order, set last order updated
    const updateOrderQuantity = (newQuantity: number) => {

        if (onQuantityUpdate) {
            onQuantityUpdate(newQuantity)
        }

        if(updateOrder) {
            const updatedOrder = order ? {...order} : generateEmptyOrder()
            // Add item to cart
            const updatedObj = updateProductQuantity( updatedOrder, product, newQuantity)
            if(updatedObj.orderProduct){
                const args: [Order, number, number, OrderProduct?] = [updatedObj.order, updatedObj.orderProduct.id!, updatedObj.order.total_price]
                if(newQuantity > 0) {
                    args.push(updatedObj.orderProduct)
                }
                updateCurrentOrder(...args)
            }
            setLastUpdatedQuantity(newQuantity)
        }
    }
    const normalizeQuantity = (eventTargetValue: string) => {
        return eventTargetValue ? parseInt(eventTargetValue) : 0
    }

    // Change updates component, but does not update order
    const change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = event.target.value.replace(/\D/g,'');
        setQuantity(normalizeQuantity(newQuantity ? newQuantity : '0'))
    }

    // Increment & update order
    const increment = (d: 1|-1) => {
        let newQuantity = (quantity ? quantity : 0) + d

        if (newQuantity <= 0) {
            if (updateOrder) {
                // product removal warning
                setWillRemove(true)
            }
        }
        else {
            setQuantity(newQuantity)
            updateOrderQuantity(newQuantity)
        }

    }

    // Update order based on last quantity set via change
    const blur = (event: React.FocusEvent<HTMLInputElement>) => {
        const newQuantity = normalizeQuantity(event.target.value)

        // if the product is already in the order, update the quantity
        if (updateOrder && lastUpdatedQuantity !== newQuantity) {
            if (newQuantity === 0) {
                setWillRemove(true)
            }
            else {
                updateOrderQuantity(newQuantity)
            }
        }
    }

    // update quantity on keydown
    const keyDown = ((event : any) => {
        if(event.keyCode === 13){
            event.target.blur()
            return false
        }
    })

    const removeFromOrder = () => {
        updateOrderQuantity(0)
        setWillRemove(false)
    }

    const removalCanceled = () => setWillRemove(false)

    return (
        <div className={'quantity-selector' + (displayOnly ? ' display-only' : '')}>
            {displayOnly ? (
                <p>{quantity}</p>
            ) : (
                <React.Fragment>
                    <Icon iconName={updateOrder && quantity === 1 ? "delete" : "minus"} className={!updateOrder && quantity === 1 ? 'disabled' : ''} onClick={() => increment(-1)}/>
                    <input type={'tel'} value={quantity} min={0} onChange={change} onBlur={blur} onKeyDown={keyDown}/>
                    <Icon iconName={"plus"} onClick={() => increment(+1)}/>
                    <ConfirmationPopup show={willRemove} onConfirm={removeFromOrder} onCancel={removalCanceled}>
                        <StaticFeatureContextProvider featureNames={['remove-from-cart-warning']} featuresRequestKey={'remove-from-cart-warning'}>
                            <StaticFeatureContext.Consumer>
                                {context => {
                                    const removeFromCartWarning = context.features['remove-from-cart-warning']
                                    return removeFromCartWarning ? <p className={'bold'}>{removeFromCartWarning.replace('__PRODUCT_NAME__', product.title)}</p> : ''
                                }}
                            </StaticFeatureContext.Consumer>
                        </StaticFeatureContextProvider>
                    </ConfirmationPopup>
                </React.Fragment>
            )}
        </div>
    )
}

interface QuantitySelectorProps {
    product: Product,
    initialQuantity: number,
    updateOrder?: boolean,
    displayOnly?: boolean,
    onQuantityUpdate?: (amount: number) => {} | void,
}

const QuantitySelector: React.FC<QuantitySelectorProps> = (props) => {
    return (
        <MeContextProvider>
            <MeContext.Consumer>
                {contextMe => (
                    <CurrentOrderContextProvider organization_id={contextMe.me.organization_id}>
                        <CurrentOrderContext.Consumer>
                            {context => (
                                <QuantitySelectorContent order={context.order} updateCurrentOrder={context.updateCurrentOrder} {...props}/>
                            )}
                        </CurrentOrderContext.Consumer>
                    </CurrentOrderContextProvider>
                )}
            </MeContext.Consumer>
        </MeContextProvider>
    )
}

export default QuantitySelector
