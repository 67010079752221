import React, {HTMLAttributes} from "react";
import './index.scss';
import './responsive.scss';

interface FieldGroupProps extends HTMLAttributes<HTMLOrSVGElement>{
    title?: string,
}

const FieldGroup: React.FC<FieldGroupProps> = ({title, className, children}) => {
    const classes = className ? className + ' form-field-group' : 'form-field-group'
    return (
        <div className={classes}>
            {title ? <h5>{title}</h5> : ''}
            {children}
        </div>
    )
}

export default FieldGroup
