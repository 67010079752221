export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegExp = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/
export const zipRegExp = /(0[1-9]|[1-9][0-9])[0-9]{3}/
export const stateRegExp = /^(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|U[MT]|V[AIT]|W[AIVY])+$/
export const urlRegExp = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

export function validateRegexMatch(regex: RegExp, value: string, caseSensetive?: boolean): boolean {
    const testValue = String(value)
    return regex.test(caseSensetive ? testValue : testValue.toLowerCase());
}

export function formatPhoneNumber(phoneNumber: string) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
}

export function formatPrice(price: number) {
    const formattedPrice = price.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 2});
    return `<span class="amount"><sup>${formattedPrice.slice(0, 1)}</sup><span class="dollars">${formattedPrice.slice(1, -3)}</span><sup>${formattedPrice.slice(-3)}</sup></span>`
}
