import React, {PropsWithChildren} from 'react';
import './index.scss';
import './responsive.scss';
import Slider from "../Basic/Slider";

interface ResourceSliderProps {
    id: string
    className?: string
}

const ResourceSlider: React.FC<PropsWithChildren<ResourceSliderProps>> = ({id, className, children}) => (
    <Slider id={id} mobileOnly className={'resource-slider' + (className ? ' ' + className : '')}>
        {children}
    </Slider>
)

export default ResourceSlider
