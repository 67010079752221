import HTMLComponent from "../components/Basic/HTMLComponent";
import React from "react";
import CategoryContextProvider, {CategoryContext} from "../contexts/CategoryContext";
import CategoryList from "../components/PageSections/CategoryList";
import CategoryOptionsList from "../components/CategoryOptionsList";
import ShowroomPlanningContent from "../components/ShowroomPlanningContent";

/**
 * Function that watches the size of a window and return the width
 *
 * @param content the current shortcode
 *
 * @return array the key/values from the shortcode
 */

export function doShortcodes (content: string): React.JSX.Element[] {
    let postContent: React.JSX.Element[] = []
    const shortcodes = content.match(/{{.+?}}/g)
    // If there are shortcodes, process them
    if (shortcodes?.length) {
        const splitContent = content.split(/{{.+?}}/g)

        splitContent.forEach((partialContent, index) => {
            postContent.push(<HTMLComponent key={'split-' + index} content={partialContent}/>)

            // After every content section except the last one, process the shortcode that caused that split
            if (index+1 !== splitContent.length) {
                const currentShortcode = shortcodes[index]
                const shortcodeData = getShortcodeData(currentShortcode)
                if (shortcodeData.hasOwnProperty('product-category')) {
                    postContent.push(
                        <CategoryContextProvider key={'shortcode-' + index} categorySlug={shortcodeData['product-category'] as string}>
                            <CategoryContext.Consumer>
                                {(context) => {
                                    return context.category ?
                                        <CategoryOptionsList category={context.category} linkRoot={''} setPageTitle={() => {}} includeBody={false}/> : null
                                }}
                            </CategoryContext.Consumer>
                        </CategoryContextProvider>
                    )
                }
                else if (shortcodeData.hasOwnProperty('hr')) {
                    postContent.push(<hr/>)
                }
                else if (shortcodeData.hasOwnProperty('showroom-planning')) {
                    postContent.push(<ShowroomPlanningContent key={'shortcode-' + index}/>)
                }
            }
        })
    }
    else {
        postContent.push(<HTMLComponent key={'root'} className={'article-content'} content={content} />)
    }

    return postContent
}

/**
 * Retrieves key-value pairs from shortcode
 *
 * @param shortCode the current shortcode
 *
 * @return object the key/values from the shortcode
 */
function getShortcodeData(shortCode: string): {[key: string]: string|boolean} {
    const shortcodePairs = shortCode.replace(/[{}]/g, '').split(' '); // Split by ' ' to get individual pairs
    const shortcodeData: {[key: string]: string|boolean} = {};

    for (const pair of shortcodePairs) {
        if (pair.indexOf(':') > -1) {
            const [key, value] = pair.split(':'); // Split each pair by '='
            shortcodeData[key] = value;
        }
        else {
            shortcodeData[pair] = true
        }
    }

    return shortcodeData
}
