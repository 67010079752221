import React, {HTMLAttributes, PropsWithChildren} from 'react';
import './index.scss';
import AuthenticationNavigation from "../AuthenticationNavigation";
import Section from "../../Basic/Section";
import PageWrapper from "../../Basic/PageWrapper";


interface Props extends HTMLAttributes<HTMLOrSVGElement> {
	slug: string,
	title: string,
	validationError: string,
	isSubmitting: boolean
}

const AuthenticationPage: React.FC<PropsWithChildren<Props>> = ({slug, title, validationError, isSubmitting, className, children, ...props}) => (
	<PageWrapper id={slug + '-page'} className={'authentication-page' + ( className ? ' ' + className : '')} title={title} {...props}>
		<div className={'background'}></div>
		<AuthenticationNavigation slot={"nav"} />
		<Section id={slug + "-form"}>
			<h1>{title}</h1>
			{children}
			{validationError ? (
				<p className={'error'}>{validationError}</p>
			) : null}
			{isSubmitting ? (
				// TODO Add Loader while submit is happening
				<div>Validating</div>
			): null}
		</Section>
	</PageWrapper>
)

export default AuthenticationPage
