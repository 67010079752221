import BaseModel from '../base-model';
import Product, {generateEmptyProduct} from "../product";

/**
 * The interface for what our Orders will look like
 */
export default interface OrderProduct extends BaseModel {

    /**
     * The product added to the order
     */
    product: Product;

    /**
     * The product added to the order
     */
    quantity: number;

    /**
     * The product added to the order
     */
    price: number;
}

/**
 * Outputs an empty Product object
 */
export function generateEmptyOrderProduct(): OrderProduct {
    return {
        product: generateEmptyProduct(),
        quantity: 0,
        price: 0
    }
}

/**
 * Outputs an array of formatted orderProducts that match the database structure
 */
export function formatOrderProducts(orderProducts: OrderProduct[]){
    return  orderProducts.map(orderProduct => {
        return formatSingleOrderProduct(orderProduct)
    })
}

/**
 * Outputs a formatted single orderProduct that matches the database structure
 */
export function formatSingleOrderProduct(orderProduct: OrderProduct) {
    return {
        free: orderProduct.product.cost_dealer ? 0 : 1,
        price: orderProduct.price.toFixed(2),
        quantity: orderProduct.quantity,
        product_id: orderProduct.product.id
    }
}
