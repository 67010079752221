import React, {ForwardedRef, forwardRef, HTMLAttributes} from 'react';
import './index.scss';


export interface ContentProps extends HTMLAttributes<HTMLOrSVGElement> {
	tag?: keyof HTMLElementTagNameMap;
}

const Content: React.FC<ContentProps> = forwardRef<HTMLOrSVGElement, ContentProps>((props, ref) => {
	// Get required props out, save rest for adding to the element
	const {tag: Wrapper = "div", className, ...additionalProps} = props

	// @ts-ignore – The ref here gets a little hairy because our tag type is variable
	return (<Wrapper ref={ref as ForwardedRef<any>}  className={'content' + (className ? ' ' + className : '')} {...additionalProps} />)
})
export default Content
