
/**
 *
 * @purpose Checks if the element has a class
 *
 * @param element - the element to search
 * @param className - the class you are searching for
 *
 * @return boolean true/false for if the element has the class or not
 *
 **/
export function ec_has_class( element: HTMLElement|Element, className: string ) {

	// If element exists
	if ( element != null ) {
		// return the index of the className in the element className string
		return ( ( ' ' + element.className + ' ' ).replace( /[\n\t]/g, ' ' ).indexOf( ' ' + className + ' ' ) > -1 );
	}
	else {
		// Throwing error on invalid element
		console.warn( 'Passed invalid element (' + JSON.stringify( element ) + ') to ec_has_class' );
	}
}

/**
 *
 * @purpose Adds a class to an element
 *
 * @param elements - the element(s) to add a class to
 * @param className - the class name to be added
 *
 * @return void
 *
 **/
export function ec_add_class( elements: HTMLElement|HTMLElement[], className: string ) {

	// If the element exists
	if (elements != null) {
		if (!(elements instanceof Array) && !(elements instanceof HTMLCollection)) {
			elements = [elements];
		}

		for (let i = 0; i < elements.length; i++) {
			let element = elements[i];

			// If the classList is defined
			if (element.classList !== undefined) {

				// Add the class
				element.classList.add(className);
			}
			// Else if the element doesn't have the class
			else if (!ec_has_class( element, className )) {
				// Directly append the class name
				element.className += ' ' + className;
			}
		}
	}
	else {

		// Throwing error on invalid element
		console.warn( 'Passed invalid element(s) (' + JSON.stringify( elements ) + ') to ec_add_class' );
	}
}

/**
 *
 * @purpose Removes a class from an element
 *
 * @param elements HTMLNodeList the element(s) to remove a class from
 * @param className string the class name to be removed
 *
 * @return void
 *
 **/
export function ec_remove_class( elements: HTMLElement|HTMLElement[], className: string ) {
	// If the element exists
	if ( elements != null ) {
		if( !( elements instanceof Array ) && !( elements instanceof HTMLCollection ) ) {
			elements = [elements];
		}

		for( let i = 0; i < elements.length; i++ ) {
			let element = elements[i];

			// If the classList is defined
			if ( element.classList !== undefined ) {

				// Remove the class
				element.classList.remove( className );
			}
			else if ( ec_has_class( element, className ) ) {

				// Create a reg expression to find the class name
				var reg = new RegExp( '(\\s|^)' + className + '(\\s|$)' );

				// Replace the class name with an empty string
				element.className = element.className.replace( reg, ' ' );
			}
		}
	}
	else {

		// Throwing error on invalid element
		console.warn( 'Passed invalid element (' + JSON.stringify( elements ) + ') to ec_remove_class' );
	}
}
