import React, {ForwardedRef, forwardRef, HTMLAttributes} from 'react';
import './index.scss';


interface Props extends HTMLAttributes<HTMLOrSVGElement> {
	tag?: keyof HTMLElementTagNameMap;
	colWidth?: number;
	no_margin?: boolean;
	no_gutter?: boolean;
	flex?: boolean;
}

const Col = forwardRef<HTMLOrSVGElement, Props>((props, ref) => {
	// Get Props out of prop object
	const {tag: Wrapper = "div", colWidth, no_margin, no_gutter, flex, className, ...additionalProps} = props;

	const constrainedWidth = colWidth ? Math.ceil(colWidth > 11 ? 12 : ( colWidth < 1 ? 1 : colWidth ) ) : 12
	const classes = (flex ? 'flex-' : '') + `col-${constrainedWidth}` + (
		no_margin ? ' no-margin' : ''
	) + (
		no_gutter ? ' no-gutter' : ''
	) + (
		className ? ' ' + className : ''
	)

	// @ts-ignore – The ref here gets a little hairy because our tag type is variable
	return ( <Wrapper ref={ref as ForwardedRef<any>} className={classes} {...additionalProps} /> )
})

export default Col
