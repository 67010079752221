import User, {getUserOrganization} from '../../../models/user/user';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import './index.scss';
import './responsive.scss';
import Col from "../../Basic/Col";
import Button from "../../Basic/Button";
import ContactContent from "./contact";
import AddressContent from "./address";
import InputWrapper from "../../FormComponents/InputWrapper";
import FormFieldGroup from "../../FormComponents/FormFieldGroup";
import Checkbox from "../../FormComponents/Checkbox";
import OrderRequests from "../../../services/requests/OrderRequests";
import LoadingIndicator from "../../Loaders/LoadingIndicator";
import CurrentOrderContextProvider, {CurrentOrderContext} from "../../../contexts/CurrentOrderContext";
import {splitFirstLastName} from "../../../models/organization/organization";
import {StringKeyObject} from "../../../util/form";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";

interface CheckoutData {
    order_first_name: string,
    order_last_name: string,
    order_name: string,
    order_email: string,
    order_phone: string,
    shipping_first_name: string,
    shipping_last_name: string,
    shipping_name: string,
    shipping_email: string,
    shipping_phone: string,
    shipping_company: string,
    shipping_address_1: string,
    shipping_address_2: string,
    shipping_city: string,
    shipping_country: string,
    shipping_state: string,
    shipping_zip: string,
    billing_first_name: string,
    billing_last_name: string,
    billing_name: string,
    billing_email: string,
    billing_phone: string,
    billing_company: string,
    billing_address_1: string,
    billing_address_2: string,
    billing_city: string,
    billing_country: string,
    billing_state: string,
    billing_zip: string,
    info: string,
    shipping_address_same_as_billing: string
}

const initialCheckoutData : any = {
    order_first_name: '',
    order_last_name: '',
    order_name: '',
    order_email: '',
    order_phone: '',
    shipping_first_name: '',
    shipping_last_name: '',
    shipping_name: '',
    shipping_email: '',
    shipping_phone: '',
    shipping_company: '',
    shipping_address_1: '',
    shipping_address_2: '',
    shipping_city: '',
    shipping_country: '',
    shipping_state: '',
    shipping_zip: '',
    billing_first_name: '',
    billing_last_name: '',
    billing_name: '',
    billing_email: '',
    billing_phone: '',
    billing_company: '',
    billing_address_1: '',
    billing_address_2: '',
    billing_city: '',
    billing_country: '',
    billing_state: '',
    billing_zip: '',
    info: '',
    shipping_address_same_as_billing: 'true'
}

/*** CheckoutContentForm Component **/

interface CheckoutFormContentProps {
    setValidationError: (error: string) => void,
    submissionState: boolean,
    checkoutFormContext: CheckoutData,
    onSubmit: (data: any) => void,
    features: {[key:string]: {}}
}

const CheckoutFormContent: React.FC<CheckoutFormContentProps> = ({setValidationError, submissionState, checkoutFormContext, onSubmit, features}) => {
    // States to determine when to show the billing section and how to adjust the form schema based on the visibility of the billing section
    const [showBilling, setShowBilling] = useState(false)
    const [CheckoutSchema, setCheckoutSchema] = useState(Yup.object())

    const contactFeatures = features['contactFeatures'] as StringKeyObject
    const addressFeatures = features['addressFeatures'] as StringKeyObject
    const formLabelFeatures = features['formLabelFeatures'] as StringKeyObject

    // Build the form
    const form = useFormik ( {
        initialValues: checkoutFormContext,
        initialErrors: {order_first_name: checkoutFormContext.order_first_name},
        validationSchema: CheckoutSchema,
        onSubmit: ((values, { resetForm }) => {
            onSubmit(values)
            resetForm()
        })
    })

    // Build the form sections
    const order_contact_fields = ContactContent('order', contactFeatures,  form)
    const shipping_contact_fields = ContactContent('shipping', contactFeatures, form)
    const shipping_address_fields = AddressContent('shipping', addressFeatures, form)
    const billing_contact_fields = ContactContent('billing', contactFeatures, form)
    const billing_address_fields = AddressContent('billing', addressFeatures, form)

    // Validation function to determine if there are any errors per section
    const validateForm = (submission: any) : string|undefined => {
        let validateError = undefined
        if(order_contact_fields.validate(submission)){
            validateError = order_contact_fields.validate(submission)
        } else if(shipping_contact_fields.validate(submission)){
            validateError = shipping_contact_fields.validate(submission)
        } else if(shipping_address_fields.validate(submission)) {
            validateError = shipping_address_fields.validate(submission)
        } else if(billing_contact_fields.validate(submission)) {
            validateError = billing_contact_fields.validate(submission)
        } else if(billing_address_fields.validate(submission)) {
            validateError = billing_address_fields.validate(submission)
        }
        return validateError
    }

    // Function to validate the form anytime it is submitted and set the error if there is one
    useEffect(() => {
        if(!form.isValid) {
            const currentError = validateForm(form)
            setValidationError(currentError ? currentError : '')
        }
    }, [form, form.isSubmitting, form.isValid, setValidationError])

    // Function to set the Yup object depending on whether the billing section is visible
    useEffect(() => {
        let checkoutYupObject = {...order_contact_fields.yup, ...shipping_contact_fields.yup, ...shipping_address_fields.yup,}
        if(showBilling){
            checkoutYupObject = {...checkoutYupObject, ...billing_contact_fields.yup, ...billing_address_fields.yup}
        }
        setCheckoutSchema(Yup.object().shape({...checkoutYupObject}))
    }, [showBilling, setShowBilling])

    // Handles submitting the form anytime the 'enter' key is pressed while in the form
    const onFormKeyDown = ((event : any) => {
        if(event.keyCode === 13 && !form.isSubmitting){
            form.handleSubmit();
        }
    })

    // Function to set any field based on name and value
    const onEventChange = ((name : string, value : string) => {
        form.setFieldValue(name, value)
    })

    // Wrapper functions for shipping and billing sections
    const Wrapper = showBilling ? Col : 'div'
    const wrapperProps = (Wrapper === Col) ? {colWidth: 6} : {}

    // Build the HTML form
    return (
        <form className={'flex form'} onSubmit={form.handleSubmit} id={'checkout-form'}>
            <Col flex colWidth={12} className={'form-contents ' + (showBilling ? 'billing-active' : '')}>
                <Col flex colWidth={(showBilling ? 12 : 6)} className={'order-detail'}>
                    <FormFieldGroup title={formLabelFeatures['individual-label']} className={'order-individual'}>
                        {order_contact_fields.node( onEventChange, onFormKeyDown)}
                    </FormFieldGroup>
                    <FormFieldGroup title={'Additional Information:'} className={'order-notes'}>
                        <InputWrapper label={''}>
                            <textarea
                                name={'info'}
                                value={form.values.info}
                                onChange={form.handleChange}
                                rows={8}
                                onKeyDown={onFormKeyDown}
                            />
                        </InputWrapper>
                    </FormFieldGroup>
                </Col>
                <Col flex colWidth={(showBilling ? 12 : 6)} className={'shipping-billing'}>
                    <Wrapper className={'shipping-info'} {...wrapperProps}>
                        {showBilling ? (
                            <React.Fragment>
                                <FormFieldGroup title={formLabelFeatures['shipping-contact']}>
                                    {shipping_contact_fields.node(onEventChange, onFormKeyDown)}
                                </FormFieldGroup>
                                <FormFieldGroup title={formLabelFeatures['shipping-address']}>
                                    {shipping_address_fields.node(onEventChange, onFormKeyDown)}
                                </FormFieldGroup>
                            </React.Fragment>
                        ) : (
                        <FormFieldGroup title={'Shipping:'}>
                            {shipping_contact_fields.node(onEventChange, onFormKeyDown)}
                            {shipping_address_fields.node(onEventChange, onFormKeyDown)}
                        </FormFieldGroup>
                    )}
                    </Wrapper>
                    <Wrapper className={'billing-info'} {...wrapperProps}>
                        <FormFieldGroup title={'Billing:'}>
                            <Checkbox
                                label={'Billing info the same as shipping'}
                                name={'shipping_address_same_as_billing'}
                                value={form.values.shipping_address_same_as_billing}
                                show={showBilling}
                                setShow={setShowBilling}
                                onChangeEvent={onEventChange} >
                                    <React.Fragment>
                                        {billing_contact_fields.node(onEventChange, onFormKeyDown)}
                                        {billing_address_fields.node(onEventChange, onFormKeyDown)}
                                    </React.Fragment>
                            </Checkbox>
                        </FormFieldGroup>
                    </Wrapper>
                </Col>
            </Col>
            <Col flex colWidth={12}>
                {!submissionState ? (
                    <Button
                        buttonColor={'main'}
                        type={'submit'}>
                        Order Now
                    </Button>
                ) : <LoadingIndicator />}
            </Col>
        </form>
    )
}


/*** Checkout Form Component **/

interface CheckoutFormProps {
    loggedInUser?: User
    setSubmissionStatus: (status: boolean) => void
}


const CheckoutForm: React.FC<CheckoutFormProps> = ({loggedInUser, setSubmissionStatus}) => {
    const [submissionState, setSubmissionState] = useState(false)
    const [validationError, setValidationError] = useState('');
    const initialFormData = {...initialCheckoutData}

    const contactFeatures = ['first_name', 'last_name', 'email', 'phone', 'fax',]
    const addressFeatures = ['company', 'address', 'address_1', 'address_2', 'city', 'state', 'country', 'zip',]
    const formLabelFeatures = ['individual-label', 'shipping-contact', 'shipping-address', 'billing-contact', 'billing-address', 'additional-info']
    const allFeatures = contactFeatures.concat(addressFeatures, formLabelFeatures)

    // Submit function to process the form data that was returned
    const onSubmit = (orderId: number, formData: CheckoutData) => {
        if (formData) {
            setSubmissionState(true)
            processCheckoutForm(orderId, formData).then(() => {
                setSubmissionState(false)
            })
        }
    }

    // Function to process the form data
    const processCheckoutForm = async (orderId: number, checkoutData: CheckoutData) => {
        try{
            setSubmissionStatus(true)

            // Destructor the checkoutData to remove columns that are not in the database
            const {
                order_first_name,
                order_last_name,
                shipping_first_name,
                shipping_last_name,
                billing_first_name,
                billing_last_name,
                ...sanitizedData
            } = checkoutData

            await OrderRequests.updateOrder(orderId, {
                ...sanitizedData,
                submit: 1,
                shipping_address_same_as_billing: checkoutData.shipping_address_same_as_billing === 'true',
            })
        }
        catch (error: any){
            setValidationError(error)
        }
    }

    if (loggedInUser) {
        const organization = ({...getUserOrganization(loggedInUser)} ?? {}) as { [key: string]: string }

        // Generic Mapping of like keys
        for (let key in initialFormData) {
            if (initialFormData.hasOwnProperty(key) && organization.hasOwnProperty(key) && organization[key] !== null) {
                initialFormData[key] = organization[key]
            }
        }

        // Mapping unique keys
        initialFormData.shipping_company = organization.name
        initialFormData.billing_company = organization.name
        initialFormData.order_first_name = loggedInUser.first_name
        initialFormData.order_last_name = loggedInUser.last_name
        initialFormData.order_email = loggedInUser.email

        // Mapping complex keys
        const shipping_name = splitFirstLastName(organization?.shipping_name)
        const billing_name = splitFirstLastName(organization?.billing_name)

        initialFormData.shipping_first_name = shipping_name.first
        initialFormData.shipping_last_name = shipping_name.last
        initialFormData.billing_first_name = billing_name.first
        initialFormData.billing_last_name = billing_name.last
    }

    const buildFormFeatures = (formFeatures: StringKeyObject, featureNames: string[]) => {
        let features = {} as StringKeyObject
        featureNames.forEach(name => {
            if(formFeatures.hasOwnProperty(name)){
                features[name] = formFeatures[name]
            }
        })
        return features
    }


    // Build the form
    return (
        <CurrentOrderContextProvider>
            <CurrentOrderContext.Consumer>
                {context => {
                    const orderId = context.order.id
                    return (
                        <StaticFeatureContextProvider featureNames={allFeatures} featuresRequestKey={allFeatures.join()}>
                            <StaticFeatureContext.Consumer>
                                {context => {
                                    const contactFieldFeatures = buildFormFeatures(context.features, contactFeatures)
                                    const addressFieldFeatures = buildFormFeatures(context.features, addressFeatures)
                                    const formLabelsFeatures = buildFormFeatures(context.features, formLabelFeatures)

                                    return (
                                        <CheckoutFormContent
                                            setValidationError={setValidationError}
                                            submissionState={submissionState}
                                            checkoutFormContext={initialFormData}
                                            onSubmit={(data: any) => onSubmit(orderId!, data)}
                                            features={{
                                                contactFeatures: contactFieldFeatures,
                                                addressFeatures: addressFieldFeatures,
                                                formLabelFeatures: formLabelsFeatures
                                            }}
                                        />
                                    )
                                }}
                            </StaticFeatureContext.Consumer>
                        </StaticFeatureContextProvider>
                    )
                }}
            </CurrentOrderContext.Consumer>
        </CurrentOrderContextProvider>
    )
}

export default CheckoutForm
