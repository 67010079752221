import React from 'react';
import './index.scss';
import './responsive.scss';

interface CostProps {
    price : number,
    unit : string,
    className?: string,
}

const Cost: React.FC<CostProps> = ({price, unit, className}) => {
    let cents, dollars= 0

    if (price) {
        dollars = Math.floor(price)
        cents = (Math.round((price - dollars) * 100))

        if(cents < 10){
            cents = cents.toString().padStart(2, '0');
        }
    }

    return (
        <span className={className}>
            <span className={'amount'}>
                <sup>{unit}</sup><span className={"dollars"}>{Intl.NumberFormat('en-US').format(dollars)}</span><sup>.{cents}</sup>
            </span>
            <span className={'us-dollar'}>USD</span>
        </span>
    )
}

interface PriceProps {
    price : number,
    sale? : number,
    unit? : string,
    free?: boolean
}
const Price: React.FC<PriceProps> = ({price, sale, unit = '$', free = true}) => {
    const classes = 'price' + (price <= 0 ? ' free' : '')

    return (
        <p className={classes}>
            {(price <= 0) ? (
                free ? 'Free' : null
            ) : (
                sale ? (
                    <span className={'product-sale'}>
                        <Cost price={sale} unit={unit} className={'sale'} />
                        <Cost price={price} unit={unit} className={'original'} />
                    </span>
                ) : (
                    <Cost price={price} unit={unit} />
                )
            )}
        </p>
    )
}

export default Price
