import React, {PropsWithChildren} from 'react';
import './index.scss';
import ModalOverlay from "../Basic/ModalOverlay";
import Button from "../Basic/Button";
import {BrandColorNames} from "../../models/brand";

interface ConfirmationPopupProps {
    show: boolean
    onCancel: () => void
    onConfirm: () => void
    onDismiss?: () => void
    confirmColor?: BrandColorNames
    cancelColor?: BrandColorNames
    confirmText?: string
    cancelText?: string
    returnToTopOnClose?: boolean
}

const ConfirmationPopup: React.FC<PropsWithChildren<ConfirmationPopupProps>> = ({show, onCancel, onConfirm, onDismiss, confirmColor, cancelColor, confirmText, cancelText, returnToTopOnClose = false, children}) => {

        const popupCanceled = () => {
            onCancel()
        }

        return (
            <ModalOverlay align={"center"} onClose={onDismiss ? onDismiss : popupCanceled} open={show} className={'confirmation-popup'} returnToTopOnClose={returnToTopOnClose} verticalMargin>
                {children}
                <div className={'confirmation-options'}>
                    <Button buttonColor={confirmColor? confirmColor : "main"} onClick={() => onConfirm()}>{confirmText ? confirmText : 'Confirm'}</Button>
                    <Button buttonColor={cancelColor ? cancelColor : "dk-gray"} onClick={popupCanceled}>{cancelText ? cancelText : 'cancel'}</Button>
                </div>
            </ModalOverlay>
        )
}

export default ConfirmationPopup
