import React, {PropsWithChildren, useEffect, useState} from 'react';
import Order, {generateEmptyOrder} from '../models/order/order';
import OrderRequests from '../services/requests/OrderRequests';
import LoadingIndicator from "../components/Loaders/LoadingIndicator";
import {mergeBrandOptions} from "../models/brand-option";
import Brand from "../models/brand";
import {connect} from "../data/connect";
import OrderProduct from "../models/order/order-product";

let cachedOrders = [] as Order[];

/**
 * The structure of the consumer
 */
export interface OrderContextConsumerState {
    hasLoaded: boolean,
    notFound: boolean,
    order: Order,
    setOrder: (order: Order) => void,
}

let defaultContext: OrderContextConsumerState = {
    hasLoaded: false,
    notFound: false,
    order: generateEmptyOrder(),
    setOrder: (order: Order) => {}
};

export const OrderContext = React.createContext<OrderContextConsumerState>(defaultContext);

interface OwnProps {
    orderId: number,
    skipCache?: boolean,
}

interface StateProps{
    brand: Brand
}

export interface OrderContextProviderProps extends OwnProps, StateProps {
}

const OrderContextProvider: React.FC<PropsWithChildren<OrderContextProviderProps>> = ({orderId, skipCache, brand, children}) => {
    const [orderState, setOrderState] = useState(defaultContext);

    const setAndBrandOrder = (orderContext: OrderContextConsumerState) => {
        const brandedOrderProducts = [] as OrderProduct[]

        orderContext.order.order_products.forEach(product => {
            const brandedProduct = mergeBrandOptions(product.product, brand)
            brandedOrderProducts.push({...product, product: brandedProduct})
        })

        setOrderState({
            ...orderContext,
            order: {
                ...orderContext.order,
                order_products: brandedOrderProducts
            }
        })
    }

    const setOrder = (order: Order): void => {
        cachedOrders[order.id!] = {...order};
        setAndBrandOrder({
            ...orderState,
            order: order,
        })
    }

    useEffect(() => {
        if (!skipCache && cachedOrders[orderId]) {
            setAndBrandOrder({
                hasLoaded: true,
                notFound: false,
                order: cachedOrders[orderId],
                setOrder: setOrder,
            });
        } else {
            setAndBrandOrder({
                ...orderState,
                hasLoaded: false,
            });
            OrderRequests.getOrder(orderId).then(order => {
                cachedOrders[orderId] = order;
                setAndBrandOrder({
                    hasLoaded: true,
                    notFound: false,
                    order: order,
                    setOrder,
                });
            }).catch(() => {
                setAndBrandOrder({
                    ...orderState,
                    hasLoaded: true,
                    notFound: true,
                });
            })
        }
    }, [orderId]);

    return (
        <OrderContext.Provider value={{...orderState, setOrder}}>
            <OrderContext.Consumer>
                {context => (context.hasLoaded ?
                    (!context.notFound ? children :
                        <div className={'order-not-found'}>
                            Not Found
                        </div>
                    ) : <LoadingIndicator/>
                )}
            </OrderContext.Consumer>
        </OrderContext.Provider>
    )
}

export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: OrderContextProvider
});