import React, {forwardRef, HTMLAttributes} from 'react';
import './index.scss';
import {Link, useParams} from "react-router-dom";
import Icon from "../Basic/Icon";
import {BrandRootParams} from "../../models/brand";
import PageLink from "../../models/link";
import Section from "../Basic/Section";

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
    id: string
    breadcrumbs : PageLink[];
}

const Breadcrumbs = forwardRef<HTMLDivElement, Props>((props, ref) => {
    // Get props out that we need explicitly
    const {id, breadcrumbs, className, ...additionalProps} = props

    const {brand} = useParams<BrandRootParams>();
    const brandHome = `/${brand}`;
    const classes = 'breadcrumb';

    // Loop through breadcrumbs attribute to form output array
    const breadcrumbsContent = breadcrumbs.map( (breadcrumb, index) => {
        const navArrow = breadcrumbs.length - 1 !== index ? <span>{'>'}</span> : ''

        return breadcrumb.link ? (
            <React.Fragment key={index}>
                <Link to={breadcrumb.link} className={classes} state={breadcrumb.data ? breadcrumb.data : null}>
                    {breadcrumb.title}
                </Link>
                {navArrow}
            </React.Fragment>
        ) : null
    })

    return (
        <Section className={'breadcrumbs'} id={id}>
            <div ref={ref} className={'breadcrumb-group'} {...additionalProps}>
                <Link to={brandHome}>
                    <Icon iconName={"home"}></Icon>
                </Link>
                { breadcrumbsContent.length > 0 ? (
                    <React.Fragment>
                        <span>{'>'}</span>
                        {breadcrumbsContent}
                    </React.Fragment>
                ) : null }
            </div>
        </Section>
    )
})

export default Breadcrumbs
