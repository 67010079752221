import PageLink from "../models/link";
import {getSubCategoryAncestors} from "../models/category";
import {PAGES} from "../config";

/**
 * Builds the breadcrumbs for a specific page
 * @param state The collection of matches from useMatches for the page
 * @param brand The current brand
 */
export async function buildBreadcrumbs (state: any, brand: string|undefined): Promise<PageLink[]> {
    let breadcrumbs: PageLink[] = []
    if (state) {
        for(let i = 0; i < state.length; i++) {
            const crumb = state[i]

            // Should this route's breadcrumb be displayed?
            if(crumb.handle && crumb.handle.hasOwnProperty('displayCrumb') && crumb.handle.displayCrumb) {
                // Does the route have a crumbLabel? If not, use the corresponding category slug
                if(crumb.handle.hasOwnProperty('crumbLabel')) {
                    breadcrumbs.push({
                        title: crumb.handle.crumbLabel,
                        link: crumb.pathname
                    })
                }
                else {
                    const categoryBreadcrumbs = await buildCategoryAncestry(crumb, brand)
                    breadcrumbs.push(...categoryBreadcrumbs)
                }
            }
        }
    }

    return breadcrumbs
}

/**
 * Builds the breadcrumbs for a category ancestry
 * @param crumb A single state breadcrumb
 * @param brand The current brand
 */
async function buildCategoryAncestry(crumb: any, brand: string|undefined): Promise<PageLink[]> {
    let ancestorBreadcrumbs: PageLink[] = []
    let rootURL = '/' + brand + PAGES.CATEGORY_ROOT
    let crumbPath: string = ''
    let ancestry = ''

    // Get the breadcrumb from the url parameters
    if(crumb.handle.hasOwnProperty('isSubcategory')){
        crumbPath = crumb.params.hasOwnProperty('*') ? crumb.params['*'] : ''
        rootURL += '/' + crumb.params.category
    } else if (crumb.params.hasOwnProperty('category')) {
        crumbPath = crumb.params.category
    } else if (crumb.params.hasOwnProperty('resource')) {
        crumbPath = crumb.params.resource
        rootURL = '/' + brand + PAGES.RESOURCES_ROOT
    }

    if (crumbPath) {
        // Build the ancestry
        const categories = await getSubCategoryAncestors(crumbPath)
        categories.forEach(category => {
            ancestorBreadcrumbs.push({
                title: category.name,
                link: rootURL + ancestry + '/' + category.slug
            })
            ancestry += '/' + category.slug
        })
    }


    return ancestorBreadcrumbs
}
