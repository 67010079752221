import React from 'react';
import './index.scss';
import './responsive.scss';
import Section from "../../Basic/Section";
import ResourceSlider from "../../ResourceSlider";
import ResourceProduct from "../../ResourceProduct";
import Product from "../../../models/product";

interface BestSellersProps {
    id: string
    title?: string
    products?: Product[]
    productBackgroundColor?: 'white'|'gray'
    featured?: boolean
}

const BestSellers: React.FC<BestSellersProps> = ({id, title, productBackgroundColor, products, featured}) => {
    let bestSellers: Product[] = []

    if (products) {
        if (featured) {
            bestSellers = products.filter(product => product.featured)
        }
        else {
            bestSellers = products.length > 3 ? products : []
        }
    }

    return bestSellers?.length ? (
        <Section id={id} className={'best-sellers-section bg-lt-gray'}>
            {title ? <h2 className={'best-seller-title'}>{title}</h2> : null}
            <ResourceSlider id={'best-sellers'} className={'products-slider'}>
                {bestSellers.slice(0, 3).map((product, index) => <ResourceProduct key={index} product={product} backgroundColor={productBackgroundColor}/>)}
            </ResourceSlider>
        </Section>
    ) : null
}

export default BestSellers
