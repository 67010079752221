import React, {useContext, useEffect, useState} from 'react';
import './index.scss';
import Button, {ButtonProps} from "../Basic/Button";
import {BrandColorNames} from "../../models/brand";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import ProductRequests from "../../services/requests/ProductRequests";
import {MeContext} from "../../contexts/MeContext";
import ProductDownload from "../../models/product-download";

type downloadButtonFeature = {default: string|undefined, abbreviation: string|undefined}

interface DownloadButtonContentProps extends DownloadButtonProps {
    downloadButtonButton: downloadButtonFeature
}

const DownloadButtonContent: React.FC<DownloadButtonContentProps> = (props) => {
    const {downloadButtonButton, fileUrl, buttonColor = 'main', abbreviated, className, onClick, downloadObject, children, ...additionalProps} = props
    const [downloading, setDownloading] = useState(false)
    const context = useContext(MeContext);

    const classes = 'download ' + ( className ? className : '')
    const buttonText = children ? children : (abbreviated ? downloadButtonButton.abbreviation : downloadButtonButton.default)

    // If the file changes somehow while we are counting a download, immediately allow the download to be countable again
    useEffect(() => {
        if (downloading) {
            setDownloading(false)
        }
    }, [fileUrl]);

    const buttonClick = (event: any) => {
        // If a click event is provided, do it
        if (onClick) {
            onClick(event)
        }

        // If we aren't currently processing a download, wait for the response before trying again
        // This is to prevent spamming the endpoint if they're repeatedly clicking the download button
        if (!downloading && context?.me?.organization_id) {
            setDownloading(true)
            ProductRequests.postProductDownload(context.me.organization_id, downloadObject).then(() => {
                setDownloading(false)
            })
        }
    }

    return (
        <Button to={fileUrl} buttonColor={buttonColor} className={classes} target={"_blank"} {...additionalProps} onClick={buttonClick}>
            <span className={"download-message"}>{buttonText}</span>
        </Button>
    )
}

interface DownloadButtonProps extends Omit<ButtonProps, 'to'|'buttonColor'> {
    fileUrl: string,
    downloadObject: ProductDownload
    buttonColor?: BrandColorNames
    abbreviated?: boolean
}

const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
    const features = ['download-button', 'download-button-abbreviation']
    return (
        <StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()}>
            <StaticFeatureContext.Consumer>
                {staticContext => (
                    <DownloadButtonContent
                        downloadButtonButton={{default: staticContext.features['download-button'], abbreviation: staticContext.features['download-button-abbreviation']}}
                        {...props}/>
                )}
            </StaticFeatureContext.Consumer>
        </StaticFeatureContextProvider>
    )
}

export default DownloadButton
