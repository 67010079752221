import React, {PropsWithChildren} from 'react';
import './index.scss';
import './responsive.scss';
import Section from "../../Basic/Section";
import ContactUsForm from "../../Forms/ContactUsForm";
import Col from "../../Basic/Col";
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";

interface ContactUsSectionProps extends PropsWithChildren {
    id: string,
    headline: string,
    formWidth?: number
}

const ContactUsSection: React.FC<ContactUsSectionProps> = ({id, headline, formWidth, children}) => {
    return (
        <Section id={id} className={'contact-us-section'}>
            <Col id={'contact-headline-col'} colWidth={12}>
                <h2 className={'contact-form-headline'}>{headline}</h2>
                {children}
            </Col>
            <MeContextProvider>
                <MeContext.Consumer>
                    {context => <ContactUsForm formWidth={formWidth} loggedInUser={context.me}/>}
                </MeContext.Consumer>
            </MeContextProvider>
        </Section>
    )
}

export default ContactUsSection
