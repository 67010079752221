import React from 'react'

import './index.scss';
import {useMatches, useParams} from "react-router-dom";
import ErrorPage from "../../ErrorPage";
import {ResourceRootParams} from "../../../models/resource";
import PageWrapper from "../../../components/Basic/PageWrapper";
import CategoryContextProvider, {CategoryContext} from "../../../contexts/CategoryContext";
import CategoryProductsContextProvider, {CategoryProductsContext} from "../../../contexts/CategoryProductsContext";
import ProductList from "../../../components/PageSections/ProductList";
import Button from "../../../components/Basic/Button";
import Section from "../../../components/Basic/Section";
import HTMLComponent from "../../../components/Basic/HTMLComponent";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";
import {resourceCategoriesSlugs} from "../../../config/string_constants";
import DefaultPage from "../../DefaultPage";

/***** RESOURCE PRODUCT LIST *****/
interface ResourceProductListProps {
	categoryID: number
}

const ResourceProductList: React.FC<ResourceProductListProps> = ({categoryID}) => {
	const matches = useMatches()
	return (
		<CategoryProductsContextProvider categoryId={categoryID}>
			<CategoryProductsContext.Consumer>
				{context => (
					<React.Fragment>
						<ProductList sectionId={'resource-product-list'} products={context.loadedData} breadcrumbs={matches} />
						{context.hasAnotherPage ? (
							<StaticFeatureContextProvider featureNames={['load-more-label']} featuresRequestKey={'load-more-label'}>
								<StaticFeatureContext.Consumer>
									{staticContext => (
										<Section id={'load-more'} content_props={{'className': 'pad-top'}}>
											<Button className={'load-more-btn'} buttonColor={'main'} onClick={() => context.loadNext()}>{staticContext.features['load-more-label']}</Button>
										</Section>
									)}
								</StaticFeatureContext.Consumer>
							</StaticFeatureContextProvider>
						) : null}
					</React.Fragment>
				)}
			</CategoryProductsContext.Consumer>
		</CategoryProductsContextProvider>
	)
}

/***** RESOURCE PAGE *****/
interface ResourcesProps {
}

const Resources: React.FC<ResourcesProps> = () => {
	const {resource} = useParams<ResourceRootParams>()

	return resource ? (
		resourceCategoriesSlugs.includes(resource) ? (
			<PageWrapper id={'resources-page'} className={'resources-page'} title={"Resources"}>
				<CategoryContextProvider categorySlug={resource}>
					<CategoryContext.Consumer>
						{context => (
							context.category ? (
								<Section id={'resource-product-page'} className={'resource-category-page'} key={context.category.slug}>
									<HTMLComponent className={'resource-title'} content={context.category.name}/>
									<HTMLComponent className={'resource-content'} content={context.category.body ? context.category.body : ''}/>
									{context.category.id ? <ResourceProductList categoryID={context.category.id}/> : null}
								</Section>
							) : <p className={'no-resources'}>The resources you've requested are currently unavailable. Apologies for the inconvenience.</p>
						)}
					</CategoryContext.Consumer>
				</CategoryContextProvider>
			</PageWrapper>
		) : (
			<DefaultPage pageSlug={resource} className={'resources-page'} />
		)
	) : <ErrorPage errorNumber={404} />
}

export default Resources
