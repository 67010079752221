import React, {PropsWithChildren} from 'react';
import './index.scss';
import './responsive.scss';
import Section from "../../Basic/Section";

interface BorderContentProps extends PropsWithChildren {
    id: string,
    title?: string,
    className?: string
}

const BorderContent: React.FC<BorderContentProps> = ({id, title, className, children}) => {
    return (
        <Section id={id} className={'border-section ' + (className ? className : '')}>
            {title ? <h5 className={'border-title'}>{title}</h5> : null}
            <div className={'border-content'}>
                {children}
            </div>
        </Section>
    )
}

export default BorderContent