import Business from './business';
import BaseEntityModel from '../entities';
import Brand from "../brand";
import User from "../user/user";

/**
 * Our Organization details
 */
export default interface Organization extends BaseEntityModel {

    /**
     * the name of the merchant
     */
    name: string;

    /**
     * Whether or not the merchant is inactive
     */
    inactive: boolean;

    /**
     * The profile image for this organization if set
     */
    profile_image_url?: string;

    /**
     * All businesses for this organization
     */
    businesses?: Business[];

    /**
     * All brands for this organization
     */
    brands?: Brand[];
}

/**
 * Gets an organization that we can use throughout the app if we need to load the organization
 */
export function placeholderOrganization(): Organization {
    return {
        id: 0,
        name: '',
        inactive: false,
        created_at: '',
        updated_at: '',
    };
}

/**
 * splits a full name into an approximated first/last, for shipping_name and billing_name
 * @param name
 * @return organization
 */
export function splitFirstLastName(name?: string) : { first: string, last: string } {
    let retVal = {first: '', last: ''}

    if (name) {
        const name_parts = name.split(' ')

        if (name_parts.length > 1) {
            retVal.first = name_parts[0]
            name_parts.shift()
            retVal.last = name_parts.join(' ')
        }
        else {
            retVal.first = name
        }
    }
    return retVal
}
