import {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import TweenMax from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import {PAGES} from "../../../config";

export default function ScrollToTop() {
	const { pathname } = useLocation();
	const [pageLoaded, setPageLoaded] = useState(false)
	TweenMax.registerPlugin(ScrollToPlugin)

	useEffect(() => {
		// Disable scroll handling for the product category pages
		if (pageLoaded && !pathname.includes(PAGES.CATEGORY_ROOT)) {
			TweenMax.to(window, {duration: .3, scrollTo: { y: 0, x: 0 }})
		}
		else {
			setPageLoaded(true)
		}
	}, [pathname]);

	return null;
}
