import ProductAsset from "../models/productAsset";
import {PossibleBrands} from "./index";
import Asset from "../models/asset";


type BrandAssetType = {
	[key in PossibleBrands]: Asset;
};

export const brandDefaultLogo: BrandAssetType = {
	'cih': {
		'url': '/assets/images/cih-logo.png',
		'caption': 'Case IH Logo',
	},
	'nh': {
		'url': '/assets/images/nh-2-brand-logo.png',
		'caption': 'New Holland Logo',
	},
	'cce': {
		'url': '/assets/images/cce-logo.png',
		'caption': 'CASE Construction Logo',
	}
}


export const brandReversedLogo: BrandAssetType = {
	'cih': {
		'url': '/assets/images/cih-logo-reverse.png',
		'caption': 'Case IH Logo',
	},
	'nh': {
		'url': '/assets/images/nh-2-brand-logo-reverse.png',
		'caption': 'New Holland Logo',
	},
	'cce': {
		'url': '/assets/images/cce-logo-reverse.png',
		'caption': 'CASE Construction Logo',
	}
}

type ProductBrandAssetType = {
	[key in PossibleBrands]: ProductAsset;
};
export const productDefaultAsset: ProductBrandAssetType = {
	'cih': {
		...brandDefaultLogo['cih'],
		'type': 'feature',
	},
	'nh': {
		...brandDefaultLogo['nh'],
		'type': 'feature',
	},
	'cce': {
		...brandDefaultLogo['cce'],
		'type': 'feature',
	}
}

export const brandShowroomPlanningBrochure: BrandAssetType = {
	'cih': {
		'url': 'https://cnhiparts.widen.net/s/nmjjhmjqlx/cih_dms_dealership_merchandising_services_brochure_pm-24170',
		'caption': 'Case IH Showroom Planning Brochure',
	},
	'nh': {
		'url': 'https://cnhiparts.widen.net/s/cr2mlbf7fh/nh_dms_dealership_merchandising_services_brochure_pm-24172',
		'caption': 'New Holland Showroom Planning Brochure',
	},
	'cce': {
		'url': 'https://cnhiparts.widen.net/s/98pzqxclqp/cce_dms_dealership_merchandising_services_brochure_pm-24171',
		'caption': 'CASE Construction Showroom Planning Brochure',
	}
}
