import {productDefaultAsset} from "../config/image_constants";
import {PossibleBrands} from "../config";
import ProductAsset from "../models/productAsset";

export function imageProps (brand: PossibleBrands, image: ProductAsset|undefined, alt?: string ): any {
    return {
        src: image?.url ? image.url : productDefaultAsset[brand!].url,
        alt: image?.caption ? image.alt : (alt ? alt : productDefaultAsset[brand!].alt),
        width: image?.width ? image.width : '',
        height: image?.height ? image.height : ''
    }
}
