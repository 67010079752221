import React from 'react'
import './index.scss';
import Section from "../../components/Basic/Section";
import PageWrapper from "../../components/Basic/PageWrapper";
import PostContextProvider, {PostContext} from "../../contexts/PostContext";
import ErrorPage from "../ErrorPage";
import ContactUsForm from "../../components/Forms/ContactUsForm";
import HTMLComponent from "../../components/Basic/HTMLComponent";
import MeContextProvider, {MeContext} from "../../contexts/MeContext";

interface ContactUsPageProps {
}

const ContactUsPage: React.FC<ContactUsPageProps> = () => {
	// TODO - Add Breadcrumbs
	return (
		<PostContextProvider postSlug={'contact-us'}>
			<PostContext.Consumer>
				{context => (
					context.post ? (
						<PageWrapper id={'contact-us-page'} title={"Contact Us"}>
							<Section id={'contact-page'}>
								<h2 className={'contact-page-title'}>Contact Us</h2>
								<HTMLComponent className={'contact-content'} content={context.post.content ? context.post.content : ''}/>
								<MeContextProvider>
									<MeContext.Consumer>
										{context => <ContactUsForm formWidth={8} buttonLocation={'left'} loggedInUser={context.me}/>}
									</MeContext.Consumer>
								</MeContextProvider>
							</Section>
						</PageWrapper>
					) : <ErrorPage errorNumber={404}/>
				)}
			</PostContext.Consumer>
		</PostContextProvider>)
}

export default ContactUsPage
