import React, {useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import Icon from "../Basic/Icon";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BrandRootParams} from "../../models/brand";
import {connect} from "../../data/connect";
import {setSearchString} from "../../data/session/session.actions";

interface OwnProps {
    className?: string
    onSearch?: () => void
}

interface StateProps {
    searchString: string
}

interface DispatchProps {
    setSearchString: (searchString: string) => void
}

interface SearchBarProps extends OwnProps, DispatchProps, StateProps {
}

const SearchBar: React.FC<SearchBarProps> = ({className, onSearch, searchString, setSearchString}) => {
    const [currentSearchString, setCurrentSearchString] = useState('')
    const {brand} = useParams<BrandRootParams>()
    const navigate = useNavigate()
    const searchPath = '/' + brand + '/search/'
    const {pathname} = useLocation()

    // If the search string is updated elsewhere, reflect that change here
    useEffect(() => {
        if (searchString !== currentSearchString) {
            setCurrentSearchString(searchString)
        }
    }, [searchString]);

    const searchSubmitted = () => {
        if (currentSearchString.length) {
            if (onSearch) {
                onSearch()
            }
            setSearchString(currentSearchString)
            setCurrentSearchString('')
            if(pathname !== searchPath){
                navigate(searchPath + currentSearchString)
            }
        }
    }

    const searchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentSearchString(event.target.value)
    }
    /** Function called to handle submitting the form when the 'enter' key is pressed **/
    const searchKeydown = ((event : any) => {
        if (event.keyCode === 13) {
            searchSubmitted();
        }
    })

    return (
        <div className={('search-bar' + (className ? ' ' + className : ''))}>
            <div className={'search-bar-content'}>
                <input type={'text'} value={currentSearchString} onChange={searchChanged} onKeyDown={searchKeydown}/><Icon iconName={'search'} onClick={searchSubmitted}/>
            </div>
        </div>

    )
}

export default connect<OwnProps, StateProps, DispatchProps >({
    mapDispatchToProps: ({
        setSearchString,
    }),
    mapStateToProps: (state) => ({
        searchString: state.session.searchString
    }),
    component: SearchBar
});
