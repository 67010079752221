import React, {PropsWithChildren} from 'react';
import './index.scss';
import './responsive.scss';
import ResourceProduct from "../../ResourceProduct";
import Product from "../../../models/product";
import Section from "../../Basic/Section";

interface ProductListProps {
    sectionId: string,
    products: Product [],
    productBackgroundColor?: 'white' | 'gray'
    className?: string
    breadcrumbs?: any
}

const ProductList: React.FC<PropsWithChildren<ProductListProps>> = ({sectionId, products, className, productBackgroundColor = 'gray', breadcrumbs, children})  => {
    const classes = 'list-wrapper' + (className ? ' ' + className : '')
    return (
        <Section id={sectionId} className={'product-list'}>
            {children}
            <div className={classes}>
                {products.map((product) => {
                    return (
                        <ResourceProduct key={product.slug + '-' + product.id} product={product} backgroundColor={productBackgroundColor} breadcrumbs={breadcrumbs}/>
                    )
                })}
            </div>
        </Section>
    )
}

export default ProductList
