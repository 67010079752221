import {api} from '../api';
import Page from '../../models/page';
import Product from "../../models/product";
import {addOrderAndFilterToParams} from "../../util/requests";
import ProductDownload from "../../models/product-download";

export default class ProductRequests {

    /**
     * All cached results from any product requests
     */
    private static cachedResults: {[key: string]: Product[]} = {};
    private static cachedSlugs: {[key: string]: Product|undefined} = {};

    /**
     * Searches for a product based on the passed in name
     * @param name
     * @return Product[]
     */
    static async searchProducts(name: string): Promise<Product[]> {

        if (ProductRequests.cachedResults[name] !== undefined)  {
            return ProductRequests.cachedResults[name];
        }

        const { data } = await api.get('/products', {
            params: {
                'search[title]': 'like,*' + name + '*',
                'expand[brandOptions]': '*',
                'expand[brands]': '*'
            }
        });
        const result = (data as Page<Product>).data;

        ProductRequests.cachedResults[name] = result;

        return result;
    }

    /**
     * Gets a product based on the passed in slug
     * @param slug
     * @return Product|undefined
     */
    static async getProduct(slug: string): Promise<Product|undefined> {

        if (ProductRequests.cachedSlugs.hasOwnProperty(slug))  {
            return ProductRequests.cachedSlugs[slug];
        }

        const { data } = await api.get('/products', {
            params: {
                'filter[slug]': slug,
                'expand[assets]': '*',
                'expand[categories]': '*',
                'expand[relatedProducts]' : '*',
                'expand[relatedProducts.assets]' : '*',
                'expand[relatedProducts.brands]' : '*',
                'expand[relatedProducts.brandOptions]' : '*',
                'expand[brandOptions]': '*',
                'expand[brands]': '*'
            },
        });

        const productResults = data.data as Product[];
        const result = productResults.length ? productResults[0] : undefined

        ProductRequests.cachedSlugs[slug] = result;

        return result;
    }

    /**
     * Gets products based on the passed in filters
     * @param params
     * @param limit
     * @return Product[]|undefined
     */
    static async getProducts(params: any, limit = 10): Promise<Product[]|undefined> {
        const config = {
            params: {
                'expand[assets]': '*',
                'expand[brandOptions]': '*',
                'expand[brands]': '*',
                limit: limit
            }
        } as any

        config.params = addOrderAndFilterToParams(config.params, params?.filter, params?.order)

        const { data } = await api.get('/products', config);

        return data.data as Product[];
    }

    /**
     * Posts a product download event
     * @param id
     * @param downloadData
     * @return formData
     */
    static async postProductDownload(id: number, downloadData: ProductDownload): Promise<boolean> {
        const { data } = await api.post('/organizations/' + id + '/product-downloads', downloadData)
        return !!data
    }
}
