import React, {PropsWithChildren} from 'react';
import './index.scss';
import Order from "../../../models/order/order";
import Section from "../../Basic/Section";
import Button, {ButtonProps} from "../../Basic/Button";
import {Link, useParams} from "react-router-dom";
import {BrandRootParams} from "../../../models/brand";
import Img from "../../Basic/Img";
import Disclaimer from "../../ParagraphTypes/Disclaimer";
import Price from "../../Price";
import {PossibleBrands, PAGES} from "../../../config";
import QuantitySelector from "../QuantitySelector";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import OrderProduct from "../../../models/order/order-product";
import Icon from "../../Basic/Icon";
import CurrentOrderContextProvider, {CurrentOrderContext} from "../../../contexts/CurrentOrderContext";
import {imageProps} from "../../../util/image";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";
import LoadingIndicator from "../../Loaders/LoadingIndicator";

interface OrderItemProps {
    brand: PossibleBrands
    orderProduct: OrderProduct
    abbreviated?: boolean
    displayOnly?: boolean
    onNavigateToProduct?: () => void
}

const OrderItem: React.FC<OrderItemProps> = ({brand, orderProduct, abbreviated, displayOnly, onNavigateToProduct}) => {
    const {sku, title, excerpt, cost_dealer, cost_sale, assets, slug} = orderProduct.product
    const productURL = '/' + brand + PAGES.PRODUCT_ROOT + '/' + slug
    let productFeatureImage = assets?.find((asset) => asset.type === 'feature')
    const featureImageProps = imageProps(brand, productFeatureImage, title)

    const quantitySelector = (
        <div className={'quantity'}>
            <QuantitySelector product={orderProduct.product} initialQuantity={orderProduct.quantity} updateOrder displayOnly={displayOnly} />
        </div>
    )

    const linkClicked = () => onNavigateToProduct ? onNavigateToProduct() : null

    return (
        <div className={'order-product-item'}>
            <Link to={productURL} onClick={linkClicked} className={'product-image'}>
                <Img {...featureImageProps}/>
            </Link>
            <div className={'product-description'}>
                <Link to={productURL} onClick={linkClicked} className={'title'}>{title}</Link>
                {!abbreviated ? (
                    <StaticFeatureContextProvider featureNames={['sku-identifier']} featuresRequestKey={'sku-identifier'}>
                        <StaticFeatureContext.Consumer>
                            {context => (
                                <React.Fragment>
                                    <p className={'sku'}><span className={'label'}>{`${context.features['sku-identifier']} `}</span><span>{sku}</span></p>
                                    {excerpt ? <Disclaimer>{excerpt}</Disclaimer> : null }
                                </React.Fragment>
                            )}
                        </StaticFeatureContext.Consumer>
                    </StaticFeatureContextProvider>
                ) : quantitySelector}
            </div>
            {!abbreviated ? quantitySelector : null}
            <Price price={cost_dealer} sale={cost_sale}/>
        </div>
    )
}

interface CurrentCartContentProps extends CurrentCartProps {
    order: Order,
    orderLoaded: boolean,
}

const CurrentCartContent: React.FC<PropsWithChildren<CurrentCartContentProps>> = ({totalLabel, totalPosition, abbreviated, noUpdate, order, orderLoaded, closeCart, children}) => {
    const {brand}  = useParams<BrandRootParams>();

    const totalRow = (
        <CSSTransition classNames="total" timeout={500}>
            <div className={'total-row ' + totalPosition}>
                <p>{totalLabel}</p>
                <Price price={order?.total_price ? order.total_price : 0}/>
            </div>
        </CSSTransition>
    )

    const validOrder = orderLoaded && order.order_products?.length && order.id
    return (
        <Section id={''} className={'current-order' + (abbreviated ? " abbreviated" : '')} content_props={{'className' : 'pad-bot'}} key={order.id}>
            <TransitionGroup>
                {totalPosition === 'top' && validOrder ? totalRow : null}
                {validOrder ? (
                    order.order_products.map((orderProduct) => (
                        orderProduct.product ? (
                            <CSSTransition key={orderProduct.product.id} classNames="in-cart" timeout={500}>
                                <div className={'transition-wrapper'}>
                                    <OrderItem brand={brand!} orderProduct={orderProduct} abbreviated={abbreviated} displayOnly={noUpdate} onNavigateToProduct={closeCart}/>
                                </div>
                            </CSSTransition>
                        ) : null
                    ))
                ) : (
                    <CSSTransition appear={true} in={true} key={'empty-cart'} classNames="empty" timeout={500}>
                        <div className={'empty-cart'}>
                            {orderLoaded ? (

                                <StaticFeatureContextProvider featureNames={['cart-empty', 'start-shopping']} featuresRequestKey={'cart-empty-start-shopping'}>
                                    <StaticFeatureContext.Consumer>
                                        {context => (
                                            <React.Fragment>
                                                <Icon iconName={'cart'}/>
                                                <h2>{context.features['cart-empty']}</h2>
                                                <Button to={'/' + brand} buttonColor={'main'} onClick={() => closeCart ? closeCart() : null}>{context.features['start-shopping']}</Button>
                                            </React.Fragment>
                                        )}
                                    </StaticFeatureContext.Consumer>
                                </StaticFeatureContextProvider>
                            ) : <LoadingIndicator /> }
                        </div>
                    </CSSTransition>
                )}
                {totalPosition === 'bottom' && validOrder ? totalRow : null}
                {validOrder ? (
                    <CSSTransition classNames="total" timeout={500}>
                        <div className={'current-cart-footer'}>
                            {children}
                        </div>
                    </CSSTransition>
                ) : null}
            </TransitionGroup>
        </Section>
    )
}

interface CurrentCartProps extends Omit<ButtonProps, 'to'|'buttonColor'>{
    totalPosition: 'top'|'bottom',
    totalLabel: string,
    abbreviated?: boolean,
    noUpdate?: boolean,
    closeCart?: () => void
}

const CurrentCart: React.FC<CurrentCartProps> = (props) => (
    <CurrentOrderContextProvider>
        <CurrentOrderContext.Consumer>
            {context => (
                <CurrentCartContent order={context.order} orderLoaded={context.loadComplete} {...props}/>
            )}
        </CurrentOrderContext.Consumer>
    </CurrentOrderContextProvider>
)

export default CurrentCart
