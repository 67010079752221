import React from 'react';
import './index.scss';
import './responsive.scss';
import Banner from "../../../models/banner";
import ArticleComponent from "../../ArticleComponent";

interface BannerSectionProps {
    banner: Banner
}

const BannerSection: React.FC<BannerSectionProps> = ({banner}) => {
    const {description, button_text, link_url, image} = {...banner}

    return <ArticleComponent id={'banner-' + banner.id} sectionClass={'banner-section'} content={description} image={image} btnLink={link_url} btnText={button_text}/>
}

export default BannerSection
