import React from 'react';
import './index.scss';
import './responsive.scss';
import Section from "../../Basic/Section";
import ResourceSlider from "../../ResourceSlider";
import ResourceProduct from "../../ResourceProduct";
import Product from "../../../models/product";

interface RecommendationProps {
    id: string
    title?: string
    products?: Product[]
    productBackgroundColor?: 'white'|'gray'
}

const RecommendationSection: React.FC<RecommendationProps> = ({id, title, products, productBackgroundColor}) => {

    const recommended_products = products && products.length ? products : []

    return (
        <Section id={id} className={'recommendation-section bg-lt-gray'}>
            {title ? <h2 className={'recommendation-title'}>{title}</h2> : null}
            <ResourceSlider id={'recommended-products'} className={'products-slider'}>
                {recommended_products.map(product => <ResourceProduct key={product.slug} product={product} backgroundColor={productBackgroundColor}/>)}
            </ResourceSlider>
        </Section>
    )
}

export default RecommendationSection
