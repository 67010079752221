import User from '../../../models/user/user';
import React, {useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import {phoneRegExp} from '../../../util/regex';
import {useFormik} from 'formik';
import './index.scss';
import './responsive.scss';
import Col from "../../Basic/Col";
import InputWrapper from "../../FormComponents/InputWrapper";
import Button from "../../Basic/Button";
import RequiredInput from "../../FormComponents/RequiredInput";
import {getFieldError, StringKeyObject} from "../../../util/form";
import ContactUsRequests from "../../../services/requests/ContactUsRequests";
import LoadingIndicator from "../../Loaders/LoadingIndicator";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";

interface ContactUsData {
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    subject: string,
    message: string,
}

const initialContactUsData = (user?: User): ContactUsData => ({
    first_name: user ? user.first_name : '',
    last_name: user ? user.last_name : '',
    email: user ? user.email : '',
    phone: user && user.phone ? user.phone : '',
    subject: '',
    message: '',
})

interface ContactFormContentProps {
    buttonPlacement?: 'left' | 'right' | 'center',
    setValidationError: (error: string) => void,
    submissionState: boolean,
    contactFormContext : ContactUsData,
    onSubmit: (data: any) => void,
    features: StringKeyObject
}

const ContactUsFormContent: React.FC<ContactFormContentProps> = ({buttonPlacement = 'center', onSubmit, setValidationError, submissionState, contactFormContext, features}) => {
    // Set up the references for each input. This will become useful in ContactUsForm component.
    const firstNameInputRef = useRef<HTMLInputElement>(null)
    const lastNameInputRef = useRef<HTMLInputElement>(null)
    const emailInputRef = useRef<HTMLInputElement>(null)
    const phoneInputRef = useRef<HTMLInputElement>(null)
    const subjectInputRef = useRef<HTMLInputElement>(null)
    const messageInputRef = useRef<HTMLTextAreaElement>(null)

    // Create schema to validate, strip, and convert the inputs based on their requirements
    const ContactUsSchema = Yup.object().shape({
        first_name: Yup.string().trim().required(getFieldError(features['first_name'])),
        last_name: Yup.string().trim().required(getFieldError(features['last_name'])),
        email: Yup.string().email(getFieldError(features['email'], true)).required(getFieldError(features['email'])),
        phone: Yup.string().matches(phoneRegExp, getFieldError(features['phone'] , true)),
        subject: Yup.string(),
        message: Yup.string()
    })

    // Gather the ref elements into a single array
    const inputs = [
        firstNameInputRef?.current,
        lastNameInputRef?.current,
        emailInputRef?.current,
        phoneInputRef?.current,
        subjectInputRef?.current,
        messageInputRef?.current
    ].filter(i => i) as HTMLInputElement[]

    // A function that return an error if one of the input fields has a corresponding error
    const validateForm = (submission: any) : string|undefined => {
        if (submission.errors.first_name && submission.touched.first_name) {
            return submission.errors.first_name
        } else if (submission.errors.last_name && submission.touched.last_name) {
            return submission.errors.last_name
        } else if (submission.errors.email && submission.touched.email) {
            return submission.errors.email
        } else if (submission.errors.phone && submission.touched.phone) {
            return submission.errors.phone
        }
        return undefined
    }

    /**
     * Create the form and set the attribute of Formik
     * initialValues : context of the form that was passed in from ContactUsForm (type: ContactUsData)
     * initialErrors : sets the first error to the first element that is required
     * validationSchema : the object to validate the input fields of the form
     * onSubmit : a function to call when the form is submitted ( aka when 'form.handleSubmit()' is called )
     */

    const form = useFormik( {
        initialValues: contactFormContext,
        initialErrors: {first_name: contactFormContext.first_name},
        validationSchema: ContactUsSchema,
        onSubmit: ((values, { resetForm }) => {
            onSubmit(values)
            resetForm()
        })
    })

    /** Function called each time any of the dependencies change
        Sets the validation error according to the error returned from the input fields in validateForm()**/
    useEffect(() => {
        if(!form.isValid) {
            const currentError = validateForm(form)
            setValidationError(currentError ? currentError : '')
        }
    }, [form, form.isSubmitting, form.isValid, setValidationError])

    /** Function called to handle submitting the form when the 'enter' key is pressed **/
    const onFormKeyDown = ((event : any) => {
        if(event.keyCode === 13 && !form.isSubmitting){
            form.handleSubmit();
        }
    })

    /** Function called to set the name and value of a given field **/
    const onEventChange = ((name : string, value : string) => {
        form.setFieldValue(name, value)
    })

    /** Build the form **/
    return (
        <form className={'flex form'} onSubmit={form.handleSubmit}>
            <Col flex colWidth={6}>
                <RequiredInput
                    name={'first_name'}
                    value={form.values.first_name}
                    error={form.errors.first_name && form.touched.first_name ? form.errors.first_name : undefined}
                    placeholder={features['first_name']}
                    onRequiredInputChange={(name : string, value : string) => {onEventChange(name, value)}}
                    autoCapitalize={'word'}
                    onKeyDown={onFormKeyDown}
                />
            </Col>
            <Col flex colWidth={6}>
                <RequiredInput
                    name={'last_name'}
                    value={form.values.last_name}
                    error={form.errors.last_name && form.touched.last_name ? form.errors.last_name : undefined}
                    placeholder={features['last_name']}
                    onRequiredInputChange={(name : string, value : string) => {onEventChange(name, value)}}
                    autoCapitalize={'word'}
                    onKeyDown={onFormKeyDown}
                />
            </Col>
            <Col flex colWidth={6}>
                <RequiredInput
                    name={'email'}
                    value={form.values.email}
                    error={form.errors.email && form.touched.email ? form.errors.email : undefined}
                    placeholder={features['email']}
                    onRequiredInputChange={(name : string, value : string) => {onEventChange(name, value)}}
                    onKeyDown={onFormKeyDown}
                />
            </Col>
            <Col flex colWidth={6}>
                <InputWrapper label={''} error={form.errors.phone && form.touched.phone ? form.errors.phone : undefined}>
                    <input
                        name={'phone'}
                        value={form.values.phone}
                        placeholder={features['phone']}
                        type={'tel'}
                        onChange={form.handleChange}
                        onKeyDown={onFormKeyDown}
                    />
                </InputWrapper>
            </Col>
            <Col flex colWidth={12}>
                <InputWrapper label={''}>
                    <input
                        name={'subject'}
                        placeholder={features['subject']}
                        value={form.values.subject}
                        type='text'
                        onChange={form.handleChange}
                        ref={subjectInputRef}
                        onKeyDown={onFormKeyDown}
                    />
                </InputWrapper>
            </Col>
            <Col flex colWidth={12}>
                <InputWrapper label={''} >
                   <textarea
                       name={'message'}
                       value={form.values.message}
                       onChange={form.handleChange}
                       rows={8}
                       ref={messageInputRef}
                       onKeyDown={onFormKeyDown}
                   />
                </InputWrapper>
            </Col>
            <Col flex colWidth={12} className={'button-row'}>
                {!submissionState ? (
                    <Button
                        buttonColor={'main'}
                        className={'btn-' + buttonPlacement}
                        type={'submit'}>
                        Submit
                    </Button>
                ) : (
                    <LoadingIndicator />
                )}
            </Col>
        </form>
    )
}


/** Exported Component **/
interface ContactUsFormProps {
    formWidth?: number,
    buttonLocation?: 'left' | 'right' | 'center',
    loggedInUser?: User
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({formWidth, buttonLocation = 'center', loggedInUser}) => {

    const [submissionState, setSubmissionState] = useState(false)
    const [submissionStatus, setSubmissionStatus] = useState(false)
    const [validationError, setValidationError] = useState('');
    const features = ['first_name', 'last_name', 'email', 'phone', 'subject']

    /** Function called when form is submitted
        Takes form data and validates that each of the required fields are filled and processes the data **/
    const onSubmit = (formData: ContactUsData) => {
        if (formData.first_name && formData.last_name && formData.email) {
            setSubmissionState(true)
            processContactForm(formData).then(() => {
                setSubmissionState(false)
                setTimeout(() => setSubmissionStatus(false), 10000)
            })
        }
    }

    /** Function to process the form data and catch any backend errors **/
    const processContactForm = async (contactData: ContactUsData) => {
        try{
            const contactProperties = {
                template: 'contact',
                data: {...contactData}
            }
            await ContactUsRequests.submitGeneralContact(contactProperties)
            setSubmissionStatus(true)
        }
        catch (error: any){
            setValidationError(error)
        }
    }

    /** Build the overall form component **/
    return (
        <StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()}>
            <StaticFeatureContext.Consumer>
                {context => (
                    <React.Fragment>
                        <Col id={'contact-form'} colWidth={formWidth}>
                            <ContactUsFormContent
                                setValidationError={setValidationError}
                                submissionState={submissionState}
                                contactFormContext={initialContactUsData(loggedInUser)}
                                onSubmit={(data: any) => onSubmit(data)}
                                buttonPlacement={buttonLocation}
                                features={context.features}
                            />
                            {submissionStatus ? (
                                <Col colWidth={12} className={'success-message'}>
                                    <p>Thank you for reaching out! A CNH representative will be in touch with you soon.</p>
                                </Col>
                            ) : '' }
                        </Col>
                    </React.Fragment>
                )}
            </StaticFeatureContext.Consumer>
        </StaticFeatureContextProvider>
    )
}

export default ContactUsForm;
