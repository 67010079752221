import Product from "../models/product";

export const FAQContent = {
    cih: [
            {
                title: 'How do I place an order?',
                content: `<p>There is an “Add to Cart” button next to each purchasable item. To order an item, you must click the button to add it to your cart. Once you’ve placed all items in your cart, you can check out by clicking the cart icon in the top right corner of the page. You will be asked to fill in any pertinent contact and shipping information before finalizing your order.</p>`
            },
            {
                title: 'How do I know my order has been received?',
                content: `<p>You will be emailed a confirmation of your order.</p>`
            },
            {
                title: 'Where can I check the status of my order?',
                content: `<p>You can check the status of your order by visiting the <a href="your-orders">Orders</a> section of the site. This can be found in the top right corner of any page. Once on the <a href="your-orders">Orders</a> page, select the order that you want to track. Email notifications are sent each time an update is made to your order (i.e., received, processed, shipped).</p>`
            },
            {
                title: 'Why did I only receive a portion of my order?',
                content: `<p>Orders may arrive in two or more shipments. We partner with several suppliers, and depending on the items in your order, they may be coming from multiple locations, resulting in separate shipments.</p>`
            },
            {
                title: 'How do I know when my order has been shipped?',
                content: `<p>You will receive an email notification.</p>`
            },
            {
                title: 'How is my order billed?',
                content: `<p>At the time of the order, you will enter a dealer code and your order will be billed to your dealer account.</p>`
            },
            {
                title: 'Can I use a gift card to pay for my order? Are there any promo codes?',
                content: `<p>We currently don’t apply any Case&nbsp;IH gift cards toward purchases made on the Case&nbsp;IH Dealership Merchandising Services website. We also don’t accept any promotional codes. Visit our site, however, to view sale items.</p>`
            },
            {
                title: 'Can I cancel or change my order?',
                content: `<p>Customers have a 48-hour grace period to make changes or cancel an order. After 48 hours, the customer is responsible for the full amount of the order in addition to any freight costs.</p>`
            },
            {
                title: 'How do I contact someone with questions regarding this website?',
                content: `<p>Complete the form on the <a href="contact-us">Contact Us</a> section of the website.</p>`
            },
            {
                title: 'What is the warranty on Lozier fixtures?',
                content: `<p>Lozier warrants to the original purchaser that all products manufactured by Lozier are free from defects in material and workmanship. Lozier’s obligation under this warranty is limited to repairing or replacing at their plant any part or parts which shall, within one (1) year after delivery to the original purchaser, be demonstrated to be thus defective under normal use and service.</p>`
            },
            {
                title: 'What if my Lozier fixtures arrive with visible damage?',
                content: `<p>Inspect deliveries carefully and record damages on the carrier’s delivery ticket prior to the carrier leaving the premises. Also, take pictures of the damaged product or packaging if you are able. Send a copy of the carrier’s ticket with the damage noted and photos to <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a>.</p>`
            },
            {
                title: 'What if my Lozier fixture shipment has concealed damage?',
                content: `<p>If your shipment is received in good condition but there is concealed damage, please take pictures and send them to <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a> within 15 calendar days of delivery. You must keep the product and the packaging for an inspection by the carrier.</p>`
            },
            {
                title: 'What is the lead time on Lozier fixtures?',
                content: `<p>These fixtures have a four to six week lead time.</p>`
            },
            {
                title: 'Are Lozier fixtures available in other colors and finishes?',
                content: `<p>There are additional metal finishes and slotwall colors to choose from. Most are available at no additional cost, but there are a few that have an additional fee. Please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
            },
            {
                title: 'How do Lozier fixtures ship?',
                content: `<p>They ship LTL (with the exception of select accessories). Based on the Lozier warehouse location and your dealership’s shipping address, Lozier will determine the most cost-efficient LTL carrier.</p>`
            },
            {
                title: 'Where can I find assembly instructions?',
                content: `<p>Assembly instructions are included with your order. We have select assembly instructions available in the “Best Practices” section of the website under “Product Documentation”. If you are unable to locate what you are looking for, please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
            },
            {
                title: 'Where can I order replacement parts?',
                content: `<p>Complete the information on the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
            },
            {
                title: 'How do I manage my account information after I have placed an order?',
                content: `<p>Please email <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a> with any information that needs to be updated on your order (i.e., email address, shipping address, contact information). There are additional metal finishes and slotwall colors to choose from. Most are available at no additional cost, but there are a few that have an additional fee. Please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
            }
        ],
    nh: [
        {
            title: 'How do I place an order?',
            content: `<p>There is an “Add to Cart” button next to each purchasable item. To order an item, you must click the button to add it to your cart. Once you’ve placed all items in your cart, you can check out by clicking the cart icon in the top right corner of the page. You will be asked to fill in any pertinent contact and shipping information before finalizing your order.</p>`
        },
        {
            title: 'How do I know my order has been received?',
            content: `<p>You will be emailed a confirmation of your order.</p>`
        },
        {
            title: 'Where can I check the status of my order?',
            content: `<p>You can check the status of your order by visiting the <a href="your-orders">Orders</a> section of the site. This can be found in the top right corner of any page. Once on the <a href="your-orders">Orders</a> page, select the order that you want to track. Email notifications are sent each time an update is made to your order (i.e., received, processed, shipped).</p>`
        },
        {
            title: 'Why did I only receive a portion of my order?',
            content: `<p>Orders may arrive in two or more shipments. We partner with several suppliers, and depending on the items in your order, they may be coming from multiple locations, resulting in separate shipments.</p>`
        },
        {
            title: 'How do I know when my order has been shipped?',
            content: `<p>You will receive an email notification.</p>`
        },
        {
            title: 'How is my order billed?',
            content: `<p>At the time of the order, you will enter a dealer code and your order will be billed to your dealer account.</p>`
        },
        {
            title: 'Can I use a gift card to pay for my order? Are there any promo codes?',
            content: `<p>We currently don’t apply any New&nbsp;Holland gift cards toward purchases made on the New&nbsp;Holland Dealership Merchandising Services website. We also don’t accept any promotional codes. Visit our site, however, to view sale items.</p>`
        },
        {
            title: 'Can I cancel or change my order?',
            content: `<p>Customers have a 48-hour grace period to make changes or cancel an order. After 48 hours, the customer is responsible for the full amount of the order in addition to any freight costs.</p>`
        },
        {
            title: 'How do I contact someone with questions regarding this website?',
            content: `<p>Complete the form on the <a href="contact-us">Contact Us</a> section of the website.</p>`
        },
        {
            title: 'What is the warranty on Lozier fixtures?',
            content: `<p>Lozier warrants to the original purchaser that all products manufactured by Lozier are free from defects in material and workmanship. Lozier’s obligation under this warranty is limited to repairing or replacing at their plant any part or parts which shall, within one (1) year after delivery to the original purchaser, be demonstrated to be thus defective under normal use and service.</p>`
        },
        {
            title: 'What if my Lozier fixtures arrive with visible damage?',
            content: `<p>Inspect deliveries carefully and record damages on the carrier’s delivery ticket prior to the carrier leaving the premises. Also, take pictures of the damaged product or packaging if you are able. Send a copy of the carrier’s ticket with the damage noted and photos to <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a>.</p>`
        },
        {
            title: 'What if my Lozier fixture shipment has concealed damage?',
            content: `<p>If your shipment is received in good condition but there is concealed damage, please take pictures and send them to <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a> within 15 calendar days of delivery. You must keep the product and the packaging for an inspection by the carrier.</p>`
        },
        {
            title: 'What is the lead time on Lozier fixtures?',
            content: `<p>These fixtures have a four to six week lead time.</p>`
        },
        {
            title: 'Are Lozier fixtures available in other colors and finishes?',
            content: `<p>There are additional metal finishes and slotwall colors to choose from. Most are available at no additional cost, but there are a few that have an additional fee. Please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
        },
        {
            title: 'How do Lozier fixtures ship?',
            content: `<p>They ship LTL (with the exception of select accessories). Based on the Lozier warehouse location and your dealership’s shipping address, Lozier will determine the most cost-efficient LTL carrier.</p>`
        },
        {
            title: 'Where can I find assembly instructions?',
            content: `<p>Assembly instructions are included with your order. We have select assembly instructions available in the “Best Practices” section of the website under “Product Documentation”. If you are unable to locate what you are looking for, please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
        },
        {
            title: 'Where can I order replacement parts?',
            content: `<p>Complete the information on the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
        },
        {
            title: 'How do I manage my account information after I have placed an order?',
            content: `<p>Please email <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a> with any information that needs to be updated on your order (i.e., email address, shipping address, contact information). There are additional metal finishes and slotwall colors to choose from. Most are available at no additional cost, but there are a few that have an additional fee. Please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
        }
    ],
    cce: [
        {
            title: 'How do I place an order?',
            content: `<p>There is an “Add to Cart” button next to each purchasable item. To order an item, you must click the button to add it to your cart. Once you’ve placed all items in your cart, you can check out by clicking the cart icon in the top right corner of the page. You will be asked to fill in any pertinent contact and shipping information before finalizing your order.</p>`
        },
        {
            title: 'How do I know my order has been received?',
            content: `<p>You will be emailed a confirmation of your order.</p>`
        },
        {
            title: 'Where can I check the status of my order?',
            content: `<p>You can check the status of your order by visiting the <a href="your-orders">Orders</a> section of the site. This can be found in the top right corner of any page. Once on the <a href="your-orders">Orders</a> page, select the order that you want to track. Email notifications are sent each time an update is made to your order (i.e., received, processed, shipped).</p>`
        },
        {
            title: 'Why did I only receive a portion of my order?',
            content: `<p>Orders may arrive in two or more shipments. We partner with several suppliers, and depending on the items in your order, they may be coming from multiple locations, resulting in separate shipments.</p>`
        },
        {
            title: 'How do I know when my order has been shipped?',
            content: `<p>You will receive an email notification.</p>`
        },
        {
            title: 'How is my order billed?',
            content: `<p>At the time of the order, you will enter a dealer code and your order will be billed to your dealer account.</p>`
        },
        {
            title: 'Can I use a gift card to pay for my order? Are there any promo codes?',
            content: `<p>We currently don’t apply any CASE Construction Equipment gift cards toward purchases made on the CASE Dealership Merchandising Services website. We also don’t accept any promotional codes. Visit our site, however, to view sale items.</p>`
        },
        {
            title: 'Can I cancel or change my order?',
            content: `<p>Customers have a 48-hour grace period to make changes or cancel an order. After 48 hours, the customer is responsible for the full amount of the order in addition to any freight costs.</p>`
        },
        {
            title: 'How do I contact someone with questions regarding this website?',
            content: `<p>Complete the form on the <a href="contact-us">Contact Us</a> section of the website.</p>`
        },
        {
            title: 'What is the warranty on Lozier fixtures?',
            content: `<p>Lozier warrants to the original purchaser that all products manufactured by Lozier are free from defects in material and workmanship. Lozier’s obligation under this warranty is limited to repairing or replacing at their plant any part or parts which shall, within one (1) year after delivery to the original purchaser, be demonstrated to be thus defective under normal use and service.</p>`
        },
        {
            title: 'What if my Lozier fixtures arrive with visible damage?',
            content: `<p>Inspect deliveries carefully and record damages on the carrier’s delivery ticket prior to the carrier leaving the premises. Also, take pictures of the damaged product or packaging if you are able. Send a copy of the carrier’s ticket with the damage noted and photos to <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a>.</p>`
        },
        {
            title: 'What if my Lozier fixture shipment has concealed damage?',
            content: `<p>If your shipment is received in good condition but there is concealed damage, please take pictures and send them to <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a> within 15 calendar days of delivery. You must keep the product and the packaging for an inspection by the carrier.</p>`
        },
        {
            title: 'What is the lead time on Lozier fixtures?',
            content: `<p>These fixtures have a four to six week lead time.</p>`
        },
        {
            title: 'Are Lozier fixtures available in other colors and finishes?',
            content: `<p>There are additional metal finishes and slotwall colors to choose from. Most are available at no additional cost, but there are a few that have an additional fee. Please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
        },
        {
            title: 'How do Lozier fixtures ship?',
            content: `<p>They ship LTL (with the exception of select accessories). Based on the Lozier warehouse location and your dealership’s shipping address, Lozier will determine the most cost-efficient LTL carrier.</p>`
        },
        {
            title: 'Where can I find assembly instructions?',
            content: `<p>Assembly instructions are included with your order. We have select assembly instructions available in the “Best Practices” section of the website under “Product Documentation”. If you are unable to locate what you are looking for, please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
        },
        {
            title: 'Where can I order replacement parts?',
            content: `<p>Complete the information on the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
        },
        {
            title: 'How do I manage my account information after I have placed an order?',
            content: `<p>Please email <a href="mailto:julie.crawford@cnh.com">julie.crawford@cnh.com</a> with any information that needs to be updated on your order (i.e., email address, shipping address, contact information). There are additional metal finishes and slotwall colors to choose from. Most are available at no additional cost, but there are a few that have an additional fee. Please complete the <a href="contact-us">Contact Us</a> form on the website and a representative will contact you to discuss your specific needs.</p>`
        }
    ],
}

export const test_products = [
    {
        id: 1,
        title: 'Lorem Ipsum Dolor',
        sku:'Ipsum dolor lorem ahmet set dolor',
        url: 'product-1',
        cost_dealer: 6.75,
        slug: 'product-1',
        body: '',
        assets: [
            {
                'source': '/assets/images/development-images/product.png',
                'caption': 'image alt text',
                'type': 'feature',
            }
        ]
    },
    {
        id: 2,
        title: 'Lorem Ipsum Dolor',
        sku:'Ipsum dolor lorem ahmet set dolor',
        url: 'product-2',
        cost_dealer: 16.75,
        slug: 'product-2',
        body: '',
        assets: [
            {
                'source': '/assets/images/development-images/product-2.png',
                'caption': 'image alt text',
                'type': 'feature',
            }
        ]
    },
    {
        id: 3,
        title: 'Lorem Ipsum',
        sku:'Ipsum dolor lorem ahmet set dolor',
        url: 'product-3',
        cost_dealer: 22.75,
        slug: 'product-3',
        body: '',
        assets: [
            {
                'source': '/assets/images/development-images/product-3.png',
                'caption': 'image alt text',
                'type': 'feature',
            }
        ]
    }
] as Product[]
