import React, {PropsWithChildren, ReactNode, useEffect, useRef, useState} from 'react';
import './index.scss';
import Icon from "../Icon";
import {IconNames} from "../../../models/icon";
import {Transition} from "react-transition-group";
import {ec_get_body, ec_get_body_scroll} from "../../../util/view";
import {ec_add_class, ec_remove_class} from "../../../util/const";
import {connect} from "../../../data/connect";
import {
	decrementOpenModalCount,
	incrementOpenModalCount
} from "../../../data/session/session.actions";


type ModalAlignment = 'left' | 'center' | 'right'

interface OwnProps {
	align: ModalAlignment
	onClose: () => void
	open: boolean
	title?: ReactNode | string
	verticalMargin?: boolean
	className?: string
	closeIcon?: IconNames
	returnToTopOnClose?: boolean
}

interface DispatchProps {
	incrementOpenModalCount: typeof incrementOpenModalCount,
	decrementOpenModalCount: typeof decrementOpenModalCount
}

interface StateProps {
	openModals: number
}

interface ModalOverlayProps extends OwnProps, StateProps, DispatchProps {
}

const ModalOverlay: React.FC<PropsWithChildren<ModalOverlayProps>> = ({ title, open, align, verticalMargin, onClose, className, closeIcon, returnToTopOnClose = false, openModals, incrementOpenModalCount, decrementOpenModalCount, children}) => {
	const modalRef = useRef(null)
	const hasPageBeenRendered = useRef(false)
	const [windowPos, setWindowPos] = useState(0)

	const duration = .3
	const direction = align === 'center' ? 'top' : align;
	const classes = "modal-overlay " + direction + (
		verticalMargin ? ' vertical-margin' : ''
	) + (
		className ? ' ' + className : ''
	) + (
		open ? ' open' : ''
	)

	const close = () => {
		onClose()
	}

	useEffect(() => {
		if(hasPageBeenRendered.current) {
			// Get the width of the body
			let body = ec_get_body();
			let top = ''

			if (open) {
				const pos = ec_get_body_scroll()
				ec_add_class(body, 'disable-body-scroll');

				// Add a negative margin top to the body to create the allusion that the page hasn't moved
				top = -pos + 'px';
				setWindowPos(pos)

				// Increment the number of open modals
				incrementOpenModalCount()
			} else {
				if (openModals <= 1) {
					ec_remove_class(body, 'disable-body-scroll');
					// If the position isn't the window top, jump there really fast, so we can create the allusion that the page never moved
					if (windowPos !== 0) {
						let newPos = (returnToTopOnClose ? 0 : windowPos)

						// Get the scroll position
						if ((document.compatMode || '') === 'CSS1Compat') {
							document.documentElement.scrollTop = newPos;
						} else {
							document.body.scrollTop = newPos;
						}
					}
				}

				if (openModals > 0) {
					decrementOpenModalCount()
				}
			}
			if (openModals < 1) {
				body.style.top = top
			}
		}
		hasPageBeenRendered.current = true
	 }, [open]);

	const onModalClickOutside = (target: EventTarget, currentTarget: EventTarget) => {
		if (target === currentTarget) {
			close()
		}
	}

	const defaultStyle = {
		[direction]: '-100%',
	}

	const transitionStyles = {
		entering: { [direction]: '0px' },
		entered:  { [direction]: '0px' },
		exiting:  { [direction]: '-100%' },
		exited:  { [direction]: '-100%' },
		unmounted:  { [direction]: '-100%' },
	};

	return children ? (
		<Transition timeout={duration} in={open} nodeRef={modalRef}>
			{(state) => (
				<div className={classes} onClick={(event) => onModalClickOutside(event.target, event.currentTarget)}>
					<div style={{
						...defaultStyle,
						...transitionStyles[state]
					}} className="modal-window">
						<p className="title">
							{typeof title === 'string' ? <span>{title}</span> : title}
							<Icon className="modal-close" iconName={closeIcon ? closeIcon : 'close'} onClick={close}/>
						</p>
						<div className={'modal-content'}>
							{children}
						</div>
					</div>
				</div>
			)}
		</Transition>
	) : null
}

export default connect<PropsWithChildren<OwnProps>, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		openModals: state.session.openModals
	}),
	mapDispatchToProps: ({
		incrementOpenModalCount,
		decrementOpenModalCount
	}),
	component: ModalOverlay
})
