import React, {useEffect, useState} from 'react'

import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import Section from "../../components/Basic/Section";
import {connect} from "../../data/connect";
import {setSearchString} from "../../data/session/session.actions";
import SearchContextProvider, {SearchContext, SearchContextState} from "../../contexts/SearchContext";
import LoadingScreen from "../../components/Loaders/LoadingScreen";
import Button from "../../components/Basic/Button";
import Category, {CategorySearchResult, getCategoryAncestry} from "../../models/category";
import Product from "../../models/product";
import ProductList from "../../components/PageSections/ProductList";
import CategoryList from "../../components/PageSections/CategoryList";
import NotFoundError from "../../components/Errors/NotFoundError";
import {PAGES} from "../../config";
import {useParams} from "react-router-dom";
import LoadingIndicator from "../../components/Loaders/LoadingIndicator";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import {ResourceSearchParams} from "../../models/resource";

interface OwnProps {
}

interface StateProps {
	searchString: string
}

interface DispatchProps {
	setSearchString: (searchString: string) => void
}
interface SearchProps extends OwnProps, StateProps, DispatchProps {
}

/** SearchResults - Component to return the search results organized by category, followed by products **/
interface SearchResultsProps {
	productData: Product[]
	categoryData: Category[]
	categoryKeys: string
	searchString: string
	hasLoaded: boolean
}

const SearchResults: React.FC<SearchResultsProps> = ({productData, categoryData, categoryKeys, searchString, hasLoaded}) => {
	const [categoryState, setCategoryState] = useState([] as CategorySearchResult[])
	let categories: CategorySearchResult[] = []

	useEffect(() => {
		// If there is category data, build the ancestry for each category using its parent_id
		if(categoryData.length){
			categoryData.forEach((category) => {
				if(category.parent_id){
					getCategoryAncestry(category.parent_id!).then(categoryAncestors => {
						categories.push({
							category: category,
							ancestors: categoryAncestors
						})
					});
				}
			})
			setCategoryState(categories)
		}
	}, [categoryKeys]);

	return (
		<React.Fragment>
			<Section id={'search-header'} content_props={{'className': 'pad-top'}}>
				<h3>Showing all results for "{searchString}"</h3>
				<hr/>
			</Section>
			{categoryState.length ? (
				<Section id={'search-category-header'} content_props={{'className': 'pad-top'}}>
					<h5>Categories</h5>
				</Section>
			) : null}
			{categoryState.length ? <CategoryList id={'search-categories'} categories={categoryState}/> : (
				!hasLoaded ? <LoadingIndicator /> : null
			)}
			{productData.length ? (
				<Section id={'search-product-header'} content_props={{'className': 'pad-top'}}>
					<h5>Products</h5>
				</Section>
			) : null}
			{productData.length ? <ProductList sectionId={'search-products'} products={productData}/> : (
				!hasLoaded ? <LoadingIndicator /> : null
			)}
		</React.Fragment>
	)
}


/** SearchContent - Component to return the search content and pagination **/

interface SearchContentProps {
	brand: string,
	searchData?: SearchContextState
	searchString: string
}

const SearchContent: React.FC<SearchContentProps> = ({brand, searchData, searchString}) => {
	let [productData, setProductData] = useState([] as Product[])
	let [categoryData, setCategoryData] = useState([] as Category[])
	const features = ['continue-shopping', 'no-search-results-available', 'load-more-label']

	useEffect(() => {

		if(searchData?.loadedData.length) {
			let newProducts = [] as Product[]
			let newCategories = [] as Category[]
			searchData.loadedData.forEach((resource) => {
				if (resource.resource) {
					if (resource.resource_type === 'product') {
						newProducts.push(resource.resource as Product)
					} else if (resource.resource_type === 'category') {
						newCategories.push(resource.resource as Category)
					}
				}
			})

			setProductData(newProducts)
			setCategoryData(newCategories)
		}
	}, [searchData])

	const keys = categoryData.reduce((key, categoryKey) => key + (categoryKey.id)?.toString(), '')

	return (
		<StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()}>
			<StaticFeatureContext.Consumer>
				{context => {
					const continueShoppingBtn = {title: context.features['continue-shopping'], link: `/${brand}` + PAGES.AUTHORIZED_LANDING }
					return (searchData?.loadedData.length ? (
							<React.Fragment>
								<SearchResults productData={productData as Product[]} categoryData={categoryData as Category[]} categoryKeys={keys} hasLoaded={searchData.initialLoadComplete} searchString={searchString}/>
								{searchData.hasAnotherPage ? (
									<Section id={'load-more'} content_props={{'className': 'pad-bot'}}>
										<Button className={'load-more'} buttonColor={'main'} onClick={() => searchData.loadNext()}>{context.features['load-more-label']}</Button>
									</Section>
								) : null}
							</React.Fragment>
						) : <NotFoundError headline={'No Results Found'} subhead={context.features['no-search-results-available']} includeReturnBtn={true} returnBtn={continueShoppingBtn}/>
					)
				}}
			</StaticFeatureContext.Consumer>
		</StaticFeatureContextProvider>
	)
}

/** Search - Search component used outside of component to call upon the search functionality **/

const Search: React.FC<SearchProps> = ({searchString, setSearchString}) => {
	const [activeSearch, setActiveSearch] = useState('')
	const {brand, search} = useParams<ResourceSearchParams>()

	useEffect(() => {
		// If there is a search in the URL, default to that
		if (search && activeSearch !== search) {
			setActiveSearch(search)
			setSearchString(search)
		}
		// If there is a search in the session data, use that if no search in the URL
		else if (searchString && activeSearch !== search) {
			setActiveSearch(searchString)

		}
	}, [search, searchString]);
	return (
		<PageWrapper id={'search-page'} title={"Search for \"" + activeSearch + "\""}>
			{activeSearch.length ? (
				<SearchContextProvider searchString={activeSearch}>
					<SearchContext.Consumer>
						{context => context.initialLoadComplete ? (
								<SearchContent searchData={context} brand={brand!} searchString={activeSearch}/>
							) : <LoadingScreen/>
						}
					</SearchContext.Consumer>
				</SearchContextProvider>
			) : <SearchContent brand={brand!} searchString={activeSearch}/>}
		</PageWrapper>
	)
}

export default connect<OwnProps, StateProps, DispatchProps >({
	mapDispatchToProps: ({
		setSearchString,
	}),
	mapStateToProps: (state) => ({
		searchString: state.session.searchString
	}),
	component: Search
});
