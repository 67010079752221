import React from "react";
import {Link, useParams} from "react-router-dom";
import {BrandRootParams} from "../../models/brand";
import Img from "../Basic/Img";

import './index.scss';
import './responsive.scss';
import {brand_names} from "../../config";
import {brandShowroomPlanningBrochure} from "../../config/image_constants";

interface ShowroomPlanningnContentProps {
}

export const ShowroomPlanningContent: React.FC<ShowroomPlanningnContentProps> = () => {
    const {brand} = useParams<BrandRootParams>()
    const afterSalesManagerTitle = brand === 'cce' ? 'CNH Business Aftermarket Manager (BAM)' : 'CNH Field Aftersales Manager (FAM)'

    return (
        <div className={'showroom-planning-content'}>
            <div className={'percentage'}>
                <Img src={process.env.PUBLIC_URL + `/assets/images/showroom-planning/${brand}/DMS-site-icons-5.jpg`} alt={`${brand} logo`}/>
                <div>
                    <h3>Co-op Eligibility:</h3>
                    <p>The CNH Parts & Service Dealership Merchandising planning program is pre-approved and eligible for 50% Co-op reimbursement per the Co-op eligibility terms.<br/><br/>
                        <strong className={'bold'}>Visit the Co-op Claim Center on the Dealer Portal to learn more:</strong> <a target={"_blank"} href={"https://my.dlrportal.com/wps/myportal/cnhi/discover/tools-and-applications/coopclaimcenter/"} rel="noreferrer">Dealer Portal / Discover / Tools and Applications / Co-op Claim Center</a></p>
                </div>
            </div>

            <hr/>

            <h2>Effective Showroom Planning:</h2>
            <br/>
            <h3>A Well-Planned Showroom:</h3>
            <div className={'custom-list'}>
                <div>
                    <Img src={process.env.PUBLIC_URL + `/assets/images/showroom-planning/${brand}/DMS-site-icons-1.jpg`} alt={`${brand} logo`}/>
                    <p>Sets the tone and welcomes the customer</p>
                </div>
                <div>
                    <Img src={process.env.PUBLIC_URL + `/assets/images/showroom-planning/${brand}/DMS-site-icons-2.jpg`} alt={`${brand} logo`}/>
                    <p>Should feel open and make navigation of the space intuitive</p>
                </div>
                <div>
                    <Img src={process.env.PUBLIC_URL + `/assets/images/showroom-planning/${brand}/DMS-site-icons-3.jpg`} alt={`${brand} logo`}/>
                    <p>Allows for flexibility of product and fixture placement</p>
                </div>
                <div>
                    <Img src={process.env.PUBLIC_URL + `/assets/images/showroom-planning/${brand}/DMS-site-icons-4.jpg`} alt={`${brand} logo`}/>
                    <p>Exposes and guides the customer to all products available</p>
                </div>
            </div>

            <br/>
            <br/>

            <h3>Showroom Floor Planning Process:</h3>
            <ol>
                <li>You place order for floor plan services <a href={"#showroom-floor-planning-services-products"}>here</a> or <Link to={`/${brand}/contact-us`}>Contact Us</Link> directly</li>
                <li>You receive pre-kickoff assets via email</li>
                <li>You complete pre-kickoff assets and email back to us</li>
                <li>{`We schedule a project kickoff meeting with you, the ${afterSalesManagerTitle} or Inside Sales Representative (ISR) and a Retail Consultant`}</li>
                <li>We generate a meeting recap and any revised documents</li>
                <li>You validate the recap and any revised documents</li>
                <li>We generate a preliminary floor plan with recommended category adjacencies</li>
                <li>We send a preliminary floor plan to you for approval/input</li>
                <li>You approve floor plan or provide input; we make agreed upon adjustments</li>
                <li>We generate a fixture, signage and accessories quote for final floor plan</li>
                <li>We provide a quote and final floor plan to you</li>
                <li>You place your order <a href={"#showroom-floor-planning-services-products"}>here</a> or <Link to={`/${brand}/contact-us`}>Contact Us</Link> directly to authorize an order placement on your behalf</li>
            </ol>
            {brand ? (
                <p><strong>For more information on showroom planning and merchandising, download the</strong> <a target={"_blank"} href={brandShowroomPlanningBrochure[brand]['url']} rel="noreferrer">Showroom Planning and Merchandising Brochure</a></p>
            ) : null}

            <hr/>

            <h3>Testimonial:</h3>
            <p>
                “The makeover of our Gibson City and Polo locations was a turning point for us. Investing in our showrooms not only improved their appearance, but also boosted our employees’ pride and energy.
                <br/>
                <br/>
                Customers immediately noticed the difference, which enhanced our culture and professionalism. Julie was fantastic—she thought of everything, provided multiple design options and made the whole process seamless and easy.
                <br/>
                <br/>
                Every year, we tackle 2-3 locations, and the transformation has made it clear that we are a top-notch Case IH dealership.”
                <br/>
                <strong><i>Ryan Bennett, Director of Parts and Service</i></strong>
            </p>

            <hr/>

            <div className={'guide-section'}>
                <div>
                    <h3>Showroom Planning and Merchandising Guide:</h3>
                    <p>
                        The {brand ? brand_names[brand] : ''} Showroom Planning and Merchandising Guide is intended to assist dealers in demonstrating the essence of the brand. Whether you are considering building a new dealership or updating an existing location, this guide will help you to provide the very best shopping environment for your customer. It contains information and instruction on everything from exterior signage, to showroom layout, to fixtures and signage and more.
                        <br/>
                        <br/>
                        <strong>Download here:</strong> <a target={"_blank"} href={process.env.PUBLIC_URL + `/assets/images/showroom-planning/${brand}/E8930_PS_${brand}_2024_EN_DMS_Showroom_Planning_Merchandising_Guide_FA(1).pdf`} rel="noreferrer">Showroom Planning and Merchandising Guide</a>
                    </p>
                </div>
                <Img src={process.env.PUBLIC_URL + `/assets/images/showroom-planning/${brand}/${brand}-merchandising-guide-thumbnail.jpg`} alt={`${brand} logo`}/>
            </div>

            <hr/>

            <p className={'cta'}><Link to={`/${brand}/contact-us`}>Contact Us</Link> for more information</p>
        </div>
    )
}

export default ShowroomPlanningContent
