import React, {PropsWithChildren, ReactElement} from 'react';
import './index.scss';
import './responsive.scss';
import Section from "../Basic/Section";
import {formatPrice} from "../../util/regex";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";

/**
 * Children layout for Table component should include only the rows of data
 *      <tr>
 *          <td><p>Title</p></td>
 *          <td>...</td>
 *      </tr>
 *      <tr>
 *          <td>...</td>
 *      </tr>
 *
 * Footer content is added to the 'footer' attribute as either a single string (used to display price) or the rows
 * of the footer as a ReactNode, similar to the layout of the children
 *
 */

interface TableProps {
    id: string,
    colNames?: string[],
    totalFooter?: number,
    className?: string,
}

const Table: React.FC<PropsWithChildren<TableProps>> = ({id, colNames, totalFooter, className, children }) => {
    let content = [] as ReactElement[]

    let header = colNames ? (
        <thead>
            <tr>
                {colNames.map((title, index) => {
                    return <th key={index} scope={'col'}>{title}</th>
                })}
            </tr>
        </thead>
    ) : null

    // The number of columns minus one. Leaves last column for the total price.
    const totalColSpan = header?.props.children.props.children.length - 1
    let footer
    const getTableFooter = (totalPriceLabel: string|undefined) => {
        return totalFooter !== undefined ? (
            <tfoot>
            <tr className={'total'}>
                <th colSpan={totalColSpan} scope={'row'}>{totalPriceLabel!}</th>
                <td><p className={'bold base'} dangerouslySetInnerHTML={{__html: formatPrice(totalFooter)}} /></td>
            </tr>
            </tfoot>
        ) : null
    }

    React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
            switch (child.type) {
                case 'thead':
                    header = child
                    break
                case 'tfoot':
                    footer = child
                    break
                default:
                    content.push(child)
            }
        }
    })

    return (
        <Section id={id} className={'table'}>
            <StaticFeatureContextProvider featureNames={['total-price-label']} featuresRequestKey={'total-price-label'}>
                <StaticFeatureContext.Consumer>
                    {context => {
                        footer = getTableFooter(context.features['total-price-label'])
                        return (
                            <table className={className}>
                                {header}
                                <tbody>
                                {content}
                                </tbody>
                                {footer}
                            </table>
                        )
                    }}
                </StaticFeatureContext.Consumer>
            </StaticFeatureContextProvider>
        </Section>
    )
}

export default Table
