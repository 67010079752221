import BaseModel from './base-model';
import CategoryRequests from "../services/requests/CategoryRequests";
import BrandOption from "./brand-option";
import ProductAsset from "./productAsset";
import Brand from "./brand";

/**
 * The interface for what we can expect our category to look like
 */
export default interface Category extends BaseModel {

    /**
     * The name of the category
     */
    name: string;

    /**
     * The category slug
     */
    slug: string;

    /**
     * A short description of teh category
     */
    description?: string;

    /**
     * The description of the category, if provided, in WYSIWYG format
     */
    body?: string;

    /**
     * The parent category ID, if it exists
     */
    parent_id?: number;

    /**
     * The parent category, if it exists
     */
    parent?: Category;

    /**
     * The category image url, if it exists
     */
    image_url?: string;

    /**
     * The category image asset, if it exists
     */
    image?: ProductAsset;

    /**
     * The category image asset, if it exists
     */
    thumbnail?: ProductAsset;

    /**
     * The category's published timestamp
     */
    published_at?: string;

    /**
     * Total number of products in category
     */
    product_count?: number;

    /**
     * The category's brand options
     */
    brand_options?: BrandOption[];

    /**
     * The category's brands
     */
    brands?: Brand[];

    /**
     * Collection of subcategories
     */
    children?: Category[];
}

export interface CategorySearchResult {
    /**
     * Search result category
     */
    category: Category,

    /**
     * Category ancestors of search result category
     */
    ancestors: Category[]
}

/**
 * Finds a category with the passed in name if one exists
 * @param categories
 * @param name
 */
export function findCategory(categories: Category[], name: string): Category|undefined {
    return categories.find(i => i.name.toLowerCase() === name.toLowerCase());
}

/**
 * Finds a category with the passed in slug if one exists
 * @param slug
 */

export async function findCategoryBySlug(slug: string): Promise<Category|undefined> {
    return await CategoryRequests.getCategory(slug)
}

/**
 * Gathers and returns a collection of parent categories for a single category
 * @param categoryParentId A parent id of a category
 */
export async function getCategoryAncestry(categoryParentId: number) {
    let ancestors = [] as Category[]
    const parentCategory = await CategoryRequests.getCategoryById(categoryParentId)

    if(parentCategory){
        if(parentCategory.parent){
            if(parentCategory.parent.parent_id){
                ancestors = await getCategoryAncestry(parentCategory.parent.parent_id)
            }
            ancestors.push(parentCategory.parent)
        }
        ancestors.push(parentCategory)
    }
    return ancestors
}

/**
 * Gathers and returns a collection of sub categories
 * @param ancestry A string of the ancestry path slugs
 */
export async function getSubCategoryAncestors(ancestry: string): Promise<Category[]> {
    const ancestorArray = ancestry.split('/')
    let ancestors: Category[] = []

    for(let i = 0; i < ancestorArray.length; i++) {
        const category = await findCategoryBySlug(ancestorArray[i])
        if(category){
            ancestors.push(category)
        }
    }
    return ancestors
}

/**
 * Removes a category with the passed in name if one exists
 * @param categories
 * @param name
 */
export function removeCategoryFromList(categories: Category[], name: string): Category[] {
    return categories.filter(i => i.name.toLowerCase() !== name.toLowerCase());
}

/**
 * Outputs an empty Category object
 */
export function generateEmptyCategory(): Category {
    return {
        name: "",
        slug: "",
    }
}
export type CategoryParams = { category: string, "*"?: string }
