export const brands = ['cih', 'cce', /*'nha', 'nhc',*/ 'nh']
export type PossibleBrands = 'cih' | 'cce' | 'nh';

export const resources = ['printable-signage-templates', 'planograms', 'showroom-planning-services']
export type PossibleResources = 'printable-signage-templates' | 'planograms' | 'showroom-planning-services'

export type IEnvironments = 'development'| 'staging' | 'production'
export type EnvironmentSwitch = {[key in IEnvironments]: string}
export type StringObject = { [key: string]: string; };
export const ENVIRONMENTS: EnvironmentSwitch = {
	development: 'development',
	staging: 'staging',
	production: 'production'
}

/***** Login Page Link Structure ******
* 1. brand-specific internal links: do not include leading backslash
* 2. brand-agnostic internal links: include leading backslash
* 3. External links: include "https://" prefix
***************************************/
export const loginPage: EnvironmentSwitch = {
	development: 'sign-in',
	staging: 'sign-in',
	production: '/validate'
}

export const SITE_URL = 'https://cnhmerchandising.com'


// Default Landing Page is the full page route
// Authorized Landing Page does not include the brand
export const PAGES: StringObject = {
	DEFAULT_LANDING: '/splash',
	AUTHORIZED_LANDING: '/',
	CATEGORY_ROOT: '/products',
	PRODUCT_ROOT: '/product',
	ORDERS_ROOT: '/your-orders',
	RESOURCES_ROOT: '/resources',
	CART: '/my-cart',
	CHECKOUT: '/checkout',
	SSO_LOGIN: 'https://api.cnhmerchandising.com/v1/auth/sso-init',
}

export const PAGE_REDIRECTS: StringObject = {
	CATEGORY: 'fixtures',
	RESOURCES: 'printable-signage-templates',
}


type BrandObject = {
	[key in PossibleBrands]: StringObject;
};

type BrandString = {
	[key in PossibleBrands]: string;
}

export const brand_colors: BrandObject = {
	'cih' : {
		'--color-main': '#A71930',
		'--color-second': '#808080',
		'--color-third': '#ef7622',
		'--color-fourth': '#ece9e6',
	},
	'cce' : {
		'--color-main': '#e58e1a',
		'--color-second': '#808080',
		'--color-third': '#ef7622',
		'--color-fourth': '#ece9e6',
	},
	// NHA & NHC are combined for this project, maintaining in case they decide to separate them
	// 'nha' : {
	// 	'--color-main': '#00539b',
	// 	'--color-second': '#ffd200',
	// 	'--color-third': '#ef7622',
	// 	'--color-fourth': '#ece9e6',
	// },
	// 'nhc' : {
	// 	'--color-main': '#000000',
	// 	'--color-second': '#ffd204',
	// 	'--color-third': '#ef7622',
	// 	'--color-fourth': '#ece9e6',
	// },
	'nh' : {
		'--color-base': '#40444C',
		'--color-black': '#40444C',
		'--color-main': '#00205B',
		'--color-second': '#ffd200',
		'--color-third': '#ef7622',
		'--color-fourth': '#ece9e6',
	},
}

export const brand_names: BrandString = {
	'cih': 'Case IH',
	'nh': 'New Holland',
	'cce': 'CASE Construction Equipment',
}

export const default_colors: StringObject = {
	'--color-fifth': '#5b85aaff',
	'--color-base': '#000000',
	'--color-black': '#000000',
	'--color-dk-gray': '#5F5E5E',
	'--color-md-gray': '#CCCCCC',
	'--color-lt-gray': '#F2F2F2',
	'--color-xlt-gray': '#F4F4F4',
	'--color-white': '#FFFFFF',
	'--color-error': '#ff0000',
}
