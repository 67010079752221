import {IconNames} from "./icon";
import {Link} from "react-router-dom";
import Icon from "../components/Basic/Icon";
import React from "react";
import PageLink from "./link";
import {
    featuredCategoriesSlugs,
    menu_constants,
    menuNameOptions, resourceCategoriesSlugs
} from "../config/string_constants";
import {PAGES, PossibleBrands} from "../config";
import Category from "./category";

/**
 * The interface for what we can expect a menu submenu to look like
 */
export type PossibleMenus = 'main-menu' | 'footer'
export default interface MenuItem {
    /**
     * The title of the submenu
     */
    title: string;
    /**
     * The content of the submenu
     */
    sub_menu?: PageLink[];
}

/**
 * Function that builds an array of the interior menu links in a menu
 */
export const buildMenuDropdowns = ((expanders: MenuItem[], mobileIcon?: IconNames, doLogout?: () => void) => {
    return expanders.map((expander) => {
        return {
            label: expander.title,
            children: (expander.sub_menu ? expander.sub_menu.map((subMenu, index) => {
                const linkContents = (
                    <React.Fragment>
                        <span>{subMenu.title}</span>
                        {mobileIcon ? <Icon iconName={mobileIcon}/> : ''}
                    </React.Fragment>
                )

                let onClick = () => {}

                if (subMenu.title === 'Log Out' && doLogout) {
                    onClick = doLogout
                }

                return subMenu.link.indexOf('http') > -1 ? (
                    <a key={index} href={subMenu.link} rel={'noreferrer'} target={'_blank'} onClick={onClick}>{linkContents}</a>
                ) : (
                    <Link key={index} to={subMenu.link} onClick={onClick}>{linkContents}</Link>
                )
                }) : [])
        }
    })
})

/**
 * Builds the title and link of menu items with an added brandRoot
 */
export const buildMenuItems = ((menu_items: Category[], brand: PossibleBrands|undefined, page_root?: string) => {
    return menu_items.map((item) => {
        return {
            title: item.name,
            link: item.name === 'Privacy Notice' ? item.slug : ('/' + brand + (page_root ? `${page_root}/` : '/') + item.slug)
        }
    })
})

/**
 * Builds a menu from a collection of menuItems and an associated brand
 * @param menuType - the type of menu being built
 * @param menuItems - an array of Categories to be menu links
 * @param brand - the associated brand
 *
 * @return an object of menu dropdowns with corresponding links
 */
export const buildMenu = ((menuType: PossibleMenus, menuItems: Category[], brand: PossibleBrands|undefined) => {
    // Apply any menu name changes
    menuNameOptions.forEach((option) => {
        if(option.omitMenu !== menuType){
            let menuItem = menuItems.find((item) => item.slug === option.slug)
            if(menuItem){
                menuItem.name = option.name
            }
        }
    })

    const categoryMenuItems = menuItems.filter(category => featuredCategoriesSlugs.includes(category.slug))
    const resourceCategoryItems = menuItems.filter(resource => resourceCategoriesSlugs.includes(resource.slug))
    const resourcePageItems = menu_constants.resource_menu_items

    // Add Showroom Planning Guide to Resources menu
    const resourceMenuItems = [...resourceCategoryItems, ...resourcePageItems]

    const categoryMenu = buildMenuItems(categoryMenuItems, brand, PAGES.CATEGORY_ROOT)
    const resourceMenu = buildMenuItems(resourceMenuItems, brand, PAGES.RESOURCES_ROOT)

    // Build the menu
    let menuExpanders = [
        {title: menu_constants.item_names.categories, sub_menu: categoryMenu},
        {title: menu_constants.item_names.resources, sub_menu: resourceMenu}
    ]

    // If it's the footer menu, add 'Support' links
    if(menuType === 'footer'){
        const supportMenu = buildMenuItems(menu_constants.support_menu_items, brand)
        menuExpanders = [
            ...menuExpanders,
            {title: menu_constants.item_names.support, sub_menu: supportMenu}
        ]
    }

    return menuExpanders
})
