import Organization, {placeholderOrganization} from "../../models/organization/organization";
import React, {Dispatch, PropsWithChildren, SetStateAction, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import OrganizationRequests from "../../services/requests/OrganizationRequests";
import LoadingScreen from "../../components/Loaders/LoadingScreen";

export interface MyOrganizationContextState {
    organization: Organization,
    setOrganization: (organization: Organization) => void,
    organizationInitiated: boolean
}

const defaultState = {
    organization: placeholderOrganization(),
    organizationInitiated: false
} as MyOrganizationContextState

let persistedState = defaultState;

export const MyOrganizationContext = React.createContext<MyOrganizationContextState>(defaultState);

function createSetOrganizationCallback(organizationContext: MyOrganizationContextState, setOrganizationContext: Dispatch<SetStateAction<MyOrganizationContextState>>) {
    return (organization: Organization) => {
        persistedState.organization = organization;
        setOrganizationContext({
            ...organizationContext,
            organization: {...organization},
        });
    }
}

interface MyOrganizationContextProviderProps {
    organizationId: number,
    hideLoadingSpace?: boolean,
}

const MyOrganizationContextProvider: React.FC<PropsWithChildren<MyOrganizationContextProviderProps>> = ({organizationId, hideLoadingSpace, ...props}) => {
    const [myOrganizationContext, setMyOrganizationContext] = useState(persistedState)
    const [myOrganizationId, setMyOrganizationId] = useState(organizationId)
    const navigate = useNavigate()

    useEffect(() => {
        if(organizationId !== 0) {
            OrganizationRequests.getMyOrganization(myOrganizationId).then(organization => {
                persistedState.organization = organization
                persistedState.organizationInitiated = true
                setMyOrganizationContext(persistedState)
            }).catch(error => {
                setMyOrganizationId(0)
                navigate('/')
                console.log(error)
            })
        }
    }, [organizationId])

    const fullContext = {
        ...persistedState,
        setOrganization: createSetOrganizationCallback(persistedState, setMyOrganizationContext),
    } as MyOrganizationContextState

    return (
        <MyOrganizationContext.Provider value={fullContext}>
            {myOrganizationContext.organizationInitiated ? props.children :
                hideLoadingSpace ? '' : <LoadingScreen />
            }
        </MyOrganizationContext.Provider>
    )
}

export default MyOrganizationContextProvider
