import React, {forwardRef} from 'react';
import './index.scss';
import './responsive.scss';
import {Link, useParams} from "react-router-dom";
import Product from "../../models/product";
import ResourceImage, {ResourceImageProps} from "../ResourceImage";
import {BrandRootParams} from "../../models/brand";
import ResourceCaption from "../ResourceCaption";
import {PAGES} from "../../config";
import {imageProps} from "../../util/image";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import ProductActions from "../ProductActions";


interface ResourceProductProps extends Omit<ResourceImageProps, 'image'|'alt'|'link'|'caption'> {
    product: Product
    breadcrumbs?: any
}

const ResourceProduct = forwardRef<HTMLDivElement, ResourceProductProps>((props, ref) => {

    const {product, backgroundColor, className, breadcrumbs, ...additionalProps} = props
    const featuredImage = product.assets?.find(image => image.type === 'feature')
    const {brand}  = useParams<BrandRootParams>();
    const features = ['sku-identifier']

    // Destructor the imageProps and replace the 'src' key with 'image'
    const resourceImageProps = imageProps(brand!, featuredImage)
    const {src: image, ...rest} = resourceImageProps
    const updatedResourceImageProps = {image, ...rest}

    return (
        <div className={'resource-product'} ref={ref}>
            <Link to={'/' + brand + PAGES.PRODUCT_ROOT + '/' + product.slug} state={{matches: breadcrumbs}}>
                <ResourceImage backgroundColor={backgroundColor} {...updatedResourceImageProps} {...additionalProps} />
                <StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()} >
                    <StaticFeatureContext.Consumer>
                        {context => {
                            const productSKU = <React.Fragment><span className={'sku'}>{context.features['sku-identifier']}</span> {product.sku}</React.Fragment>
                            return <ResourceCaption headline={product.title} subhead={productSKU} price={product.cost_dealer} sale={product.cost_sale} free={product.free}/>
                        }}
                    </StaticFeatureContext.Consumer>
                </StaticFeatureContextProvider>
            </Link>
            <div className={'resource-button'}>
                <ProductActions product={product} linkUnavailableBreadcrumbs={breadcrumbs} />
            </div>
        </div>
    )
})

export default ResourceProduct
