export default interface SessionState {
    loadingCount: number;
    openModals: number;
    searchString: string;
}

export const initialSessionState: SessionState = {
    loadingCount: 0,
    openModals: 0,
    searchString: ''
}
