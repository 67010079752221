import React, {useEffect, useState} from 'react'
import {AppContextProvider} from './data/AppContext';
import {Navigate, Outlet, useParams, useLocation} from "react-router-dom";
import cssVars from "css-vars-ponyfill";
import {brand_colors, default_colors, brands, PAGES, PossibleBrands, brand_names, PAGE_REDIRECTS} from "./config";

import Home from './pages/Home';
import AuthenticatedRoute from './components/Authentication/AuthenticatedRoute';
import Splash from "./pages/Splash";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import {BrandRootParams} from "./models/brand";
import CurrentCartPopup from "./components/CartComponents/CurrentCartPopup";
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";


/* Theme variables */
import './theme/styles.scss'
import MainMenu from "./components/MainMenu";
import Orders from "./pages/Orders";
import CategoryTemplate from "./pages/CategoryTemplate";
import Product from "./pages/SingleProduct";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Search from "./pages/Search";
import ErrorPage from "./pages/ErrorPage";
import Resources from "./pages/Resources/ResourceTemplate";
import Footer from "./components/Basic/Footer";
import ScrollToTop from "./components/Basic/ScrollToTop";
import Order from "./pages/SingleOrder";
import ResourcesRoot from "./pages/Resources";
import DefaultPage from "./pages/DefaultPage";
import ContactUsPage from "./pages/ContactUs";
import {breadCrumbLabels, getBrandedNoMenuPaths, menu_constants} from "./config/string_constants";
import RedirectExternal from "./components/RedirectExternal";
import SSOValidation from "./pages/SSOValidation";

export interface BrandOutletProps {
	setCartShowing: (state: boolean) => void
}

const BrandRoot: React.FC = () => {
	const [cartShowing, setCartShowing] = useState(false)
	const [pageNavigated, setPageNavigated] = useState(false)
	const [pageLoaded, setPageLoaded] = useState(false)
	// Get brand from the parameters
	const {brand}  = useParams<BrandRootParams>();
	const { pathname } = useLocation();

	useEffect(() => {
		if (pageLoaded) {
			setPageNavigated(true)
		}
		else {
			setPageLoaded(true)
		}
	}, [pathname]);

	useEffect(() => {
		if (pageNavigated) {
			setPageNavigated(false)
		}
	}, [pageNavigated]);

	const setBrandColors = (brand: PossibleBrands) => {
		let vars = {...default_colors, ...brand_colors[brand]};
		cssVars({
			// Target Element(s)
			rootElement: document,
			shadowDOM: false,

			// Sources
			include: "link[rel=stylesheet],style",
			exclude: '',
			variables: vars,

			// Options
			onlyLegacy: true,
			preserveStatic: true,
			preserveVars: false,
			silent: false,
			updateDOM: true,
			updateURLs: true,
			watch: null,

			// Functions
			onError: function (message: string, elm: HTMLElement, xhr: XMLHttpRequest, url: string) {
				console.log(message + 'in' + elm);
			},
			onWarning: function (message: string) {
				console.log(message);
			}
		});
	}

	// Setting Brand Colors
	useEffect(() => {
		if (brand && brands.includes(brand)) {
			setBrandColors(brand)
		}
	}, [brand])

	return brand ? (
		<div className={'brand-wrapper ' + brand}>
			{getBrandedNoMenuPaths(brand).includes(pathname) ? (
				<Outlet />
			) : (
				<React.Fragment>
					<ScrollToTop/>
					<MainMenu reverseLogo={brand === 'cce'} onCartClicked={() => setCartShowing(true)} reset={pageNavigated} slot="nav" />
					<CurrentCartPopup open={cartShowing} onClose={() => setCartShowing(false)} />
					<Outlet context={{setCartShowing}}/>
					<Footer title={menu_constants.site_name} reverseLogo={brand !== 'cih'} copyright={ (new Date().getFullYear()) + ' ' + brand_names[brand] + '. CNH Industrial America LLC. All rights reserved.'} />
				</React.Fragment>
			)}
		</div>
	) : <Navigate to={PAGES.DEFAULT_LANDING}/>
}

const router = createBrowserRouter([
	{
		path: "/:brand",
		element: <BrandRoot/>,
		handle: {displayCrumb: false},
		children: [
			{
				index: true,
				element: <AuthenticatedRoute><Home/></AuthenticatedRoute>,
				handle: {displayCrumb: false}
			},
			{
				path: "your-orders",
				handle: {displayCrumb: true, crumbLabel: breadCrumbLabels['your-orders']},
				children: [
					{
						index: true,
						element: <AuthenticatedRoute><Orders/></AuthenticatedRoute>,
					},
					{
						path: ":orderId",
						element: <AuthenticatedRoute><Order/></AuthenticatedRoute>,
						handle: {displayCrumb: false}
					}
				]
			},
			{
				path: "products",
				element: <Navigate to={PAGE_REDIRECTS.CATEGORY}/>,
			},
			{
				path: "products/:category",
				element: <AuthenticatedRoute><CategoryTemplate/></AuthenticatedRoute>,
				handle: {displayCrumb: true},
				children: [
					{
						path: "*",
						element: <AuthenticatedRoute><CategoryTemplate/></AuthenticatedRoute>,
						handle: {displayCrumb: true, isSubcategory: true}
					}
				]
			},
			{
				path: "product/:product",
				element: <AuthenticatedRoute><Product/></AuthenticatedRoute>,
				handle: {displayCrumb: true}
			},
			{
				path: "my-cart",
				element: <AuthenticatedRoute><Cart/></AuthenticatedRoute>,
				handle: {displayCrumb: true, crumbLabel: breadCrumbLabels['my-cart']}
			},
			{
				path: "checkout",
				element: <AuthenticatedRoute><Checkout/></AuthenticatedRoute>,
				handle: {displayCrumb: false}
			},
			{
				path: "search/",
				element: <Navigate to={PAGES.DEFAULT_LANDING}/>,
				handle: {displayCrumb: false}
			},
			{
				path: "search/:search",
				element: <AuthenticatedRoute><Search/></AuthenticatedRoute>,
				handle: {displayCrumb: false}
			},
			{
				path: "resources",
				element: <AuthenticatedRoute><ResourcesRoot/></AuthenticatedRoute>,
				handle: {displayCrumb: false, crumbLabel: breadCrumbLabels['resources']},
				children: [
					{
						index: true,
						element: <Navigate to={PAGE_REDIRECTS.RESOURCES}/>,
					},
					{
						path: ":resource",
						element: <AuthenticatedRoute><Resources/></AuthenticatedRoute>,
						handle: {displayCrumb: true}
					}
				]
			},
			{
				path: ":page",
				element: <AuthenticatedRoute><DefaultPage/></AuthenticatedRoute>,
				handle: {displayCrumb: false}
			},
			{
				path: "contact-us",
				element: <AuthenticatedRoute><ContactUsPage/></AuthenticatedRoute>,
				handle: {displayCrumb: false}
			},
			{
				path: "sign-in",
				element: <SignIn/>
			},
			{
				path: "sign-up",
				element: <SignUp/>
			},
			{
				path: "*",
				element: <ErrorPage errorNumber={404}/>,
				handle: {displayCrumb: false}
			}
		]
	},
	{
		path: "/splash",
		element: <Splash/>
	},
	{
		path: "/validate",
		handle: {displayCrumb: false},
		children: [
			{
				index: true,
				element: <RedirectExternal to={PAGES.SSO_LOGIN} isSSO={true} />,
				handle: {displayCrumb: false}
			},
			{
				path: ":initialToken",
				element: <SSOValidation/>,
				handle: {displayCrumb: false}
			},
		]
	},
	{
		index: true,
		element: <Navigate to={PAGES.DEFAULT_LANDING}/>,
	},
	{
		path: "*",
		element: <Splash/>
	}
])

const App: React.FC = () => (
	<AppContextProvider>
		<RouterProvider router={router} />
	</AppContextProvider>
);

export default App;

