import React from 'react'
import './index.scss';
import LoadingIndicator from "../LoadingIndicator";
import Section from "../../Basic/Section";

interface Props {
	id: string,
	text?: string
}

const LoadingSection: React.FC<Props> = ({ id, text}) => {
	return (
		<Section id={id} className={'loading-section-placeholder'}>
			{text ? <p>{text}</p> : <LoadingIndicator/>}
		</Section>
	)
}

export default LoadingSection
