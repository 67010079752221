import React from 'react'

import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import Section from "../../components/Basic/Section";

interface ErrorPageProps {
	pageTitle?: string
	errorNumber?: number
}

// TODO output cart information
const ErrorPage: React.FC<ErrorPageProps> = ({pageTitle, errorNumber}) => {
	return (
		<PageWrapper id={'cart-page'} title={pageTitle ? pageTitle : "Your ErrorPage"}>
			<Section id={'cart'}>
				<p>
					ErrorPage {errorNumber ? errorNumber : 'Generic'}
				</p>
			</Section>
		</PageWrapper>
	)
}
export default ErrorPage
