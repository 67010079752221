import {get_browser} from "./platform";

export function isElementInViewport (el: HTMLElement) {

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom * .75 <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

/**
 *
 * @purpose Get the body
 *
 * Most browsers use document.documentElement (the HTML tag) for scrolling
 * Edge and Safari use document.body (the body tag)
 *
 *
 * @return HTMLBodyElement the body element
 *
 **/
export function ec_get_body() {

    // Get the width of the body
    let body = document.documentElement;
    let browser = get_browser();

    if ( browser === 'safari' || browser === 'edge' ) {
        body = document.body;
    }

    return body;
}

/**
 *
 * @purpose Get the current body scroll position
 *
 * @return number
 *
 **/
export function ec_get_body_scroll() {

    // Checking browser compatibility for scroll position stuff
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = ( ( document.compatMode || '' ) === 'CSS1Compat' );

    // Get the scroll postiion
    return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
}
