import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {Dispatch, PropsWithChildren, SetStateAction, useEffect, useState} from 'react';
import Category from '../models/category';
import {mergeBrandOptions} from "../models/brand-option";
import Brand from "../models/brand";
import {connect} from "../data/connect";

/**
 * The state interface for our state
 */
export interface ParentCategoriesContextState extends BasePaginatedContextState<Category> {}

function createDefaultState(): ParentCategoriesContextState {
    return {
        ...defaultBaseContext(),
        filter: {
            'parent_id': 'null',
            'published_at': 'notnull'
        },
        expands: [
            'brandOptions',
            'brands',
        ],
        order: {
            'published_at': 'asc'
        },
        limit: 100
    }
}

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = createDefaultState();
let parentCategorySubscriptions: {[key: string]: Dispatch<SetStateAction<ParentCategoriesContextState>>} = {};

/**
 * The actual context component
 */
export const ParentCategoriesContext = React.createContext<ParentCategoriesContextState>(createDefaultState());

interface OwnProps extends PropsWithChildren<BasePaginatedContextProviderProps> {
}
interface StateProps {
    brand: Brand
}

export interface ParentCategoriesContextProviderProps extends StateProps, OwnProps{
}

const ParentCategoriesContextProvider: React.FC<PropsWithChildren<ParentCategoriesContextProviderProps>> = (props => {
    const [parentCategoriesState, setParentCategoriesState] = useState({...persistentContext});
    const [parentCategories, setParentCategories] = useState<Category[]>([])
    const [instanceKey, _] = useState(Math.random() + "-" + Date.now());
    const {brand} = {...props}

    const setPersistedState = (newState: ParentCategoriesContextState) => {
        persistentContext = newState;
        Object.keys(parentCategorySubscriptions).forEach(key => {
            if (key !== instanceKey) {
                parentCategorySubscriptions[key](persistentContext)
            }
        });
    }
    const updateContext = (newState: ParentCategoriesContextState) => {
        const loadedProductsForBrand = [...newState.loadedData].filter( (category: Category) => category.brands?.find(categoryBrand => categoryBrand.id === brand.id))
        const brandedCategories = loadedProductsForBrand.map( product => mergeBrandOptions(product, brand))

        setParentCategories(brandedCategories)
        setParentCategoriesState(newState)
    }

    useEffect(() => {
        if (parentCategoriesState.initialLoadComplete && parentCategoriesState != persistentContext) {
            setPersistedState(parentCategoriesState)
            updateContext(parentCategoriesState)
        }
    }, [parentCategoriesState])

    useEffect(() => {
        updateContext(parentCategoriesState)
    }, [brand])

    useEffect(() => {
        parentCategorySubscriptions[instanceKey] = setParentCategoriesState;

        return () => {
            delete parentCategorySubscriptions[instanceKey];
        }
    }, []);

    useEffect(() => {
        prepareContextState(setParentCategoriesState, parentCategoriesState, '/categories')
    }, []);

    const fullContext = {
        ...parentCategoriesState,
        ...createCallbacks(setParentCategoriesState, parentCategoriesState, '/categories')
    }

    return (
        <ParentCategoriesContext.Provider value={{...fullContext, loadedData: parentCategories}}>
            <ParentCategoriesContext.Consumer>
                {context => context.initialLoadComplete && context.loadedData ? (
                    props.children
                ) : null}
            </ParentCategoriesContext.Consumer>
        </ParentCategoriesContext.Provider>
    )
});

export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: ParentCategoriesContextProvider
});
