import React, {useEffect, useRef, useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AuthRequests from '../../services/requests/AuthRequests';
import InputWrapper from '../../components/FormComponents/InputWrapper';
import './index.scss';
import {useNavigate, useParams} from 'react-router-dom';
import Button from "../../components/Basic/Button";
import AuthenticationPage from "../../components/Authentication/AuthenticationPage";
import Col from "../../components/Basic/Col";
import {BrandRootParams} from "../../models/brand";
import SignInContextProvider, {
	SignInContext,
	SignInContextStateConsumer,
	SignInData
} from "../../contexts/signin/SignInContext";
import ServerAlert from "../../components/ServerAlert";
import {RequestError} from "../../models/request-error";
import {PAGES} from "../../config";

interface SignInContentProps {
	setValidationError: (error: string) => void,
	signInContext: SignInContextStateConsumer,
	onSubmit: (data: any) => void
}
const SignInContent: React.FC<SignInContentProps> = ({onSubmit, setValidationError, signInContext}) => {
	const emailInputRef = useRef<HTMLInputElement>(null)
	const passwordInputRef = useRef<HTMLInputElement>(null)
	const SignupSchema = Yup.object().shape({
		email: Yup.string().required('Email is required'),
		password: Yup.string().required('Password is required')
	})

	const inputs = [
		emailInputRef?.current,
		passwordInputRef?.current,
	].filter(i => i)as HTMLInputElement[]

	const validateForm = (submission: any) : string|undefined =>  {
		if (submission.errors.email && submission.touched.email) {
			return submission.errors.email
		}
		else if (submission.errors.password && submission.touched.password) {
			return submission.errors.password;
		}
		return undefined
	}

	const form = useFormik({
		initialValues: signInContext.data,
		initialErrors: {email: signInContext.data.email},
		validationSchema: SignupSchema,
		onSubmit: onSubmit
	})

	useEffect(() => {
		if (!form.isValid) {

			const currentError = validateForm(form);

			setValidationError(currentError ? currentError : '');
		}
	}, [form, form.isSubmitting, form.isValid, setValidationError])

	useEffect(() => {
		if (inputs) {

			const enterPressed = (event: any) => {
				if (event.keyCode === 13 && !form.isSubmitting) {
					form.handleSubmit();
				}
			}

			inputs.forEach((i: any) => {
				i.removeEventListener('keydown', enterPressed);
				i.addEventListener('keydown', enterPressed)
			})
		}
	},  [form, inputs])

	return (
		<form>
			<Col flex colWidth={6}>
				<InputWrapper label={''} error={form.errors.email && form.touched.email ? form.errors.email : undefined}>
					<input
						type='text'
						name='email'
						placeholder={'Email'}
						autoComplete={'username'}
						onChange={form.handleChange}
						ref={emailInputRef}
					/>
				</InputWrapper>
			</Col>
			<Col flex colWidth={6}>
				<InputWrapper label='' error={form.errors.password && form.touched.password ? form.errors.password : undefined}>
					<input
						type='password'
						name='password'
						placeholder='Password'
						autoComplete={'current-password'}
						onChange={form.handleChange}
						ref={passwordInputRef}
					/>
				</InputWrapper>
			</Col>
			<Button
				buttonColor={'main'}
				className={"submit" + (form && form.isValid ? ' valid' : '')}
				onClick={() => form.handleSubmit()}>
				Sign In
			</Button>
		</form>
	)
}

interface SignInProps { }

const SignIn: React.FC<SignInProps> = () => {
	const [submissionState, setSubmissionState] = useState(false)
	const [validationError, setValidationError] = useState('');
	const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);
	const navigate = useNavigate();
	const {brand}  = useParams<BrandRootParams>();

	const onSubmit = (formData: SignInData, setData: (data: SignInData) => void) => {
		setData({'email': formData.email, password: ''})
		if (formData.email && !submissionState) {
			setSubmissionState(true)
			processSignIn(formData).then(() => {
				setSubmissionState(false)
			}).catch((error : any) => {
				console.log( error)
			})
		}
	}

	const processSignIn = async (signInData: SignInData) => {
		try {
			await AuthRequests.signIn(signInData)
			navigate(`/${brand}` + PAGES.AUTHORIZED_LANDING)
		} catch(error: any) {
			console.log(error)
			if (error && error.status && error.status === 401) {
				setValidationError('Login Invalid');
			}
		}
	}

	return (
		<AuthenticationPage slug={'sign-in'} title={"Sign-In"} validationError={validationError} isSubmitting={submissionState}>
			<SignInContextProvider>
				<SignInContext.Consumer>
					{signInContext => (
						<React.Fragment>
							<SignInContent
								setValidationError={setValidationError}
								signInContext={signInContext}
								onSubmit={(data: any) => onSubmit(data, signInContext.setData)}
							/>
							{requestError &&
								<ServerAlert
									requestError={requestError}
									onCloseAlert={() => setRequestError(undefined)}
								/>
							}
						</React.Fragment>
					)}
				</SignInContext.Consumer>
			</SignInContextProvider>
		</AuthenticationPage>
	)
}

export default SignIn
