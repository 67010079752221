import BaseModel from '../base-model';
import OrderProduct from "./order-product";
import Product from "../product";

/**
 * The interface for what our Orders will look like
 */
export default interface Order extends BaseModel {

    /**
     * When the order was submitted
     */
    submitted_at: string;

    /**
     * When the order was shipped
     */
    shipped_at?: string;

    /**
     * When the order was delivered
     */
    delivered_at?: string;

    /**
     * When the order was cancelled
     */
    canceled_at?: string;

    /**
     * email associated with the order
     */
    order_email: string;

    /**
     * phone associated with the order
     */
    order_phone: string;

    /**
     * fax associated with the order
     */
    order_fax: string;

    /**
     * name associated with the order
     */
    shipping_name: string;

    /**
     * email associated with the shipping
     */
    shipping_email: string;

    /**
     * phone associated with the shipping
     */
    shipping_phone: string;
    /**
     * fax associated with the shipping
     */
    shipping_fax: string;

    /**
     * address line 1 associated with the shipping
     */
    shipping_address_1: string;

    /**
     * address line 2 associated with the shipping
     */
    shipping_address_2: string;

    /**
     * city associated with the shipping
     */
    shipping_city: string;

    /**
     * country associated with the shipping
     */
    shipping_country: string;

    /**
     * ZIP associated with the shipping
     */
    shipping_zip: string;

    /**
     * name associated with the billing
     */
    billing_name?: string;

    /**
     * email associated with the billing
     */
    billing_email?: string;

    /**
     * phone associated with the billing
     */
    billing_phone?: string;

    /**
     * fax associated with the billing
     */
    billing_fax?: string;

    /**
     * address line 1 associated with the billing
     */
    billing_address_1?: string;

    /**
     * address line 2 associated with the billing
     */
    billing_address_2?: string;

    /**
     * address line 3 associated with the billing
     */
    billing_address_3?: string;

    /**
     * city associated with the billing
     */
    billing_city?: string;

    /**
     * country associated with the billing
     */
    billing_country?: string;

    /**
     * ZIP associated with the billing
     */
    billing_zip?: string;

    /**
     * is the shipping address the same as billing address?
     */
    shipping_address_same_as_billing?: number

    /**
     * additional information added to the order
     */
    info?: string;

    /**
     * cost of the order
     */
    total_price: number;

    /**
     * products within the order
     */
    order_products: OrderProduct[];

    /**
     * Defines the type of the object
     */
    [key: string]: any;
}

interface ProductInOrder {
    orderProduct: OrderProduct
    index: number
}

interface OrderObject {
    order: Order,
    orderProduct: OrderProduct|null
}

/**
 * Outputs an empty Product object
 */
export function generateEmptyOrder(): Order {
    return {
        id: 0,
        total_price: 0,
        order_email: "",
        order_fax: "",
        order_phone: "",
        shipping_address_1: "",
        shipping_address_2: "",
        shipping_city: "",
        shipping_country: "",
        shipping_email: "",
        shipping_fax: "",
        shipping_name: "",
        shipping_phone: "",
        shipping_zip: "",
        submitted_at: "",
        order_products: [],
    }
}

/**
 * @purpose Determine the status of an order
 *
 * @param order the current order
 *
 * @return The status of the order
 */
export const getOrderStatus = (order: Order) => {
    let status = null
    if(order.canceled_at){
        status = 'Cancelled'
    } else if(order.delivered_at){
        status = 'Delivered'
    } else if(order.shipped_at) {
        status = 'Shipped'
    } else if(order.submitted_at){
        status = 'Pending'
    }
    return status
}

/**
 * @purpose Find a product on an order
 *
 * @param order the current order
 * @param product the product to find
 *
 * @return ProductInOrder the product and the index of the product in the order for easy updating
 */
export function findOrderProductInOrder(order: Order, product: Product): ProductInOrder|false {
    let index = null as null|number
    const foundOrderProduct = order.order_products.find( (orderProduct, orderProductIndex) => {
        let retVal = false
        if (orderProduct.product.id === product.id) {
            retVal = true
            index = orderProductIndex
        }
        return retVal
    })
    return foundOrderProduct && index !== null ? {orderProduct: foundOrderProduct, index: index} : false
}

/**
 * @purpose change the quantity of an order product for a specific order
 *
 * @param order the current order
 * @param productInOrder the orderProduct + index object
 * @param quantity the amount of the product requested
 *
 * @return Order the updated order
 */
function changeOrderProductQuantity(order: Order, productInOrder: ProductInOrder, quantity: number) {

    // If they want none or fewer of a product, remove from order
    if (quantity <= 0) {
        order = removeProductFromOrder(order, productInOrder.orderProduct.product)
    }
    // Else update the quantity
    else {
        productInOrder.orderProduct.quantity = quantity

        // Update order product and total price
        order.order_products[productInOrder.index] = productInOrder.orderProduct
        order.total_price = calculateTotalPriceOfOrder(order.order_products)
    }

    return order
}

/**
 * @purpose Add a product to an order
 *
 * @param order the current order
 * @param product the product to add
 * @param quantity optional, increases by 1 if not provided
 *
 * @return Order the updated order
 */
export function addProductToOrder(order: Order, product: Product, quantity?: number): OrderObject {
    // Find the current orderProduct, if exists
    const productInOrder = findOrderProductInOrder(order, product)

    // Add 1 of product if no quantity provided
    let determinedQuantity = (quantity || 1)
    const orderObject = {
        order: order,
        orderProduct: {product: product, quantity: determinedQuantity, price: product.cost_dealer}
    }

    // If the product is already in the order, add the requested quantity to the current quantity and update
    if (productInOrder) {
        determinedQuantity += productInOrder.orderProduct.quantity
        orderObject.orderProduct.quantity = determinedQuantity

        // Update order quantity
       changeOrderProductQuantity(order, productInOrder, determinedQuantity)
    }
    // Else, add new order product
    else {
        order.order_products.push(orderObject.orderProduct)
        order.total_price = calculateTotalPriceOfOrder(order.order_products)
    }

    return orderObject
}

/**
 * @purpose Update the quantity of a product for a specific order
 *
 * @param order the current order
 * @param product the orderProduct + index object
 * @param quantity the amount of the product requested
 *
 * @return Order the updated order
 */
export function updateProductQuantity(order: Order, product: Product, quantity: number): OrderObject {
    // Find the current orderProduct, if exists
    const productInOrder = findOrderProductInOrder(order, product)
    const orderObject = {
        order: order,
        orderProduct: productInOrder ? productInOrder.orderProduct : null
    }

    // If the product is already in the order
    if (productInOrder) {

        // Update order quantity
       changeOrderProductQuantity(order, productInOrder, quantity)
    }
    // Else warn we are trying to update a product that is not yet in the order
    else {
        console.warn("trying to update the quantity of \"" + product.title + "\", but it is not currently a part of the order")
    }

    return orderObject
}

/**
 * @purpose Remove a product from an order
 *
 * @param order the current order
 * @param product the product to add
 *
 * @return Order the updated order
 */
export function removeProductFromOrder(order: Order, product: Product): Order {
    // Find the current orderProduct, if exists
    const productInOrder = findOrderProductInOrder(order, product)

    // If the product is already in the order, update the quantity, else add new order product
    if (productInOrder) {

        // Remove product and reduce total price of order
        order.order_products.splice(productInOrder.index, 1)
        order.total_price = calculateTotalPriceOfOrder(order.order_products)
    }
    // Else warn we are trying to remove a product that is not yet in the order
    else {
        console.warn("trying to remove \"" + product.title + "\", but it is not currently a part of the order")
    }

    return order
}

/**
 * @purpose Calculate the number of items in an order
 *
 * @param orderProducts the products within an order
 *
 * @return The number of total items in an order
 */
export const calculateNumOfItemsInOrder = (orderProducts: OrderProduct[]) => {
    return orderProducts.reduce((sum, product) => sum + product.quantity, 0)
}

/**
 * @purpose Calculate the total cost of an order
 *
 * @param orderProducts the products within an order
 *
 * @return The total cost of an order
 */
export const calculateTotalPriceOfOrder = (orderProducts: OrderProduct[]): number => {
    return orderProducts.reduce((sum, product) => sum + (product.price * product.quantity), 0)
}
