import BaseModel from './base-model';
import ProductAsset from "./productAsset";
import BrandOption from "./brand-option";
import Brand from "./brand";
import Category from "./category";

/**
 * The interface for what we can expect our category to look like
 */
export default interface Product extends BaseModel {

    /**
     * The name of the product
     */
    title: string;

    /**
     * The product URL slug
     */
    slug: string;

    /**
     * The product SKU
     */
    sku: string;

    /**
     * The product URL
     */
    url: string;

    /**
     * The dealer product price
     */
    cost_dealer: number;

    /**
     * is the product free
     */
    free: boolean;

    /**
     * The should the product be featured on the home page?
     */
    featured?: boolean;

    /**
     * is the product out of production
     */
    out_of_production: boolean;

    /**
     * The product sale price
     */
    cost_sale?: number;

    /**
     * Can the product be returned?
     */
    return?: boolean;

    /**
     * The product disclosure
     */
    excerpt?: string;

    /**
     * The product features
     */
    feature?: string;

    /**
     * The product specifications
     */
    specification?: string;

    /**
     * The product description
     */
    body?: string;

    /**
     * The product assets
     */
    assets?: ProductAsset[]

    /**
     * The product's related products
     */
    related_products?: Product[]

    /**
     * The product's brand options
     */
    brand_options?: BrandOption[]

    /**
     * The product's brands
     */
    brands?: Brand[]

    /**
     * The product's related categories
     */
    categories?: Category[]
}

/**
 * Outputs an empty Product object
 */
export function generateEmptyProduct(): Product {
    return {
        title: "",
        slug: "",
        sku: "",
        url: "",
        cost_dealer: 0,
        free: false,
        out_of_production: false,
    }
}

export type ProductParams = { product: string }
