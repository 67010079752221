import {useEffect, useState} from "react";

/**
 * Function that watches the size of a window and return the width
 */
export const useViewportWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleResize)

        return () => window.removeEventListener("resize", handleResize)
    }, [width, setWidth])

    return width
}
