import React, {PropsWithChildren, useEffect, useState} from 'react';
import Category, {generateEmptyCategory} from '../models/category';
import CategoryRequests from '../services/requests/CategoryRequests';
import LoadingScreen from '../components/Loaders/LoadingScreen';
import ErrorPage from "../pages/ErrorPage";
import Brand from "../models/brand";
import {connect} from "../data/connect";
import {mergeBrandOptions} from "../models/brand-option";

type categoryCache =  {
    [key: string]: Category|undefined;
};
let cachedCategories: categoryCache = {};

/**
 * The structure of the consumer
 */
export interface CategoryContextConsumerState {
    hasLoaded: boolean,
    notFound: boolean,
    category?: Category,
    setCategory: (category: Category) => void,
}

let defaultContext: CategoryContextConsumerState = {
    hasLoaded: false,
    notFound: false,
    category: generateEmptyCategory(),
    setCategory: (category: Category) => {}
};

export const CategoryContext = React.createContext<CategoryContextConsumerState>(defaultContext);

interface OwnProps {
    categorySlug: string,
    skipCache?: boolean,
}

interface StateProps {
    brand: Brand
}

export interface CategoryContextProviderProps extends OwnProps, StateProps {
}

const CategoryContextProvider: React.FC<PropsWithChildren<CategoryContextProviderProps>> = ({categorySlug, skipCache, brand, children}) => {
    const [categoryState, setCategoryState] = useState(defaultContext);

    const setAndBrandCategory = (categoryContext: CategoryContextConsumerState) => {
        if(categoryContext.category) {
            // Confirm the category is valid for this brand before output
            let brandedCategory = categoryContext.category.brands?.find(categoryBrand => categoryBrand.id === brand.id) ? mergeBrandOptions(categoryContext.category, brand) : undefined

            if (brandedCategory) {
                let brandedChildren: Category[] = []
                brandedCategory.children?.forEach(child => {
                    if (child.brands?.find(childBrand => childBrand.id === brand.id)) {
                        brandedChildren.push(mergeBrandOptions(child, brand))
                    }
                })

                brandedCategory.children = brandedChildren
            }

            setCategoryState({
                ...categoryContext,
                category: brandedCategory
            })
        }
    }
    const setCategory = (category: Category): void => {
        cachedCategories[category.slug!] = {...category};
        setAndBrandCategory({
            ...categoryState,
            category: category,
        })
    }

    useEffect(() => {
        if (!skipCache && cachedCategories[categorySlug]) {
            setAndBrandCategory({
                hasLoaded: true,
                notFound: false,
                category: cachedCategories[categorySlug],
                setCategory: setCategory,
            });
        } else {
            setAndBrandCategory({
                ...categoryState,
                hasLoaded: false,
            });
            CategoryRequests.getCategory(categorySlug).then(category => {
                cachedCategories[categorySlug] = category;
                setAndBrandCategory({
                    hasLoaded: true,
                    notFound: false,
                    category: category,
                    setCategory,
                });
            }).catch(() => {
                setAndBrandCategory({
                    ...categoryState,
                    hasLoaded: true,
                    notFound: true,
                });
            })
        }
    }, [categorySlug]);

    return (
        <CategoryContext.Provider value={{...categoryState, setCategory}}>
            <CategoryContext.Consumer>
                {context => (context.hasLoaded ?
                    (!context.notFound ? (
                        children
                    ) : (
                        <ErrorPage errorNumber={404}/>
                    )) : (
                        <LoadingScreen/>
                    )
                )}
            </CategoryContext.Consumer>
        </CategoryContext.Provider>
    )
}

export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: CategoryContextProvider
});
