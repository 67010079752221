import React, {PropsWithChildren, useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import Category, {CategorySearchResult, getSubCategoryAncestors} from "../../../models/category";
import Section from "../../Basic/Section";
import ResourceCategory from "../../ResourceCategory";

interface CategoryListProps {
    id: string,
    category?: Category,
    ancestry?: string,
    categories?: CategorySearchResult[],
}

const CategoryList: React.FC<PropsWithChildren<CategoryListProps>> = ({id, category, ancestry, categories, children}) => {
    const [ancestorsState, setAncestorsState] = useState<Category[]>([])

    useEffect(() => {
        if(ancestry) {
            getSubCategoryAncestors(ancestry).then(ancestors => {
                setAncestorsState(ancestors)
            })
        }
    }, [ancestry])

    return (
        <Section id={id} className={'category-list'}>
            {children}
            <div className={'list-wrapper'}>
                {categories ? (
                    categories.map((category, index) => {
                        return (<ResourceCategory key={index} category={category.category} ancestors={category.ancestors} />)
                    })
                ) : (
                    category?.children?.map((subCategory, index) => {
                        return (
                            <ResourceCategory key={index} category={subCategory} ancestors={ancestorsState}/>
                        )
                    })
                )}
            </div>
        </Section>
    )
}

export default CategoryList
