import React, {useEffect, useState} from 'react'

import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import Section from "../../components/Basic/Section";
import {useMatches, useParams} from "react-router-dom";
import OrderContextProvider, {OrderContext} from "../../contexts/OrderContext";
import Table from "../../components/Table";
import OrderProduct from "../../models/order/order-product";
import SingleOrder, {calculateTotalPriceOfOrder} from "../../models/order/order";
import Col from "../../components/Basic/Col";
import {buildAddressPrefixes, buildContactPrefixes} from "../../models/form";
import {formatPhoneNumber, formatPrice} from "../../util/regex";
import {buildBreadcrumbs} from "../../util/navigation";
import Breadcrumbs from "../../components/Breadcrumbs";
import PageLink from "../../models/link";
import {PossibleBrands} from "../../config";
import {BrandRootParams} from "../../models/brand";
import Img from "../../components/Basic/Img";
import {imageProps} from "../../util/image";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import {StringKeyObject} from "../../util/form";

/***** ORDER PRODUCTS TABLE ****/
interface OrderProductsTableProps {
	data: OrderProduct[],
	brand: PossibleBrands,
	features: StringKeyObject
	className?: string
}

const OrderProductsTableRows: React.FC<OrderProductsTableProps> = ({data, brand, features, className}) => {
	return (
		<React.Fragment>
			{data.map((product, index) => {
				const featureImage = product.product.assets?.find((asset) => asset.type === 'feature')
				const featureImageProps = imageProps(brand, featureImage, product.product.title)

				return (
					<tr key={index} className={className}>
						<td className={'img-cell'}><Img {...featureImageProps}/>
						</td>
						<td>
							<p className={'title'}>{product.product.title}</p>
							<p className={'sku'}><span className={'label'}>{`${features['sku-identifier']} `}</span><span>{product.product.sku}</span></p>
							{product.product.return ?
								(features['return-disclaimer'] ? <p className={'disclaimer'}>{features['return-disclaimer']}</p> : '') : null}
						</td>
						<td><p>{product.quantity}</p></td>
						<td><p dangerouslySetInnerHTML={{__html: formatPrice(product.price)}}/></td>
						<td><p className={'bold base'} dangerouslySetInnerHTML={{__html: formatPrice(product.quantity * product.price)}} />
						</td>
					</tr>
				)
			})}
		</React.Fragment>
	)
}

/****** ORDER INFORMATION *****/
interface OrderInformationProps{
	order: SingleOrder,
	prefix: string
	headline?: string,
	isContactInfo?: boolean
}

const OrderInformation: React.FC<OrderInformationProps> = ({order, prefix, headline, isContactInfo = true}) => {
	let prefixes = {} as StringKeyObject
	let features = [] as string[]

	if(isContactInfo){
		prefixes = buildContactPrefixes(prefix)
		features = ['name', 'email', 'phone', 'fax']
	}
	else {
		prefixes = buildAddressPrefixes(prefix)
		features = ['address_1', 'address_2', 'city', 'company', 'state', 'country', 'zip']
	}

	const sectionContent = (features: {[key:string]: string}) => {
		return Object.keys(prefixes).map((key, index) => {
			let dataPoint = order[prefixes[key]] ? order[prefixes[key]] : '';
			let dataContent = null;

			if(dataPoint) {
				switch (key) {
					case 'phone':
						dataContent = <a href={`tel:${dataPoint}`}>{formatPhoneNumber(dataPoint)}</a>
						break
					case 'email':
						dataContent = <a href={`mailto:${dataPoint}`}>{dataPoint}</a>
						break
					default:
						dataContent = <span>{dataPoint}</span>
				}
			}

			return (key !== 'first_name' && key !== 'last_name') ? (
				<p key={index} className={prefix + '-detail'}>
					<span className={'label'}>{features[key]}</span>
					{dataContent}
				</p>
			) : null
		})
	}

	return (
		<StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()}>
			<StaticFeatureContext.Consumer>
				{context => (
					<div className={prefix + '-info'}>
						{headline ? <p className={prefix + '-section-title'}>{headline}</p> : null}
						<div className={prefix + '-content'}>
							{sectionContent(context.features)}
						</div>
					</div>
				)}
			</StaticFeatureContext.Consumer>
		</StaticFeatureContextProvider>
	)
}

/***** ORDER PAGE *****/
interface OrderContentProps {
	order: SingleOrder,
	brand: PossibleBrands,
	setPageTitle: (title: string) => void
}

const OrderContent: React.FC<OrderContentProps> = ({order, brand, setPageTitle}) => {
	const billingContextPrefix = order.shipping_address_same_as_billing ? 'shipping' : 'billing'
	const orderTableColumnFeatures = ['image-label', 'details-label', 'quantity-abbreviation-label', 'price-label', 'total-price-label']
	const formFeatures = ['individual-label', 'shipping-contact', 'shipping-address', 'billing-contact', 'billing-address', 'additional-info']
	const orderProductRowFeatures = ['return-disclaimer', 'sku-identifier']
	const allFeatures = formFeatures.concat(orderTableColumnFeatures, orderProductRowFeatures)

	useEffect(() => {
		setPageTitle('Order #' + order.id)
	}, [order.id]);

	const buildFeatureLabelObject = (featureObject: StringKeyObject, featureNames: string[]) => {
		const featureLabels = [] as string[]
		featureNames.forEach(name => {
			if(featureObject.hasOwnProperty(name)){
				featureLabels.push(featureObject[name])
			}
		})
		return featureLabels
	}

	return (
		<StaticFeatureContextProvider featureNames={allFeatures} featuresRequestKey={allFeatures.join()}>
			<StaticFeatureContext.Consumer>
				{context => {
					const features = (context.features)
					const orderTableColumns = buildFeatureLabelObject(features, orderTableColumnFeatures)

					return (
						<React.Fragment>
							<Section id={'order-header'}>
								<h5>Order #{order.id}</h5>
								<div className={'order-date'}>
									<p className={'order-date-header'}>Order Date: </p>
									<p>{order.submitted_at}</p>
								</div>
							</Section>
							<Table id={'order-table'} colNames={orderTableColumns} totalFooter={calculateTotalPriceOfOrder(order.order_products)}>
								<OrderProductsTableRows data={order.order_products} brand={brand!} features={features}/>
							</Table>
							<Section id={'order-data'}>
								<Col colWidth={6}>
									<OrderInformation order={order} prefix={'order'} headline={features['individual-label']}/>
									<OrderInformation order={order} prefix={'shipping'} headline={features['shipping-contact']}/>
									<OrderInformation order={order} prefix={'shipping'} headline={features['shipping-address']} isContactInfo={false}/>
								</Col>
								<Col colWidth={6}>
									<div className={'additional-info'}>
										{features['additional-info'] ? <p className={'additional-info-section-title'}>{features['additional-info']}</p> : ''}
										<p className={'additional-info-content'}>{order.info}</p>
									</div>
									<OrderInformation order={order} prefix={billingContextPrefix} headline={features['billing-contact']}/>
									<OrderInformation order={order} prefix={billingContextPrefix} headline={features['billing-address']} isContactInfo={false}/>
								</Col>
							</Section>
						</React.Fragment>
					)
				}}
			</StaticFeatureContext.Consumer>
		</StaticFeatureContextProvider>
	)
}


/***** ORDER PAGE *****/
interface OrderProps {
}

const Order: React.FC<OrderProps> = () => {
	const {brand}  = useParams<BrandRootParams>();
	const {orderId} = useParams()
	const matches = useMatches()
	const [orderBreadcrumbs, setOrderBreadcrumbs] = useState<PageLink[]>([])
	const [pageTitle, setPageTitle] = useState('ORDER NAME')

	useEffect(() => {
		if(matches) {
			buildBreadcrumbs(matches, brand).then(breadcrumbs => {
				setOrderBreadcrumbs(breadcrumbs)
			})
		}
	}, [matches]);

	return (
		<PageWrapper id={'order-page'} title={pageTitle}>
			<Breadcrumbs id={'single-order-crumbs'} breadcrumbs={orderBreadcrumbs} />
			<Section id={'order-page-headline'}>
				<h3 className={'order-page-title'}>Orders</h3>
				<hr/>
			</Section>
			{orderId ? (
				<OrderContextProvider orderId={Number(orderId)}>
					<OrderContext.Consumer>
						{context => (<OrderContent order={context.order} brand={brand!} setPageTitle={setPageTitle}/>)}
					</OrderContext.Consumer>
				</OrderContextProvider>
			) : null}
		</PageWrapper>
	)
}
export default Order
