import React from 'react'
import './index.scss';
import LoadingIndicator from "../LoadingIndicator";
import PageWrapper from "../../Basic/PageWrapper";

interface Props {
	text?: string
}

const LoadingScreen: React.FC<Props> = ({  text }) => {
	return (
		<PageWrapper id={'loading-screen-wrapper'} title={'Loading...'}>
			<div id={'loading-screen'}>
				<div id={'loading-wrapper'}>
					{text ? <p>{text}</p> : <LoadingIndicator/>}
				</div>
			</div>
		</PageWrapper>
	)
}

export default LoadingScreen
