import React, {useEffect, useState} from 'react'
import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import MyCartSection from "../../components/PageSections/MyCart";
import {useLocation, useParams} from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import {buildBreadcrumbs} from "../../util/navigation";
import RecommendationsSection from "../../components/PageSections/RecommendationsSection";
import {BrandRootParams} from "../../models/brand";
import PageLink from "../../models/link";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import LoadingSection from "../../components/Loaders/LoadingSection";
import MeContextProvider, {MeContext} from "../../contexts/MeContext";
import CurrentOrderContextProvider, {CurrentOrderContext} from "../../contexts/CurrentOrderContext";
import ProductContextProvider, {ProductContext} from "../../contexts/ProductContext";
import Order from "../../models/order/order";
import Product from "../../models/product";

interface CartRecommendedContentProps {
	order: Order
	title: string
}

const CartRecommendedContent: React.FC<CartRecommendedContentProps> = ({order, title}) => {
	const [mostExpensiveOrderedProduct, setMostExpensiveOrderedProduct] = useState<Product|undefined>(undefined)

	useEffect(() => {
		const sortedByPrice = [...order.order_products].sort((a, b) => a.price > b.price ? 1 : -1)
		const mostExpensive = sortedByPrice.pop()?.product
		setMostExpensiveOrderedProduct(mostExpensive)

	}, [order.total_price])

	return mostExpensiveOrderedProduct ? (
		<ProductContextProvider productSlug={mostExpensiveOrderedProduct?.slug}>
			<ProductContext.Consumer>
				{ context => context.hasLoaded && context.product ? (
					<RecommendationsSection id={'cart-recommendation'} title={title} productBackgroundColor={'white'} products={context.product.related_products?.slice(0,3)} />
				) : null}
			</ProductContext.Consumer>
		</ProductContextProvider>
	) : null
}

interface CartProps {
}

const Cart: React.FC<CartProps> = () => {
	const {state} = useLocation()
	const {brand} = useParams<BrandRootParams>()
	const [cartBreadcrumbs, setCartBreadcrumbs] = useState<PageLink[]>([])

	useEffect(() => {
		if(state) {
			buildBreadcrumbs(state.matches, brand).then(breadcrumbs => {
				setCartBreadcrumbs(breadcrumbs)
			})
		}
	}, [state]);

	return (
		<PageWrapper id={'cart-page'} title={"Your Cart"}>
			<Breadcrumbs id={'cart-breadcrumbs'} breadcrumbs={cartBreadcrumbs} />
			<MyCartSection id={'owner-1-cart'} />
			<StaticFeatureContextProvider featureNames={['recommendation-headline']} featuresRequestKey={'recommendation-headline'} hideLoader={true}>
				<StaticFeatureContext.Consumer>
					{context => context.hasLoaded ? (
						<MeContextProvider>
							<MeContext.Consumer>
								{contextMe => (
									<CurrentOrderContextProvider organization_id={contextMe.me.organization_id}>
										<CurrentOrderContext.Consumer>
											{orderContext => (
												<CartRecommendedContent order={orderContext.order} title={context.features['recommendation-headline']} />
											)}
										</CurrentOrderContext.Consumer>
									</CurrentOrderContextProvider>
								)}
							</MeContext.Consumer>
						</MeContextProvider>

					) : <LoadingSection id={'cart-recommendations-loading'}/>}
				</StaticFeatureContext.Consumer>
			</StaticFeatureContextProvider>
		</PageWrapper>
	)
}
export default Cart
