import React, {HTMLAttributes, useEffect, useState} from 'react'
import './index.scss';
import './responsive.scss';
import InputWrapper from "../InputWrapper";
import PhoneNumberInput from "../PhoneNumberInput";

interface RequiredInputProps extends HTMLAttributes<HTMLInputElement>{
    name: string,
    value: string,
    error: string | undefined,
    onRequiredInputChange: (name : string, value : string) => void,
    options?: string[],
    type?: string,
    placeholder?: string,
    wrapperLabel?: string
}

const RequiredInput: React.FC<RequiredInputProps> = ({name, value, placeholder, error, onRequiredInputChange, options, type = 'text', wrapperLabel, className, ...rest }) => {
    const [required, setRequired] = useState(true)
    const classes = (required ? 'required' : '') + (className ? ' ' + className : '')

    useEffect( () => {
        if (!required && !value ) {
            setRequired(true)
        }
        else if (required && value?.length) {
            setRequired(false)
        }
    }, [value])

    let InputTag : any  = 'input'
    let InputChildren : any = undefined

    let inputProps = {
        name: name,
        value: value ? value : '',
        ...rest
    } as any

    if(type === 'tel'){
        inputProps.onPhoneNumberChange = ((phoneNumber :  string) => onRequiredInputChange(name, phoneNumber))
        inputProps.placeholder = placeholder
        InputTag = PhoneNumberInput
    }
    else {
        inputProps.onChange = ((event : any) => onRequiredInputChange(name, event.target.value))
        inputProps.type = type
        if(type === 'select') {
            InputChildren = options ? options.map((option, index) => {
                return <option key={index}>{option}</option>
            }) : []
        }
        else {
            inputProps.placeholder = placeholder
        }
    }

    return (
        <InputWrapper label={wrapperLabel ? wrapperLabel : ''} className={classes} error={error}>
            {InputChildren ? (
                <select {...inputProps}>
                    <option disabled value={''}>{placeholder}</option>
                    {InputChildren}
                </select>
            ) : (
                <InputTag {...inputProps} />
            )}
        </InputWrapper>
    )
}

export default RequiredInput
