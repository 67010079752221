import React, {ForwardedRef, forwardRef, HTMLAttributes} from 'react';
import {useNavigate} from "react-router-dom";


export interface HTMLComponentProps extends HTMLAttributes<HTMLOrSVGElement> {
	content: string,
	tag?: keyof HTMLElementTagNameMap;
}

const HTMLComponent = forwardRef<HTMLOrSVGElement, HTMLComponentProps>((props, ref) => {
	const navigate = useNavigate()
	const {content, tag: Wrapper = "div", ...additionalProps} = {...props}
	const contentClickHandler = (e: any) => {
		const targetLink = e.target.closest('a');

		const href = targetLink?.getAttribute('href');

		if (href && href.indexOf('.') === -1) {
			e.preventDefault();
			navigate(href)
		}
	};

	// @ts-ignore – The ref here gets a little hairy because our tag type is variable
	return (<Wrapper ref={ref as ForwardedRef<any>} onClick={contentClickHandler} dangerouslySetInnerHTML={{__html: content}} {...additionalProps} />
	);
});
export default HTMLComponent
