import React from 'react';
import './index.scss';
import './responsive.scss';
import Product from "../../models/product";
import AddToCartButton from "../CartComponents/AddToCartButton";
import DownloadButton from "../DownloadButton";
import QuantitySelector from "../CartComponents/QuantitySelector";
import Button from "../Basic/Button";
import {Link, useParams} from "react-router-dom";
import {PAGES} from "../../config";
import {BrandRootParams} from "../../models/brand";
import HTMLComponent from "../Basic/HTMLComponent";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";

interface ProductUnavailableProps {
    product : Product,
    linkUnavailableBreadcrumbs?: boolean
}

const ProductUnavailable: React.FC<ProductUnavailableProps> = ({product, linkUnavailableBreadcrumbs}) => {
    const {brand}  = useParams<BrandRootParams>();
    const features = ['unavailable-product-button-text', 'unavailable-product-link-text', 'unavailable-product-message']
    return (
        <StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join('-')}>
            <StaticFeatureContext.Consumer>
                {context => (
                    <div className={'unavailable'}>
                        <Button buttonColor={'dk-gray'} type={'button'} disabled>{context.features['unavailable-product-button-text']}</Button>
                        <p className={'action-message'}>
                            {linkUnavailableBreadcrumbs ? (
                                <Link to={'/' + brand + PAGES.PRODUCT_ROOT + '/' + product.slug}  state={{matches: linkUnavailableBreadcrumbs}}>{context.features['unavailable-product-link-text']}</Link>
                            ) : (
                                <HTMLComponent content={context.features['unavailable-product-message']} />
                            )}
                        </p>
                    </div>
                )}
            </StaticFeatureContext.Consumer>
        </StaticFeatureContextProvider>
    )
}

interface ProductActionsProps {
    product : Product,
    quantity? : number,
    linkUnavailableBreadcrumbs? : any,
    onQuantityAdjust? : (amount: number) => void
}

const ProductActions: React.FC<ProductActionsProps> = ({product, quantity, linkUnavailableBreadcrumbs, onQuantityAdjust}) => {
    let Component = null
    if (product.cost_dealer) {
        Component = !product.out_of_production ? (
            <React.Fragment>
                <AddToCartButton product={product}/>
                {quantity && onQuantityAdjust ? (
                    <QuantitySelector
                        product={product}
                        initialQuantity={1}
                        updateOrder={false}
                        onQuantityUpdate={(amount) => onQuantityAdjust(amount)}/>
                ) : null}
            </React.Fragment>

        ) : (
            <ProductUnavailable product={product} linkUnavailableBreadcrumbs={linkUnavailableBreadcrumbs} />
        )
    }
    else {
        const productDownload = product.assets?.find(asset => asset.type === 'pdf')

        if (productDownload) {
            Component = !product.out_of_production ? (
                <DownloadButton fileUrl={productDownload.url} downloadObject={{asset_id: productDownload.id!, product_id: product.id!}}/>
            ) : (
                <ProductUnavailable product={product} linkUnavailableBreadcrumbs={linkUnavailableBreadcrumbs} />
            )
        }
        else if (!product.free) {
            Component = (
                <StaticFeatureContextProvider featureNames={['contact-for-pricing']} featuresRequestKey={'contact-for-pricing'} >
                    <StaticFeatureContext.Consumer>
                        {context => <p className={'action-message'}>{context.features['contact-for-pricing']}</p>}
                    </StaticFeatureContext.Consumer>
                </StaticFeatureContextProvider>

            )
        }
    }

    return Component
}

export default ProductActions
