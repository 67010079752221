import React from 'react';
import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import FAQSection from "../../components/PageSections/FAQSection";
import ContactUsSection from "../../components/PageSections/ContactUsSection";
import BestSellersSection from "../../components/PageSections/BestSellersSection";
import FeaturedCategoriesSection from "../../components/PageSections/FeaturedCategoriesSection";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import {StringKeyObject} from "../../util/form";
import LoadingScreen from "../../components/Loaders/LoadingScreen";
import BestSellingProductsContextProvider, {BestSellingProductsContext} from "../../contexts/BestSellingProductsContext";
import PostContextProvider, {PostContext} from "../../contexts/PostContext";
import BannerSection from "../../components/PageSections/BannerSection";
import Banner from "../../models/banner";

interface HomeContentProps {
	features: StringKeyObject,
	banners?: Banner[]
}

const HomeContent: React.FC<HomeContentProps> = ({features, banners}) => {
	const header_banner = banners?.find(banner => banner.headline === 'Header Banner');
	const footer_banner = banners?.find(banner => banner.headline === 'Footer Banner');
	return (
		<React.Fragment>
			<FeaturedCategoriesSection id={'home-featured-categories'} title={features['shop-by-category-headline']}/>
			{header_banner ? <BannerSection banner={header_banner}/> : null}
			<FAQSection
				title={features['faqs-headline']}
				id={'home-faq'}
				className={'bg-lt-gray'}
			/>
			<ContactUsSection id={'home-contact'} headline={features['contact-us-headline']}/>
			{footer_banner ? <BannerSection banner={footer_banner}/> : null}
			<BestSellingProductsContextProvider>
				<BestSellingProductsContext.Consumer>
					{context => context.hasLoaded ? (
						<BestSellersSection id={'home-best-sellers'} products={context.products} title={features['best-sellers-headline']} productBackgroundColor={'white'} featured={true}/>
					) : null}
				</BestSellingProductsContext.Consumer>
			</BestSellingProductsContextProvider>
		</React.Fragment>
	)
}

interface HomeProps {
}

const Home: React.FC<HomeProps> = () => {
	const features = ['home-page-title', 'shop-by-category-headline', 'faqs-headline', 'contact-us-headline', 'best-sellers-headline']

	return (
		<PageWrapper id={'home-page'}>
			<StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()} hideLoader={true}>
				<StaticFeatureContext.Consumer>
					{context => context.hasLoaded ? (
						<PostContextProvider postSlug={'/'}>
							<PostContext.Consumer>
								{postContext => <HomeContent features={context.features} banners={postContext?.post?.banners} /> }
							</PostContext.Consumer>
						</PostContextProvider>
					) : <LoadingScreen/>}
				</StaticFeatureContext.Consumer>
			</StaticFeatureContextProvider>
		</PageWrapper>
    )
}

export default Home

