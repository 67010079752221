import React, {PropsWithChildren} from "react";
import './index.scss';
import './responsive.scss';

interface CheckboxProps {
    label: string,
    name: string,
    value: string,
    show: boolean,
    setShow: (show: boolean) => void
    onChangeEvent: (name: string, value: string) => void
}

const Checkbox: React.FC<PropsWithChildren<CheckboxProps>> = ({label, name, value, show, setShow, onChangeEvent, children}) => {
    const changeEvent = () => {
        setShow(!show)
        onChangeEvent(name, (show ? 'true' : 'false'))
    }

    return (
        <React.Fragment>
            <label className={'optional'}>
                <input
                    type={'checkbox'}
                    name={name}
                    value={value}
                    checked={!show}
                    onChange={changeEvent}
                />
                <span>{label}</span>
            </label>
            {show ? children : null}
        </React.Fragment>
    )
}

export default Checkbox