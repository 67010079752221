import React, {forwardRef} from 'react';
import './index.scss';
import './responsive.scss';
import CollapsibleContainerGroup, {CollapsibleContainerGroupProps} from "../Basic/CollapsibleContainerGroup";
import Post from "../../models/post/post";

export interface FAQComponentProps extends Omit<CollapsibleContainerGroupProps, 'content'>{
    FAQs: Post[],
}

const FAQExpanders = forwardRef<HTMLDivElement, FAQComponentProps>(({FAQs, ...props}, ref) => {

    return (
        <div className={"faq-content"} ref={ref}>
            <CollapsibleContainerGroup
                {...props}
                content={
                    FAQs.map((FAQ) => {
                        return {
                            label: FAQ.title,
                            children: FAQ.content,
                        }
                    })
                }
            />
        </div>
    )
})

export default FAQExpanders
