import RequiredInput from "../../FormComponents/RequiredInput";
import React, {useEffect, useState} from "react";
import InputWrapper from "../../FormComponents/InputWrapper";
import * as Yup from 'yup';
import {countries, provinces, states} from "../../../config/string_constants";
import {getFieldError, StringKeyObject, validate} from "../../../util/form";
import {buildAddressPrefixes} from "../../../models/form";


const AddressContent = ((prefix: string, features: StringKeyObject, form: any) => {
    const [statesOrProvinces, setStateOrProvinces] = useState(states)

    const prefixes = buildAddressPrefixes(prefix)

    // Yup validation object specific to address component
    const yupObject = {
        [prefixes.company]: Yup.string().trim().required(getFieldError(features['company'])),
        [prefixes.address_1]: Yup.string().trim().required(getFieldError(features['address'])),
        [prefixes.address_2]: Yup.string().trim(),
        [prefixes.city]: Yup.string().trim().required(getFieldError(features['city'])),
        [prefixes.country]: Yup.string().required(getFieldError(features['country'])),
        [prefixes.state]: Yup.string().required(getFieldError(features['state'])),
        [prefixes.zip]: Yup.string().trim().required(getFieldError(features['zip']))
    }

    // Function to watch the country to change the list of states or provinces
    useEffect(() => {
        form.values[prefixes.country] === 'Canada' ? setStateOrProvinces(provinces) : setStateOrProvinces(states)
    }, [form.values[prefixes.country], statesOrProvinces, setStateOrProvinces]);

    // Function that returns the HTML for address component
    const htmlNode = (onChangeEvent: (name: string, value: string) => void, onKeyDown: (event: any) => void) => (
        <React.Fragment>
            <RequiredInput
                name={prefixes.company}
                value={form.values[prefixes.company]}
                error={form.errors[prefixes.company] && form.touched[prefixes.company] ? form.errors[prefixes.company] : undefined}
                placeholder={features['company']}
                onRequiredInputChange={onChangeEvent}
                onKeyDown={onKeyDown}
            />
            <RequiredInput
                name={prefixes.address_1}
                value={form.values[prefixes.address_1]}
                error={form.errors[prefixes.address_1] && form.touched[prefixes.address_1] ? form.errors[prefixes.address_1] : undefined}
                placeholder={features['address_1']}
                onRequiredInputChange={onChangeEvent}
                onKeyDown={onKeyDown}
            />
            <InputWrapper label={''}>
                <input
                    name={prefixes.address_2}
                    value={form.values[prefixes.address_2]}
                    placeholder={features['address_2']}
                    onChange={form.handleChange}
                    onKeyDown={onKeyDown}
                />
            </InputWrapper>
            <RequiredInput
                name={prefixes.city}
                value={form.values[prefixes.city]}
                error={form.errors[prefixes.city] && form.touched[prefixes.city] ? form.errors[prefixes.city] : undefined}
                placeholder={features['city']}
                onRequiredInputChange={onChangeEvent}
                onKeyDown={onKeyDown}
            />
            <RequiredInput
                name={prefixes.country}
                value={form.values[prefixes.country]}
                error={form.errors[prefixes.country] && form.touched[prefixes.country] ? form.errors[prefixes.country] : undefined}
                type={'select'}
                options={countries}
                placeholder={features['country']}
                onRequiredInputChange={onChangeEvent}
                onKeyDown={onKeyDown}
            />
            <InputWrapper label={''} error={form.errors[prefixes.state] && form.touched[prefixes.state] ? form.errors[prefixes.state] : undefined}>
                <select name={prefixes.state} value={form.values[prefixes.state]} onChange={form.handleChange} onKeyDown={onKeyDown}>
                    <option value={''} disabled>{features['state']}</option>
                    {statesOrProvinces.map((location, index) => {
                        return <option key={index}>{location}</option>
                    })}
                </select>
            </InputWrapper>
            <RequiredInput
                name={prefixes.zip}
                value={form.values[prefixes.zip]}
                error={form.errors[prefixes.zip] && form.touched[prefixes.zip] ? form.errors[prefixes.zip] : undefined}
                placeholder={features['zip']}
                onRequiredInputChange={onChangeEvent}
                onKeyDown={onKeyDown}
            />
        </React.Fragment>
    )

    return {
        node: (onChangeEvent: (name: string, value: string) => void, onKeyDown: (event: any) => void) => htmlNode(onChangeEvent, onKeyDown),
        validate: (submission: any) => validate(submission, prefixes),
        yup: yupObject
    }
})

export default AddressContent
