import React, {Dispatch, PropsWithChildren, SetStateAction, useState} from 'react';

export interface SignUpData {
    first_name: string
    last_name: string
    email: string
    password: string
}

export interface SignUpContextStateConsumer {
    data: SignUpData,
    setData: (data: SignUpData) => void,
}

let persistedState: SignUpData = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
}
export function createDefaultSignUpContextState(persistedState: SignUpData): SignUpContextStateConsumer {
    return {
        data: persistedState,
        setData: (data: SignUpData) => {},
    }
}

export const SignUpContext = React.createContext<SignUpContextStateConsumer>(createDefaultSignUpContextState(persistedState));

function createSetDataCallback(setData: Dispatch<SetStateAction<SignUpData>>) {
    return (newData: SignUpData) => {
        persistedState = newData;
        setData(newData);
    }
}

interface SignUpContextProviderProps {}

const SignUpContextProvider : React.FC<PropsWithChildren<SignUpContextProviderProps>> = ({children}) => {
    // eslint-disable-next-line
    const [_, setSignUpContext] = useState(persistedState);

    const fullContext = {
        data: persistedState,
        setData: createSetDataCallback(setSignUpContext),
    } as SignUpContextStateConsumer;

    return (
        <SignUpContext.Provider value={fullContext}>
            {children}
        </SignUpContext.Provider>
    )
}

export default SignUpContextProvider;
