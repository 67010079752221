import React, {useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import Section from "../../Basic/Section";
import FAQExpanders from "../../FAQExpanders";
import {FAQContent} from "../../../config/content-testing";
import Button from "../../Basic/Button";
import TweenMax from "gsap";
import {useParams} from "react-router-dom";
import {BrandRootParams} from "../../../models/brand";
import FAQsContextProvider, {FAQsContext} from "../../../contexts/FAQsContext";
import Post from "../../../models/post/post";
import Page from "../../../models/page";
import LoadingIndicator from "../../Loaders/LoadingIndicator";
import LoadingSection from "../../Loaders/LoadingSection";

interface FAQSectionContentProps extends FAQSectionProps {
    FAQs: Post[],
    canLoadMore: boolean,
    loadMore: () => Promise<Page<Post[]>>,
}

const FAQSectionContent: React.FC<FAQSectionContentProps> = ({id, FAQs, title, canLoadMore, loadMore, className}) => {
    const [animate, setAnimate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [faqCount, setFaqCount] = useState(FAQs.length)
    const contentRef = React.createRef<HTMLDivElement>()

    // Fires click event to load more FAQ items
    const handleClick = async () => {
        TweenMax.set(contentRef?.current, {height: (contentRef?.current?.offsetHeight) + 'px'})
        setLoading(true)
        await loadMore()
    }

    // Once the animation is complete, show the load more button again
    const animationComplete = () => {
        if (contentRef) {
            TweenMax.set(contentRef.current, {height: 'auto'})
        }
        setAnimate(false)
        setLoading(false)
    }

    // Runs whenever the FAQ count changes
    useEffect( () => {
        // If the count is greater than the previous count, start the animation
        if (FAQs.length > faqCount) {
            setFaqCount(FAQs.length)
            setAnimate(true)
        }
    }, [FAQs.length])

    // If true, run the animation
    useEffect(() => {
        if(animate){
            TweenMax.to(contentRef?.current, {
                duration: 1,
                height: contentRef?.current?.children[0].clientHeight + 'px',
                ease: "power3.in",
                onComplete: animationComplete
            })
        }
    }, [animate]);

    return (
        <Section id={id} className={'faq-section' + (className ? ' ' + className : '')}>
            {title ? <h2 className={'faq-title'}>{title}</h2> : null}
            <FAQExpanders
                ref={contentRef}
                FAQs={[...FAQs]}
                closedIcon={'plus'}
                openIcon={'minus'}
            />
            <div className={'faqs-footer'}>
                {canLoadMore && !loading ? (
                    <Button className={'expand-more-btn'} onClick={handleClick}>See More</Button>
                ) : ( loading ? (
                    <LoadingIndicator />
                ) : null)}
            </div>
        </Section>
    )
}
interface FAQSectionProps {
    id: string,
    title?: string,
    className?: string,
}

const FAQSection: React.FC<FAQSectionProps> = (props) => {
    const {brand}  = useParams<BrandRootParams>();

    return (
        <FAQsContextProvider>
            <FAQsContext.Consumer>
                {context => context.initialLoadComplete && brand ? (
                    <FAQSectionContent FAQs={context.loadedData ? context.loadedData : FAQContent[brand] as Post[]} canLoadMore={context.hasAnotherPage} loadMore={context.loadNext} {...props}/>
                ) : (
                    <LoadingSection id={'faqs-loading'} />
                )}
            </FAQsContext.Consumer>
        </FAQsContextProvider>
    )
}

export default FAQSection
