import React, {useEffect, useState} from 'react'
import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useLocation, useParams} from "react-router-dom";
import {buildBreadcrumbs} from "../../util/navigation";
import CheckoutForm from "../../components/Forms/CheckoutForm";
import CurrentCart from "../../components/CartComponents/CurrentCart";
import BorderContent from "../../components/PageSections/BorderContent";
import {PAGES} from "../../config";
import {BrandRootParams} from "../../models/brand";
import PageLink from "../../models/link";
import CurrentOrderContextProvider, {CurrentOrderContext} from "../../contexts/CurrentOrderContext";
import Section from "../../components/Basic/Section";
import Icon from "../../components/Basic/Icon";
import MeContextProvider, {MeContext} from "../../contexts/MeContext";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";

interface CheckoutContentProps {
	breadcrumbs: PageLink[],
	orderSubmissionStatus: boolean
	setOrderSubmission: (submissionStatus: boolean) => void
}

const CheckoutContent: React.FC<CheckoutContentProps> = ({breadcrumbs, orderSubmissionStatus, setOrderSubmission}) => {
	const features = ['checkout-headline', 'checkout-thank-you', 'order-summary-headline', 'total-price-label']

	return (
		<StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()}>
			<StaticFeatureContext.Consumer>
				{context => !orderSubmissionStatus ? (
					<React.Fragment>
						<Breadcrumbs id={'checkout-breadcrumbs'} breadcrumbs={breadcrumbs} />
						<section id={'order-summary'}>
							{context.features['checkout-headline'] ? <h2 className={'my-cart-title'}>{context.features['checkout-headline']}</h2> : ''}
							<BorderContent className={'my-cart'} id={'checkout-info'}>
								{context.features['order-summary-headline'] ? <h5 className={'cart-title'}>{context.features['order-summary-headline']}</h5> : ''}
								<CurrentCart totalPosition={'bottom'} totalLabel={context.features['total-price-label']}/>
								<MeContextProvider>
									<MeContext.Consumer>
										{context => <CheckoutForm setSubmissionStatus={setOrderSubmission} loggedInUser={context.me}/>}
									</MeContext.Consumer>
								</MeContextProvider>
							</BorderContent>
						</section>
					</React.Fragment>
				) : (
					<Section id={"checkout-thank-you"}>
						<h3><Icon iconName={'profile'}/><br/>{context.features['checkout-thank-you']}</h3>
					</Section>
				)}
			</StaticFeatureContext.Consumer>
		</StaticFeatureContextProvider>
	)
}

interface CheckoutProps {
}

const Checkout: React.FC<CheckoutProps> = () => {
	const {brand}  = useParams<BrandRootParams>();
	const {state} = useLocation()
	const [checkoutBreadcrumbs, setCheckoutBreadcrumbs] = useState<PageLink[]>([])
	const [orderSubmissionStatus, setOrderSubmissionStatus] = useState(false)


	// Gather Cart page breadcrumbs and pass them to the 'Cart' breadcrumb on the Checkout page
	// This allows the user to navigate back to any previous pages before accessing their cart
	// Add the 'Cart' breadcrumb to the collection of breadcrumbs for the page
	useEffect(() => {
		if(state) {
			buildBreadcrumbs(state.matches, brand).then(breadcrumbs => {
				breadcrumbs.push({title: 'Cart', link: `/${brand}` + PAGES.CART, data: state})
				setCheckoutBreadcrumbs(breadcrumbs)
			})
		}
	}, [state]);

	return (
		<PageWrapper id={'checkout-page'} title={"Complete your Order"}>
			<CurrentOrderContextProvider reset={orderSubmissionStatus}>
				<CurrentOrderContext.Consumer>
					{context => (
						<CheckoutContent breadcrumbs={checkoutBreadcrumbs} orderSubmissionStatus={orderSubmissionStatus} setOrderSubmission={setOrderSubmissionStatus} />
					)}
				</CurrentOrderContext.Consumer>
			</CurrentOrderContextProvider>
		</PageWrapper>
	)
}

export default Checkout
