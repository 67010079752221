import React, {useEffect, useState} from 'react';
import './index.scss';
import {getCategoryAncestry} from "../../models/category";
import Breadcrumbs from "../Breadcrumbs";
import PageLink from "../../models/link";
import {useParams} from "react-router-dom";
import {BrandRootParams} from "../../models/brand";
import {PAGES} from "../../config";

interface CategoryBreadcrumbsProps{
    id: string
    categoryId: number
}

const CategoryBreadcrumbs: React.FC<CategoryBreadcrumbsProps> = ({id, categoryId}) => {
    const {brand} = useParams<BrandRootParams>();
    const [categoryBreadcrumbs, setCategoryBreadcrumbs] = useState([] as PageLink[])

    useEffect(() => {
        // Get the category ancestors using the categoryId prop as the starting point
        getCategoryAncestry(categoryId).then(categoryAncestors => {
            // When the Promise resolves, create a list of the category ancestors in a PageLink[] format
            const categoryAncestorBreadcrumbs = categoryAncestors.map((ancestor, index) => {
                // Concatenate the slugs of previous parent categories to form the entire category link
                const categoryLink = categoryAncestors.reduce((combinedLink, ancestor, i) => combinedLink += ( (i <= index) ? '/' + ancestor.slug : ''), '' )
                return {
                    title: ancestor.name,
                    link: `/${brand}` + PAGES.CATEGORY_ROOT + categoryLink
                }
            })
            setCategoryBreadcrumbs(categoryAncestorBreadcrumbs)
        })
    }, [categoryId]);

    return categoryBreadcrumbs.length ? (
        <Breadcrumbs id={id} breadcrumbs={categoryBreadcrumbs}/>
    ) : null
}

export default CategoryBreadcrumbs

