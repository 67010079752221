import {StringKeyObject} from "../util/form";

/**
 * @purpose Builds the contact prefixes for a form
 *
 * @param prefix the string prefix
 *
 * @return An object of the form fields with added prefix
 */
export const buildContactPrefixes = (prefix: string) => {
    return {
        first_name: prefix + '_first_name',
        last_name: prefix + '_last_name',
        name: prefix + '_name',
        email: prefix + '_email',
        phone: prefix + '_phone',
        fax: prefix + '_fax'
    }
}

/**
 * @purpose Builds the address prefixes for a form
 *
 * @param prefix the string prefix
 *
 * @return An object of the form fields with added prefix
 */

export const buildAddressPrefixes = (prefix: string) => {
    return {
        company: prefix + '_company',
        address_1: prefix + '_address_1',
        address_2: prefix + '_address_2',
        city: prefix + '_city',
        country: prefix + '_country',
        state: prefix + '_state',
        zip: prefix + '_zip'
    } as StringKeyObject
}