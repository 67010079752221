import {api} from '../api';
import Page from '../../models/page';
import Category from '../../models/category';
import Product from "../../models/product";
import {addOrderAndFilterToParams} from "../../util/requests";

export default class CategoryRequests {

    /**
     * All cached results from any category requests
     */
    private static cachedResults: {[key: string]: Category[]} = {};
    private static cachedSlugs: {[key: string]: Category|undefined} = {};
    private static cachedIds: {[key: number]: Category} = {};

    /**
     * Searches for a category based on the passed in name
     * @param name
     * @return Category[]
     */
    static async searchCategories(name: string): Promise<Category[]> {

        if (CategoryRequests.cachedResults[name] !== undefined)  {
            return CategoryRequests.cachedResults[name];
        }

        const { data } = await api.get('/categories', {
            params: {
                'search[name]': 'like,*' + name + '*',
                'expand[brandOptions]' : '*',
                'expand[brands]' : '*',
            }
        });

        const result = (data as Page<Category>).data;
        if(result.length) {
            result.forEach(category => {
                category.children = category.children?.filter(child => child.published_at !== null)
            })
        }

        CategoryRequests.cachedResults[name] = result;

        return result;
    }

    /**
     * Gets a category based on the passed in ID
     * @param id
     * @return Category|undefined
     */
    static async getCategoryById(id: number): Promise<Category> {
        if(CategoryRequests.cachedIds.hasOwnProperty(id)) {
            return CategoryRequests.cachedIds[id]
        }

        const { data } = await api.get('/categories/' + id, {
            params: {
                'expand[parent]': '*',
            }
        });

        const result = data as Category;
        CategoryRequests.cachedIds[id] = result;
        return result;
    }

    /**
     * Gets a category based on the passed in slug
     * @param slug
     * @return Category|undefined
     */
    static async getCategory(slug: string): Promise<Category|undefined> {

        if (CategoryRequests.cachedSlugs.hasOwnProperty(slug))  {
            return CategoryRequests.cachedSlugs[slug];
        }

        const { data } = await api.get('/categories', {
            params: {
                'filter[slug]': slug,
                'expand[brands]' : '*',
                'expand[brandOptions]' : '*',
                'expand[children]': '*',
                'expand[children.brandOptions]' : '*',
                'expand[children.brands]' : '*',
            },
        });

        const categoryResults = data.data as Category[];
        const result = categoryResults.length && categoryResults[0].published_at ? categoryResults[0] : undefined;
        if(result){
            result.children = result.children?.filter(child => child.published_at !== null)
        }

        CategoryRequests.cachedSlugs[slug] = result;

        return result;
    }


    /**
     * Gets all products in a category based on the passed in ID
     * @param id
     * @param params
     * @param limit
     * @return Product[]|undefined
     */
    static async getCategoryProducts(id: number, params?: any, limit = 10): Promise<Product[]|undefined> {
        const config = {
            params: {
                'expand[assets]': '*',
                'expand[brandOptions]': '*',
                'expand[brands]': '*',
                limit: limit
            }
        } as any

        config.params = addOrderAndFilterToParams(config.params, params?.filter, params?.order)

        const { data } = await api.get('/categories/' + id + '/products', config);

        return  data.data as Product[];
    }


    /**
     * Creates a category for us properly
     * @param name
     */
    static async createCategory(name: string): Promise<Category> {
        const { data } = await api.post('/categories ', {
            name: name,
        });
        return data as Category;
    }
}
