import React from 'react';
import './index.scss';
import './responsive.scss';
import Section from "../../Basic/Section";
import ResourceSlider from "../../ResourceSlider";
import ResourceCategory from "../../ResourceCategory";
import ParentCategoriesContextProvider, {ParentCategoriesContext} from "../../../contexts/ParentCategoriesContext";
import {featuredCategoriesSlugs} from "../../../config/string_constants";

interface FeaturedCategoriesProps {
    id: string
    title?: string
    sectionBackgroundColor?: 'white'|'lt-gray'
    categoryBackgroundColor?:'white'|'gray'
}

const FeaturedCategories: React.FC<FeaturedCategoriesProps> = ({id, title, sectionBackgroundColor, categoryBackgroundColor}) => {
    return (
        <ParentCategoriesContextProvider>
            <ParentCategoriesContext.Consumer>
                {context => {
                    const categories = context.loadedData?.filter(category => featuredCategoriesSlugs.includes(category.slug))
                    return context.loadedData ? (
                      <Section id={id} className={'featured-categories-section' + (sectionBackgroundColor ? ' bg-' + sectionBackgroundColor : '')}>
                          {title ? <h3 className={'featured-categories-title'}>{title}</h3> : null}
                          <ResourceSlider id={'featured-categories'} className={'category-slider'}>
                              {categories.map(category => <ResourceCategory key={category.slug} category={category} backgroundColor={categoryBackgroundColor}/>)}
                          </ResourceSlider>
                      </Section>
                    ) : null
                }}
            </ParentCategoriesContext.Consumer>
        </ParentCategoriesContextProvider>
    )
}

export default FeaturedCategories
