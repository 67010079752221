import React, {ForwardedRef, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import './index.scss';
import {Link} from "react-router-dom";
import {BrandColorNames} from "../../../models/brand";


export interface ButtonProps extends HTMLAttributes<HTMLOrSVGElement> {
	to?: string;
	data?: any;
	buttonColor?: BrandColorNames;
	type?: "button" | "submit" | "reset";
	outline?: boolean;
	disabled?: boolean;
	target?: string
}

const Button= forwardRef<HTMLButtonElement|HTMLAnchorElement, PropsWithChildren<ButtonProps>>((props, ref) => {
	const {to, data, buttonColor, type, outline, disabled, className, children, ...additionalProps} = props
	let classes = 'btn' + (
		buttonColor ? ' btn-' + buttonColor : ''
	) + (
		outline ? ' outline' : ''
	) + (
		disabled ? ' disabled' : ''
	) + (
		className ? ' ' + className : ''
	)
	const isPDF = to ? to.includes('.pdf') : false

	const button_type = type ? type : 'button'

	return to ? (
		isPDF ? (
				<a ref={ref as ForwardedRef<HTMLAnchorElement>} target={"_blank"} rel={"noopener noreferrer"} href={to ? to : ''} className={classes} {...additionalProps}>
					{children}
				</a>
			) : (
				<Link ref={ref as ForwardedRef<HTMLAnchorElement>} to={to ? to : ''} state={data ? data: null} className={classes} {...additionalProps}>
					{children}
				</Link>
		)
	) : (
		<button ref={ref as ForwardedRef<HTMLButtonElement>} type={button_type} className={classes} {...additionalProps}>
			{children}
		</button>
	)
})

export default Button
