import BaseModel from "./base-model";
import Brand from "./brand";
import ProductAsset from "./productAsset";

export default interface BrandOption extends BaseModel {
    /**
     * The id of the associated brand
     */
    brand_id: number;

    /**
     * The field of the brand option
     */
    field: string;

    /**
     * The value of the brand option
     */
    value?: string;

    /**
     * The field of the brand option
     */
    asset?: ProductAsset;
}

export function mergeBrandOptions<Model>(item: Model, brand: Brand) {
    const newModel = {...item} as { [key: string]: any }

    if(newModel && newModel.brand_options){
        const brandOptions: BrandOption[] = newModel.brand_options
        const brandChanges = brandOptions.filter(options => options.brand_id === brand.id)
        const booleanOptions = ['featured', 'free', 'return']
        brandChanges.forEach(option => {
            if (option.field !== 'id') {
                if (option.asset) {

                    if ((item as object).hasOwnProperty('assets')) {
                        option.asset.type = option.field !== 'featured_image' ? option.field : 'feature'
                        let assetFound = false

                        newModel['assets'] = newModel['assets']?.map( (asset: ProductAsset) => {
                            let retVal = asset
                            if (asset.type === option.asset!.type) {
                                retVal = option.asset!
                                assetFound = true
                            }
                            return retVal
                        })

                        if (!assetFound) {
                            newModel['assets'].push(option.asset!)
                        }
                    }
                    else {
                        newModel[option.field] = option.asset
                    }
                }
                else if (option.value || booleanOptions.includes(option.field)) {
                    newModel[option.field] = option.value
                }
            }
        })
    }

    return newModel as Model
}
