import React, {forwardRef, HTMLAttributes} from 'react';
import './index.scss';


interface Props extends HTMLAttributes<HTMLOrSVGElement> {
	src: string,
	alt: string,
	height?: string,
	width?: string,
}

const Img: React.FC<Props> = forwardRef<HTMLImageElement, Props>((props, ref) => {
	const {src, alt, className, ...additionalProps} = props

	return (
		<img ref={ref} src={src} alt={alt} className={className} {...additionalProps}/>
	)
})

export default Img
