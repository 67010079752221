import React, {PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import {Transition, TransitionGroup} from "react-transition-group";
import TweenMax from "gsap";
import Icon from "../Icon";
import {useSwipeable} from "react-swipeable";
import {useViewportWidth} from "../../../util/WindowInfo";

/** Slide Component **/
interface SlideProps {
    index: number,
    node: ReactNode,
    isActive: boolean,
    duration: number,
    direction: number,
    shouldAnimate?: boolean
}

const INACTIVE_ANIMATION_LEFT = 150
const ACTIVE_ANIMATION_LEFT = 0

const Slide: React.FC<SlideProps> = ({index, node, isActive, duration, direction, shouldAnimate}) => {
    const [callback, setCallback] = useState(null as any)

    // Function called after the completion of the transition to set the callback function of animationComplete()
    const end = (_: HTMLElement, done: () => void) => {
        setCallback(done)
    }

    // Sets the exiting behavior of a slide
    const exit = (node: HTMLElement) => {
        const initialLeft = shouldAnimate ? ACTIVE_ANIMATION_LEFT : INACTIVE_ANIMATION_LEFT
        node.style.left = initialLeft + '%'

        if (shouldAnimate) {
            TweenMax.to(node, {
                left: -(initialLeft * direction) + '%',
                ease: 'power3.inOut',
                onComplete: () => animationComplete(node)
            })
        }
    }

    // Sets the entering behavior of a slide
    const enter = (node: HTMLElement) => {
        const initialLeft = shouldAnimate ? INACTIVE_ANIMATION_LEFT : ACTIVE_ANIMATION_LEFT
        node.style.left = (initialLeft * direction) + '%'

        if (shouldAnimate) {
            TweenMax.to(node, {
                left: ACTIVE_ANIMATION_LEFT + '%',
                ease: "power3.inOut",
                onComplete: () => animationComplete(node)
            })
        }
    }

    // Calling the callback function if set once the transition completes (enter or exit)
    const animationComplete = (node: HTMLElement) => {
        node.style.left = ''
        return callback ? callback() : null;
    }

    return (
        <Transition key={index+1} in={isActive} onEnter={enter} onExit={exit} addEndListener={end} timeout={duration}>
            <div className={'slide' + (isActive ? ' active' : '')}>
                {node}
            </div>
        </Transition>
    )
}

/** Slider Component **/
interface SliderProps {
    id: string
    mobileOnly?: boolean
    className?: string
}

const Slider: React.FC<PropsWithChildren<SliderProps>> = ({id, mobileOnly, children, className}) => {
    const [activeIndex, setActiveIndex] = useState(mobileOnly ? 0 : -1)
    const [direction, setDirection] = useState(1)
    const [shouldSlide, setShouldSlide] = useState(false)

    const windowWidth = useViewportWidth()

    const classes = (
        className ? ' ' + className : ''
    ) + (
        mobileOnly ? ' mobile-only' : ''
    )

    // Verify that index is within the bounds of the slider contents and set to first or last element if it is outside the bounds
    const checkActiveIndex = (newDirection: number) => {
        let newIndex = activeIndex + newDirection
        if(newIndex >= React.Children.count(children)){
            newIndex = 0
        }
        else if(newIndex < 0){
            newIndex = (React.Children.count(children) - 1)
        }
        setActiveIndex(newIndex)
        setDirection(newDirection)
    }

    // Swipeable settings
    const handlers = useSwipeable({
        onSwipedLeft: () => checkActiveIndex(1),
        onSwipedRight: () => checkActiveIndex(-1),
        preventScrollOnSwipe: true,
        trackTouch: shouldSlide,
    })

    useEffect(() => {
        setShouldSlide(windowWidth < 768)
    }, [windowWidth]);

    useEffect(() => {
        if(activeIndex < 0){
            setActiveIndex(shouldSlide ? 0 : -1);
        }
    }, [shouldSlide])
    return children ? (
        <div className={'slider' + classes} {...handlers}>
            <Icon className={'arrow'} iconName={"arrow-left"} onClick={() => checkActiveIndex(-1)}/>
            <TransitionGroup id={id} className={'slider-content'}>
                {React.Children.map(children, (child, index) => (
                    <Slide key={index} duration={5000} index={index} node={child} isActive={(activeIndex === index)} direction={direction} shouldAnimate={!mobileOnly || shouldSlide}/>
                ))}
            </TransitionGroup>
            <Icon className={'arrow'} iconName={"arrow-right"} onClick={() => checkActiveIndex(1)} />
        </div>
    ) : null
}

export default Slider
