import React, {useEffect, useState} from 'react'

import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import {useParams} from "react-router-dom";
import Category, {CategoryParams} from "../../models/category";
import ErrorPage from "../ErrorPage";
import InPageNavigation from "../../components/InPageNavigation";
import PageLink from "../../models/link";
import {PAGES} from "../../config";
import {BrandRootParams} from "../../models/brand";
import Section from "../../components/Basic/Section";
import CategoryContextProvider, {CategoryContext} from "../../contexts/CategoryContext";
import BestSellersSection from "../../components/PageSections/BestSellersSection";
import TweenMax from "gsap";
import HTMLComponent from "../../components/Basic/HTMLComponent";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../contexts/StaticFeatureContext";
import BestSellingProductsContextProvider, {BestSellingProductsContext} from "../../contexts/BestSellingProductsContext";
import CategoryOptionsList from "../../components/CategoryOptionsList";
import {StringKeyObject} from "../../util/form";

/**
 * The Header for all category template pages
 * - Outputs the Top level category name, description, and first-level subcategory navigation
 * - Converts a list of subcategories into PageLink objects for the navigation
 * - Determines and highlights the current active first-level subcategory navigation item
 *
 * @component
 * @param parentCategory the slug of the top-level parent category
 * @param linkRoot the current URL path, determines the correct activeLink for the navigation
 */
interface CategoryTemplateHeaderProps {
	parentCategory: Category
	linkRoot: string
	categoryNavigationPrefix: string
}

const CategoryTemplateHeader: React.FC<CategoryTemplateHeaderProps> = ({parentCategory, linkRoot, categoryNavigationPrefix}) => {
	const {brand} = useParams<BrandRootParams>()
	const parentLink = '/' + brand + PAGES.CATEGORY_ROOT + '/' + parentCategory.slug + '/'
	const ancestors = linkRoot.split('/')

	// Build sub category navigation object
	let subCategoryNavigation = [] as PageLink[]
	let activeLink = parentLink

	if (ancestors.length > 1) {
		activeLink = parentLink + ancestors[1]
	}

	if (parentCategory && parentCategory.children?.length) {
		subCategoryNavigation.push({
			title: categoryNavigationPrefix + ' ' + parentCategory.name,
			link: parentLink
		})

		parentCategory.children.forEach(subcategory => {
			subCategoryNavigation.push({
				title: subcategory.name,
				link: parentLink + subcategory.slug,
			})
		});
	}

	return parentCategory ? (
		<div className={'category-template-header'}>
			<h2>{parentCategory.name}<span>({parentCategory.product_count})</span></h2>
			{parentCategory.body ? <HTMLComponent tag={'p'} className={'category-content'} content={parentCategory.body}/> : null}
			{subCategoryNavigation.length ? <InPageNavigation id={'sub-category-navigation'} pages={subCategoryNavigation} activeLink={activeLink}/> : null}
			<hr/>
		</div>
	) : null
}

/**
 * The Content for all category template pages
 *
 * @component
 */
interface CategoryTemplateContentProps {
	parentCategory?: Category
	subCategory?: Category
	setPageTitle: (title: string) => void
	staticText: StringKeyObject
	linkRoot: string
}

const CategoryTemplateContent: React.FC<CategoryTemplateContentProps> = ({parentCategory, subCategory, setPageTitle, staticText, linkRoot}) => {

	return subCategory ? (
		<React.Fragment>
			<CategoryOptionsList category={subCategory} linkRoot={linkRoot} setPageTitle={setPageTitle} isRootCategory={parentCategory?.id === subCategory.id}/>
			<BestSellingProductsContextProvider categoryId={subCategory.id}>
				<BestSellingProductsContext.Consumer>
					{bestSellingProductsContext => (
						<BestSellersSection id={'fixtures-bestSellers'} products={bestSellingProductsContext.products} title={staticText['best-sellers-headline']} productBackgroundColor={'white'}/>
					)}
				</BestSellingProductsContext.Consumer>
			</BestSellingProductsContextProvider>
		</React.Fragment>
	) : staticText['sub-category-does-not-exist'] ? (
		<Section id={"invalid-subcategory"}><h3>{staticText['sub-category-does-not-exist']}</h3></Section>
	) : null
}

/**
 * The Primary content management for the Category Template
 * - Outputs the page wrapper & all page content sections.
 * - Handles subcategory output, if there are subcategories
 * - Passes processing off to the CategoryOptionsList component if there are no more subcategories
 * - If the category provided is invalid, outputs a 404 page
 *
 * @component
 * @param primaryCategory the current category we would like to display content for
 * @param parentCategorySlug the slug of the top-level parent category, used to generate the CategoryTemplateHeader
 * @param linkRoot the current URL path, for determining ancestors and generating new links with the correct hierarchy
 */
interface CategoryTemplateBodyProps {
	primaryCategorySlug?: string
	parentCategory?: Category
	linkRoot: string
	setPageTitle: (title: string) => void
	staticText: StringKeyObject
}

const CategoryTemplateBody: React.FC<CategoryTemplateBodyProps> = ({primaryCategorySlug, parentCategory, linkRoot, setPageTitle, staticText}) => {
	const [currentCategory, setCurrentCategory] = useState(primaryCategorySlug)

	useEffect(() => {
		if (primaryCategorySlug !== currentCategory) {
			setCurrentCategory(primaryCategorySlug)
		}

		if (!parentCategory) {
			setPageTitle('category-does-not-exist')
		}
	}, [primaryCategorySlug, parentCategory]);

	return parentCategory ? (
		<React.Fragment>
			<Section id={'category-header'} content_props={{'className': 'pad-top'}}>
				<CategoryTemplateHeader parentCategory={parentCategory} linkRoot={linkRoot} categoryNavigationPrefix={staticText['all-text']}/>
			</Section>
			<CategoryContextProvider categorySlug={currentCategory ? currentCategory : parentCategory.slug}>
				<CategoryContext.Consumer>
					{subCategoryContext => {

						return (
							<CategoryTemplateContent
								parentCategory={parentCategory}
								subCategory={subCategoryContext.category}
								setPageTitle={setPageTitle}
								staticText={staticText}
								linkRoot={linkRoot} />
						)
					}}
				</CategoryContext.Consumer>
			</CategoryContextProvider>
		</React.Fragment>
	) : null
}

/**
 * The Main Category template entry point. Handles the Context of the initial category
 * - Requires a minimum of a /:category parameter on the URL to function
 * - Will output the content for the lowest level category provided in /*, if additional categories are provided
 *
 * @component
 */
interface CategoryTemplateProps {
}

const CategoryTemplate: React.FC<CategoryTemplateProps> = () => {
	const [pageTitle, setPageTitle] = useState('Products')
	const [rootCategory, setRootCategory] = useState('')

	const {category, "*": subcategories} = useParams<CategoryParams>()
	let subCategorySlug= ''
	let ancestorCategories= category!
	const featureNames = ['category-does-not-exist', 'site-title', 'sub-category-does-not-exist', 'best-sellers-headline', 'all-text']

	// set the subcategories
	if(subcategories) {
		const pos = subcategories.lastIndexOf('/')
		if(pos > -1){
			subCategorySlug = subcategories.slice(pos + 1)
			ancestorCategories = ancestorCategories.concat('/', subcategories)
		}
		else {
			subCategorySlug = subcategories
			ancestorCategories = ancestorCategories.concat('/', subcategories)
		}
	}

	// Custom scroll handling for the Category Template
	useEffect(() => {
		if (category !== rootCategory) {
			TweenMax.to(window, {duration: .3, scrollTo: { y: 0, x: 0 }})
			setRootCategory(category ? category : '')
		}
		else {
			TweenMax.to(window, {duration: .3, scrollTo: {y: '#sub-category-navigation', offsetY: 145}})
		}
	}, [category, subCategorySlug]);



	return (
		<StaticFeatureContextProvider featureNames={featureNames} featuresRequestKey={featureNames.join()}>
			<StaticFeatureContext.Consumer>
				{staticContext => pageTitle !== 'category-does-not-exist' ? (
					<CategoryContextProvider categorySlug={category!}>
						<CategoryContext.Consumer>
							{parentCategoryContext => (
								<PageWrapper id={'category-template'} title={pageTitle}>
									<CategoryTemplateBody
										parentCategory={parentCategoryContext.category}
										primaryCategorySlug={subCategorySlug}
										linkRoot={ancestorCategories}
										setPageTitle={(title: string) => setPageTitle(title)}
										staticText={staticContext.features}/>
								</PageWrapper>
							)}
						</CategoryContext.Consumer>
					</CategoryContextProvider>
				) : <ErrorPage errorNumber={404} pageTitle={staticContext.features['category-does-not-exist']}/>}
			</StaticFeatureContext.Consumer>
		</StaticFeatureContextProvider>
	)
}

export default CategoryTemplate
