
import React, {Dispatch, PropsWithChildren, SetStateAction, useState} from 'react';

export interface SignInData {
    email: string,
    password: string,
}

export interface SignInContextStateConsumer {
    data: SignInData,
    setData: (data: SignInData) => void,
}

let persistedState: SignInData = {
    email: '',
    password: '',
}
export function createDefaultSignInContextState(persistedState: SignInData): SignInContextStateConsumer {
    return {
        data: persistedState,
        setData: (data: SignInData) => {},
    }
}

export const SignInContext = React.createContext<SignInContextStateConsumer>(createDefaultSignInContextState(persistedState));

function createSetDataCallback(setData: Dispatch<SetStateAction<SignInData>>) {
    return (newData: SignInData) => {
        persistedState = newData;
        setData(newData);
    }
}

interface SignInContextProviderProps {}

const SignInContextProvider : React.FC<PropsWithChildren<SignInContextProviderProps>> = ({children}) => {
    const [_, setSignUpContext] = useState(persistedState);

    const fullContext = {
        data: persistedState,
        setData: createSetDataCallback(setSignUpContext),
    } as SignInContextStateConsumer;

    return (
        <SignInContext.Provider value={fullContext}>
            {children}
        </SignInContext.Provider>
    )
}

export default SignInContextProvider;
