import React, {forwardRef} from 'react';
import {Link} from "react-router-dom";
import PageLink from "../../models/link";
import './index.scss';
import './responsive.scss';

interface Props {
    pages : PageLink[]
    activeLink: string,
    id?: string,
    className?: string,
}

const InPageNavigation = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {pages, activeLink, className, ...additionalProps} = props

    const classes = className ? className + ' navigation' : 'navigation'
    const flexValue = (100 / pages.length) - 1 + '%'

    return (
        <div ref={ref} className={classes} {...additionalProps}>
            {pages.map( (page, index) => {

                let classes = 'nav-item' + (page.link === activeLink ? ' active' : '')

                return page.link ? (
                    <Link key={index} to={page.link} className={classes} style={{flexBasis: flexValue}}>{page.title}</Link>
                ) : '' }) }
        </div>
    )
})

export default InPageNavigation
