import React, {ForwardedRef, forwardRef, ReactNode, useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import {IconNames} from "../../../models/icon";
import CollapsibleContainer, {CollapsibleContainerProps} from "../CollapsibleContainer";

interface SingleContainerProps extends Omit<CollapsibleContainerProps, 'closedIcon' | 'onToggle'> {
	children: ReactNode | string,
	closedIcon?: IconNames,
}

export interface CollapsibleContainerGroupProps {
	closedIcon: IconNames
	openIcon?: IconNames
	always_one_open?: boolean
	className?: string,
	content: SingleContainerProps[]
	closeAll?: boolean
}

const CollapsibleContainerGroup = forwardRef<HTMLOrSVGElement, CollapsibleContainerGroupProps>(({closedIcon, openIcon, always_one_open = false, className, content, closeAll}, ref) => {
	const [openContainer , setOpenContainer] = useState(always_one_open ? 0 : -1)

	useEffect(() => {
		if (closeAll && openContainer !== -1) {
			setOpenContainer(-1)
		}
	}, [closeAll]);

	const processToggle = ((opened : boolean, index: number) => {
		// If the container is open and its index matches the openContainer and always_one_open is set to true
		if(always_one_open && index === openContainer){
			return false
		}
		else{
			// If the container is not open, set it to be the open container
			if(!opened){
				setOpenContainer(index)
			}
			return true
		}
	})

	return (
		<div className={'expanding-group' + (className ? ' ' + className : '')} ref={ref as ForwardedRef<any>}>
			{content.map((containerProps, index) => {
				return (
					<CollapsibleContainer
						key={index}
						closedIcon={closedIcon}
						openIcon={openIcon ? openIcon : closedIcon}
						open={(openContainer === index)}
						onToggle={(opened : boolean) => {return processToggle(opened, index)}}
						{...containerProps}
					/>
				)
			})}
		</div>
	)
})

export default CollapsibleContainerGroup

