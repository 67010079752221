import React, {forwardRef, PropsWithChildren, useEffect, useState} from 'react';
import './index.scss';
import './responsive.scss';
import Content from "../Content";
import AccordionGroup from "../../AccordionGroup";
import {buildMenuDropdowns, buildMenu} from "../../../models/menu-item";
import {useParams} from "react-router-dom";
import {BrandRootParams} from "../../../models/brand";
import ParentCategoriesContextProvider, {ParentCategoriesContext} from "../../../contexts/ParentCategoriesContext";
import Category from "../../../models/category";
import {PossibleBrands} from "../../../config";
import MenuItem from "../../../models/menu-item";
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";
import {brandDefaultLogo, brandReversedLogo} from "../../../config/image_constants";
import Img from "../Img";
import {connect} from "../../../data/connect";
import {logOut} from "../../../data/persistent/persistent.actions";
import {clearLoadingCount} from "../../../data/session/session.actions";

interface FooterContentProps extends DispatchProps {
	contextMenuItems: Category[],
	brand: PossibleBrands,
}

const FooterContent: React.FC<FooterContentProps> = ({contextMenuItems, brand, clearLoadingCount, logOut}) => {
	const [footerMenu, setFooterMenu] = useState<MenuItem[]>()

	useEffect(() => {
		const menu = buildMenu('footer', contextMenuItems, brand)
		setFooterMenu(menu)
	}, [contextMenuItems, brand]);

	const onLogOut = () => {
		clearLoadingCount()
		logOut()
	}

	return footerMenu ? (
		<AccordionGroup mobileOnly={true} className={'footer-menu'} closedIcon={"arrow-down"} openIcon={"arrow-up"} content={buildMenuDropdowns(footerMenu, undefined, onLogOut)}/>
	) : null
}

interface OwnProps extends PropsWithChildren{
	copyright: string,
	reverseLogo: boolean,
	title?: string,
}

interface DispatchProps {
	logOut: typeof logOut,
	clearLoadingCount: typeof clearLoadingCount,
}

interface FooterProps extends OwnProps, DispatchProps {
}

const Footer = forwardRef<HTMLElement, FooterProps>(({copyright, reverseLogo, title, clearLoadingCount, logOut, children}, ref) => {
	const {brand} = useParams<BrandRootParams>()
	const logo = reverseLogo ? brandReversedLogo[brand!] : brandDefaultLogo[brand!]

	return (
		<footer ref={ref} id={'footer'}>
			<Content>
				{children ? children : (
					<MeContextProvider hideLoader={true}>
						<MeContext.Consumer>
							{() => (
								<ParentCategoriesContextProvider>
									<ParentCategoriesContext.Consumer>
										{context => context.loadedData ? (
											<React.Fragment>
												<div className={'footer-contents'}>
													<div className={'footer-header'}>
														{title ? <h3>{title}</h3> : ''}
														<div className={'footer-brand-images'}>
															<Img src={process.env.PUBLIC_URL + logo.url} alt={logo.alt!} className={'brand-logo'}/>
															<img src={process.env.PUBLIC_URL + '/assets/images/ps-logo.png'} className={'ps-logo'} alt={"CNH Genuine Parts Logo"}/>
														</div>
													</div>
													<FooterContent contextMenuItems={context.loadedData} brand={brand!} clearLoadingCount={clearLoadingCount} logOut={logOut}/>
												</div>
												<p className={'copyright'}>&copy;{copyright}</p>
											</React.Fragment>
										) : null}
									</ParentCategoriesContext.Consumer>
								</ParentCategoriesContextProvider>
							)}
						</MeContext.Consumer>
					</MeContextProvider>
				)}
			</Content>
		</footer>
	)
})

export default connect<OwnProps, { }, DispatchProps>({
	mapDispatchToProps: ({
		logOut,
		clearLoadingCount,
	}),
	component: Footer
});
