import React from 'react';
import './index.scss';
import './responsive.scss';
import {Outlet, useOutletContext, useParams} from "react-router-dom";
import {BrandRootParams} from "../../models/brand";
import {menu_constants, resourceCategoriesSlugs} from "../../config/string_constants";
import InPageNavigation from "../../components/InPageNavigation";
import {ResourceRootParams} from "../../models/resource";
import {PossibleBrands, PossibleResources, resources} from "../../config";
import ErrorPage from "../ErrorPage";
import Section from "../../components/Basic/Section";
import {BrandOutletProps} from "../../App";
import
    ParentCategoriesContextProvider, {
    ParentCategoriesContext,
    ParentCategoriesContextState
} from "../../contexts/ParentCategoriesContext";

interface ResourcesMenuProps {
    categoriesContext: ParentCategoriesContextState,
    resource?: PossibleResources,
    brand?: PossibleBrands,
}

const ResourcesMenu: React.FC<ResourcesMenuProps> = ({categoriesContext, resource,  brand}) => {
    let resourceLinkItems = categoriesContext.loadedData
        ?.filter(category => resourceCategoriesSlugs.includes(category.slug))
        .map(category => ({
            title: category.name,
            link: category.slug
        }));

    menu_constants.resource_menu_items.forEach( resource => {
        resourceLinkItems.push({
            title: resource.name,
            link: (resource.slug) as PossibleResources
        })
    })

    return (
        <InPageNavigation className={'resource-nav'} pages={resourceLinkItems} activeLink={resource!}/>
    )
}

interface ResourceRootProps {
}

const ResourcesRoot: React.FC<ResourceRootProps> = () => {
    const {brand} = useParams<BrandRootParams>()
    const {resource} = useParams<ResourceRootParams>()
    const isValidResource = (resource && resources.includes(resource)) || resource === undefined
    const brandOutletProps = useOutletContext<BrandOutletProps>()

    return isValidResource ? (
        <React.Fragment>
            <Section id={'resource-header'} content_props={{'className': 'pad-top'}} >
                <h2 className={'resource-page-title'}>Resources</h2>
                <ParentCategoriesContextProvider>
                    <ParentCategoriesContext.Consumer>
                        {context => context.loadedData ? (
                            <ResourcesMenu brand={brand} resource={resource} categoriesContext={context}/>
                        ) : null}
                    </ParentCategoriesContext.Consumer>
                </ParentCategoriesContextProvider>
                <hr/>
            </Section>
            <Outlet context={{...brandOutletProps}}/>
        </React.Fragment>
    ) : <ErrorPage errorNumber={404} />
}

export default ResourcesRoot
