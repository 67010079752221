import React, {useEffect, useState} from 'react'

import './index.scss';
import PageWrapper from "../../components/Basic/PageWrapper";
import ProductContextProvider, {ProductContext} from "../../contexts/ProductContext";
import {useParams} from "react-router-dom";
import ErrorPage from "../ErrorPage";
import ProductDetailsSection from "../../components/PageSections/ProductDetailsSection";
import RecommendationsSection from "../../components/PageSections/RecommendationsSection";
import {BrandRootParams} from "../../models/brand";
import Product from "../../models/product";
import {PossibleBrands} from "../../config";
import NotFoundError from "../../components/Errors/NotFoundError";
import BestSellingProductsContextProvider, {BestSellingProductsContext} from "../../contexts/BestSellingProductsContext";
import {isEmptyArray} from "formik";
import CategoryBreadcrumbs from "../../components/CategoryBreadcrumbs";

interface ProductContentProps {
	product: Product,
	brand: PossibleBrands,
	setPageTitle: (title: string) => void
}

const ProductContent: React.FC<ProductContentProps> = ({product, brand, setPageTitle}) => {
	const productCategoryId = product.categories ? product.categories[0].id : '';

	useEffect(() => {
		setPageTitle(product.title!)
	}, [product.title]);

	const renderRecommendedSection = (products: Product[]) => {

		// If a product is listed as its own similar item, ignore it
		const productIndex = products.findIndex(currentProduct => currentProduct.id === product.id);
		if (productIndex !== -1) {
			products.splice(productIndex, 1)
		}

		// Limit Similar Items to 3 products, using the order they are listed in the field
		const related_products = products.slice(0, 3)
		return <RecommendationsSection id={'similar-section'} title={'Similar Items'} products={related_products} productBackgroundColor={'white'}/>
	}

	return (
		<React.Fragment>
			{ productCategoryId ? <CategoryBreadcrumbs id={'product-page-crumbs'} categoryId={productCategoryId} /> : null }
			<ProductDetailsSection brand={brand} product={product}/>
			{ isEmptyArray(product.related_products) && productCategoryId ? (
				<BestSellingProductsContextProvider categoryId={productCategoryId}>
					<BestSellingProductsContext.Consumer>
						{context => {
							return (context.products?.length && context.products.length > 1) ? renderRecommendedSection(context.products): null
						}}
					</BestSellingProductsContext.Consumer>
				</BestSellingProductsContextProvider>
			) : renderRecommendedSection(product.related_products!)}
		</React.Fragment>
	)
}


interface ProductProps {
}

const SingleProduct: React.FC<ProductProps> = () => {
	const {brand} = useParams<BrandRootParams>()
	const {product} = useParams()
	const [pageTitle, setPageTitle] = useState('PRODUCT NAME')

	return product ? (
		<PageWrapper id={'product-page'} title={pageTitle}>
			<ProductContextProvider productSlug={product}>
				<ProductContext.Consumer>
					{context => ( !context.notFound ? (
						context.product ? (
							<ProductContent product={context.product} brand={brand!} setPageTitle={setPageTitle} />
						) : <NotFoundError headline={'This product does not exist.'} includeReturnBtn={true}/>
					) : (
						<NotFoundError headline={'Uh-Oh!'} subhead={'The product you are looking for appears to be unavailable. Please try again later.'} includeReturnBtn={true} />
					))}
				</ProductContext.Consumer>
			</ProductContextProvider>
		</PageWrapper>
	) : <ErrorPage errorNumber={404}/>
}
export default SingleProduct
