import {api} from '../api';
import Order, {generateEmptyOrder} from "../../models/order/order";
import OrderProduct from "../../models/order/order-product";

export default class OrderRequests {

    /**
     * All cached results from any order requests
     */
    private static cachedIds: {[key: number]: Order} = {};

    private static requestingOpenOrder = false;

    /**
     * Gets a category based on the passed in ID
     * @param id
     * @return Product
     */
    static async getOrder(id: number): Promise<Order> {

        if (OrderRequests.cachedIds.hasOwnProperty(id))  {
            return OrderRequests.cachedIds[id];
        }

        const { data } = await api.get('/orders/' + id, {
            params: {
                'expand[orderProducts.product]': '*',
                'expand[orderProducts.product.assets]': '*',
                'expand[orderProducts.product.brands]': '*',
                'expand[orderProducts.product.brandOptions]': '*',
            },
        });
        const result = (data as Order);

        OrderRequests.cachedIds[id] = result;

        return result;
    }

    /**
    * Gets a collection of orders based on the passed in ID
    * @param org_id
    * @return Order[]
    */
    static async getOpenOrganizationOrder(org_id: number): Promise<Order|false> {
        if (!OrderRequests.requestingOpenOrder) {
            OrderRequests.requestingOpenOrder = true

            const { data } = await api.get('/organizations/' + org_id + '/orders', {
                params: {
                    'expand[orderProducts.product]': '*',
                    'expand[orderProducts.product.assets]': '*',
                    'filter[submitted_at]': 'null'
                },
            });

            OrderRequests.requestingOpenOrder = false

            const orders = data.data as Order[]

            return orders.length ? orders[0] : generateEmptyOrder();
        }

        return false
    }

    /**
     * Posts an order based on the passed in ID and submitted checkout form data
     * @param id
     * @param checkoutData
     * @return formData
     */
    static async postOrder(id: number, checkoutData: any): Promise<Order> {
        const { data } = await api.post('/organizations/' + id + '/orders', checkoutData)
        return data as Order
    }

    /**
     * Updates an order based on the passed in ID
     * @param order_id
     * @param orderData
     * @return orderData
     */
    static async updateOrder(order_id: number, orderData: any): Promise<Order> {
        const { data } = await api.patch('/orders/' + order_id, orderData)
        return data
    }

    /**
     * Deletes an order based on the passed in ID
     * @param order_id
     * @return orderData
     */
    static async deleteOrder(order_id: number): Promise<Order> {
        const { data } = await api.delete('/orders/' + order_id)
        return data
    }

    /**
     * Updates an order item based on the passed in ID
     * @param order_id
     * @param order_product
     * @return orderData
     */
    static async addOrderItem(order_id: number, order_product: any): Promise<OrderProduct> {
        const { data } = await api.post('/orders/' + order_id + '/products', order_product)
        return data as OrderProduct
    }

    /**
     * Updates an order item based on the passed in ID
     * @param order_id
     * @param product_id
     * @param order_product
     * @return orderData
     */
    static async updateOrderItem(order_id: number, product_id: number, order_product: any): Promise<OrderProduct> {
        const { data } = await api.patch('/orders/' + order_id + '/products/' + product_id, order_product)
        return data
    }

    /**
     * Deletes an order item based on the passed in ID
     * @param order_id
     * @param product_id
     * @return orderData
     */
    static async deleteOrderItem(order_id: number, product_id: number): Promise<OrderProduct> {
        const { data } = await api.delete('/orders/' + order_id + '/products/' + product_id)
        return data
    }
}
