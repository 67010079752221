import React, {useContext} from 'react'
import './index.scss';
import PostContextProvider, {PostContext} from "../../contexts/PostContext";
import Section from "../../components/Basic/Section";
import PageWrapper from "../../components/Basic/PageWrapper";
import {useParams} from "react-router-dom";
import ErrorPage from "../ErrorPage";
import HTMLComponent from "../../components/Basic/HTMLComponent";
import {doShortcodes} from "../../util/ShortCode";

const DefaultPageContent: React.FC<DefaultPageProps> = ({pageSlug, className}) => {
	const {post} = useContext(PostContext)

	return post ? (
		<PageWrapper id={pageSlug ? pageSlug : ''} title={post.title ? post.title : 'Default Page'} className={className}>
			<Section id={'article-' + post.id} className={'default-page'}>
				<h2 className={'article-title'}><HTMLComponent className={'article-content'} content={post.title ? post.title : ''} /></h2>
				{post.content ? doShortcodes(post.content) : []}
			</Section>
		</PageWrapper>
	) : <ErrorPage errorNumber={404} />
}

interface DefaultPageProps {
	pageSlug?: string,
	className?: string,
}

const DefaultPage: React.FC<DefaultPageProps> = ({pageSlug, className}) => {
	const {page} = useParams()
	const slug = page || pageSlug

	return slug ? (
		<PostContextProvider postSlug={slug}>
			<DefaultPageContent pageSlug={slug} className={className}/>
		</PostContextProvider>
	) : <ErrorPage errorNumber={404} />
}

export default DefaultPage
