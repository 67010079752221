import React from 'react';
import './index.scss';
import './responsive.scss';
import CollapsibleContainerGroup, {CollapsibleContainerGroupProps} from "../Basic/CollapsibleContainerGroup";

interface AccordionProps extends CollapsibleContainerGroupProps{
    mobileOnly?: boolean
}
const AccordionGroup: React.FC<AccordionProps> = ({mobileOnly, className, ...props}) => {
    const classes = 'accordion-group' + (mobileOnly ? ' mobile-only' : '') + (className ? ' ' + className : '')

    return (
        <CollapsibleContainerGroup className={classes} {...props} />
    )
}

export default AccordionGroup