import './index.scss';
import React from 'react'


interface LoadingIndicatorComponentProps {}

const LoadingIndicator: React.FC<LoadingIndicatorComponentProps> = () => {
	return (
		<div className={'lds-ripple visible'} slot={'end'}>
			<div></div>
			<div></div>
		</div>
	)
}
export default LoadingIndicator
