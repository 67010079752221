import React, {PropsWithChildren, ReactNode, useEffect, useRef, useState} from 'react';
import './index.scss';
import Icon from "../Icon";
import {IconNames} from "../../../models/icon";
import {CSSTransition, Transition, TransitionGroup} from "react-transition-group";
import TweenMax from "gsap";
import HTMLComponent from "../HTMLComponent";


export interface CollapsibleContainerProps {
	label: ReactNode | string
	closedIcon: IconNames
	openIcon?: IconNames
	open?: boolean
	overlay?: boolean
	animationSpeed?: number
	onToggle?: (opened: boolean) => boolean
	className?: string
}

const CollapsibleContainer: React.FC<PropsWithChildren<CollapsibleContainerProps>> = ({label, closedIcon, openIcon, open, overlay, animationSpeed, onToggle, className, children}) => {
	const [isOpen, setIsOpen] = useState(open ? open : false)
	const [callback, setCallback] = useState(null as any)
	const contentRef = useRef<HTMLDivElement>(null)

	const expanderClicked = () => {
		const shouldToggle = onToggle ? onToggle(isOpen) : true
		if (shouldToggle) {
			setIsOpen(!isOpen)
		}
	}

	// effect for when the expanders open state is controlled by another component
	useEffect(() => {
		if (open !== undefined && open !== isOpen) {
			expanderClicked()
		}
	}, [open])

	const duration = (animationSpeed ? animationSpeed : 500) / 1000

	const animationComplete = (node: HTMLElement) => {
		node.style.height = ''
		return callback ? callback() : null;
	}

	const enter = (node: HTMLElement) => {
		node.style.height = '0px'
		TweenMax.to(node, {
			height: contentRef?.current?.offsetHeight + 'px',
			duration: duration,
			ease: 'power3.inOut',
			onComplete: () => animationComplete(node)
		})
	}

	const exit = (node: HTMLElement) => {
		node.style.height = contentRef?.current?.offsetHeight + 'px'
		TweenMax.to(node, {
			height: '0px',
			duration: duration,
			ease: 'power3.inOut',
			onComplete: () => animationComplete(node),
		});
	}

	const end = (_: HTMLElement, done: () => void) => {
		setCallback(done);
	}

	return children ? (
		<div className={"exp-container" + (className ? ' ' + className : '') + (overlay ? ' window-absolute' : '') + (isOpen ? ' open' : '')}>
			<div className="expander exp-button" onClick={expanderClicked}>
				<p className="title">
					{typeof label === 'string' ? <span>{label}</span> : label}
					<span className={'transition-wrapper'}>
						<TransitionGroup component={null}>
							{isOpen ? (
								<CSSTransition key={"open"} classNames="open" timeout={300}>
									<Icon iconName={(openIcon ? openIcon : closedIcon)}/>
								</CSSTransition>
							) : (
								<CSSTransition key={"close"} classNames="close" timeout={300}>
									<Icon iconName={closedIcon}/>
								</CSSTransition>
							)}
						</TransitionGroup>
					</span>
				</p>
			</div>
			<Transition timeout={duration*1000} in={isOpen} onEnter={enter} onExit={exit} addEndListener={end}>
				{() => (
					<div className={"exp-window"}>
						{typeof children === 'string' ? (
							<HTMLComponent ref={contentRef} className="exp-contents" content={children} />
						) : (
							<div ref={contentRef} className="exp-contents">
								{children}
							</div>
						)}
					</div>
				)}
			</Transition>
		</div>
	) : null
}

export default CollapsibleContainer
