import React, {useEffect, useState} from 'react';
import './index.scss';
import Product from "../../../models/product";
import Button, {ButtonProps} from "../../Basic/Button";
import Order, {addProductToOrder, findOrderProductInOrder} from "../../../models/order/order";
import {BrandColorNames} from "../../../models/brand";
import CurrentOrderContextProvider, {CurrentOrderContext} from "../../../contexts/CurrentOrderContext";
import { useOutletContext } from "react-router-dom";
import {BrandOutletProps} from "../../../App";
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";
import OrderProduct from "../../../models/order/order-product";
import StaticFeatureContextProvider, {StaticFeatureContext} from "../../../contexts/StaticFeatureContext";

type addToCartFeature = {default: string|undefined, abbreviation: string|undefined}

interface AddToCartContentProps extends AddToCartProps {
    order: Order
    organizationId: number,
    addToCurrentOrder: (order: Order, orderProduct: OrderProduct, organization_id: number) => void,
    updateCurrentOrder: (order: Order, orderProductId: number, totalPrice: number, orderProduct?: OrderProduct) => void
    addToCartButton: addToCartFeature
    addToCartSuccess: addToCartFeature
}

const AddToCartContent: React.FC<AddToCartContentProps> = (props) => {
    const [addedToCart, setAddedToCart] = useState(false)
    const {order, organizationId, addToCurrentOrder, updateCurrentOrder, addToCartButton, addToCartSuccess, product, quantity, buttonColor = 'main', abbreviated, className, onClick, children, ...additionalProps} = props
    const {setCartShowing} = useOutletContext<BrandOutletProps>()

    const classes = 'add-to-cart ' + ( className ? className : '') + (addedToCart ? ' disabled' : '')
    const buttonText = children ? children : (abbreviated ? addToCartButton.abbreviation : addToCartButton.default)
    const successMessage = (abbreviated ? addToCartSuccess.abbreviation : addToCartSuccess.default)
    const successStateDuration = 2000
    const addToCart = (event: React.MouseEvent<HTMLOrSVGElement>) => {
        // If the product hasn't been added to the cart in the last 2 seconds
        if (!addedToCart) {
            // Call prop-based on click event
            if (onClick) {
                onClick(event)
            }

            // Add item to cart
            const existingProduct = findOrderProductInOrder(order, product)
            const updatedObj = addProductToOrder(order, product, quantity)

            if(updatedObj.orderProduct){
                // If the product being added already exists in the order, update the quantity. Otherwise, add it to the order.
                existingProduct ? (
                    updateCurrentOrder(updatedObj.order, existingProduct.orderProduct.id!, updatedObj.order.total_price, updatedObj.orderProduct)
                ) : addToCurrentOrder(updatedObj.order, updatedObj.orderProduct, organizationId)
            }

            setAddedToCart(true)
            if (setCartShowing) {
                setCartShowing(true)
            }
        }
    }

    useEffect(() => {
        // Sets a timeout to reset the addedToCart state. This prevents rapid clicks on the Add To Cart Button from adding more products than intended
        if (addedToCart) {
            setTimeout(() => setAddedToCart(false), successStateDuration)
        }
    }, [addedToCart]);

    return (
        <Button buttonColor={buttonColor} className={classes} onClick={addToCart} {...additionalProps}>
            {!addedToCart ? (
                <span key={'add'} className={"add-message"}>{buttonText}</span>
            ) : (
                <span key={'success'} className={'success-message'}>{successMessage}</span>
            )}
        </Button>
    )
}

interface AddToCartProps extends Omit<ButtonProps, 'to'|'buttonColor'> {
    product: Product,
    quantity?: number,
    buttonColor?: BrandColorNames
    abbreviated?: boolean
}

const AddToCart: React.FC<AddToCartProps> = (props) => {
    const features = ['add-to-cart-button', 'add-to-cart-button-abbreviation', 'add-to-cart-success', 'add-to-cart-success-abbreviation']
    return (
        <MeContextProvider>
            <MeContext.Consumer>
                {contextMe => {
                    return <CurrentOrderContextProvider organization_id={contextMe.me.organization_id}>
                        <CurrentOrderContext.Consumer>
                            {context => (
                                <StaticFeatureContextProvider featureNames={features} featuresRequestKey={features.join()}>
                                    <StaticFeatureContext.Consumer>
                                        {staticContext => (
                                            <AddToCartContent
                                                order={context.order}
                                                organizationId={contextMe.me.organization_id!}
                                                addToCurrentOrder={context.addToCurrentOrder}
                                                updateCurrentOrder={context.updateCurrentOrder}
                                                addToCartButton={{default: staticContext.features['add-to-cart-button'], abbreviation: staticContext.features['add-to-cart-button-abbreviation']}}
                                                addToCartSuccess={{default: staticContext.features['add-to-cart-success'], abbreviation: staticContext.features['add-to-cart-success-abbreviation']}}
                                                {...props}/>
                                        )}
                                    </StaticFeatureContext.Consumer>
                                </StaticFeatureContextProvider>
                            )}
                        </CurrentOrderContext.Consumer>
                    </CurrentOrderContextProvider>
                }}
            </MeContext.Consumer>
        </MeContextProvider>
    )
}

export default AddToCart
