import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import Resource from "../models/resource";
import Category from "../models/category";
import Product from "../models/product";
import {mergeBrandOptions} from "../models/brand-option";
import {connect} from "../data/connect";
import Brand from "../models/brand";

/**
 * The state interface for our state
 */
export interface SearchContextState extends BasePaginatedContextState<Resource<Category|Product>> {
}

function createDefaultState(): SearchContextState {
    return {
        ...defaultBaseContext(),
        expands: [
            'resource',
            'resource.brands',
            'resource.brandOptions',
        ],
        limit: 20,
    }
}

/**
 * The actual context component
 */
export const SearchContext = React.createContext<SearchContextState>(createDefaultState());

export interface OwnProps extends BasePaginatedContextProviderProps {
    searchString: string,
}

interface StateProps {
    brand: Brand
}

export interface SearchContextProviderProps extends OwnProps, StateProps {

}

export const SearchContextProvider: React.FC<PropsWithChildren<SearchContextProviderProps>> = (({searchString, brand, ...props}) => {
    const [searchState, setSearchState] = useState(createDefaultState());
    const [brandedSearchResults, setBrandedSearchResults] = useState([] as Resource<Category | Product>[])
    const context = createCallbacks(setSearchState, searchState, '/resources')


    const setAndBrandResources = (searchContext: SearchContextState) => {
        if(searchContext.loadedData) {
            const brandedData: Resource<Category | Product>[] = []
            searchContext.loadedData.forEach(datapoint => {
                if (datapoint.resource && datapoint.resource.brands?.find(childBrand => childBrand.id === brand.id)) {
                    const resource = datapoint.resource

                    // If there are related products, filter them by the current brand
                    if("related_products" in resource && resource.related_products) {
                        const filteredRelatedProducts = resource.related_products?.filter( (product: Product) => product.brands?.find(productBrand => productBrand.id === brand.id));
                        resource.related_products = filteredRelatedProducts.map(product => mergeBrandOptions(product, brand))
                    }

                    datapoint.resource = mergeBrandOptions(resource, brand)
                    brandedData.push(datapoint)
                }

            })


            setBrandedSearchResults(brandedData)
        }
    }

    useEffect(() => {
        searchState.initiated = true
        const newContext = prepareContextState(setSearchState, searchState, '/resources')

        newContext.initialLoadComplete = false
        setAndBrandResources({...newContext})
        newContext.setSearch('content', searchString)
    }, [searchString]);

    useEffect(() => {
        if (searchState.initialLoadComplete) {
            setAndBrandResources(searchState)
        }
    }, [searchState])

    return (
        <SearchContext.Provider value={{...context, loadedData: brandedSearchResults}}>
            {props.children}
        </SearchContext.Provider>
    )
});


export default connect<PropsWithChildren<OwnProps>, StateProps, { }>({
    mapStateToProps: (state) => ({
        brand: state.persistent.brand
    }),
    component: SearchContextProvider
});
